import { createSlice } from "@reduxjs/toolkit";

const initialState =[]

export const salesSlice = createSlice({
    name: 'sales',
    initialState,
    reducers:{
        addItem: (state, action) => {
            state.push(action.payload);
        },
        deleteItem: (state, action) => {
            return state.filter(item => item.id !== action.payload);
        },
        increaseQuantity: (state, action) => {
            const item = state.find(item => item.id === action.payload);
            if (item) {
                item.quantity = parseInt(item.quantity) + 1;
                item.price = parseFloat((item.unit_price) * item.quantity).toFixed(2); 

            }
        },
        decreaseQuantity: (state, action) => {
            const item = state.find(item => item.id === action.payload);
            if (item && item.quantity > 1) {
                item.quantity = parseInt(item.quantity) - 1;
                item.price = parseFloat((item.unit_price) * item.quantity).toFixed(2); 


            }
        },
        clearCart: (state) => {
            return [];
        }

       
    }
})

export const { addItem ,deleteItem,increaseQuantity,decreaseQuantity,clearCart} = salesSlice.actions;

export default salesSlice.reducer;
