import React, { useRef, useState } from 'react';
import { InfinitySpin } from 'react-loader-spinner';
import { useSelector } from 'react-redux';
import { api_url } from '../../../config';
import axios from 'axios';
import { toast } from 'react-toastify';
import api from '../../../utils/api';


const EditStock = ({ toggle, data, products, fetchStocks }) => {
    const [product, setProduct] = useState()
    const [quantity, setQuantity] = useState(data.quantity_received || '')
    const [bill, setBill] = useState(data.price_on_waybill)
    const [date_received, setDate_received] = useState(data.date_received || '')
    const [expiryDate, setExpiryDate] = useState(data.expiry_date || '')
    const [comment, setComment] = useState()
    const [unitPrice, setUnitPrice] = useState(data.price || '')
    const [stock_status, setStock_status] = useState('')
    const [lowStockValue, setLowStockValue] = useState(data?.low_stock_value || '')
    const [sale_target, setSale_target] = useState(data.sale_target || '')
    const [selectedStatus, setSelectedStatus] = useState('');
    const status = ['All', 'Active', 'Inactive']
    const [loading, setLoading] = useState(false)
    const [error, setError] = useState('')
    console.log(data);
    const [productsInput, setProductsInput] = useState(data.inventory.name +'-'+ data.manufacturer || '');
    const [productsSuggestions, setProductsSuggestions] = useState([]);
    const [showProductsSuggestions, setShowProductsSuggestions] = useState(false);
    const productsInputRef = useRef(null);
    const [selectedProductId, setSelectedProductId] = useState(null);
    const { id, branch_id, token } = useSelector((state) => state.auth);


    const updateStock = async (e) => {
        // Prevent default form submission if an event is passed
        if (e) {
            e.preventDefault();
        }

        // Gather stock details from state or props
        const stockDetails = {
            inventory_id: data.inventory.id,
            quantity_received: quantity,
            price: unitPrice,
            date_received,
            expiry_date: expiryDate,
            sale_target,
            price_on_waybill: bill,
            low_stock_value:lowStockValue
        };

        // Check if any required field is missing
        const required = !data.id || !quantity || !unitPrice || !date_received || !expiryDate || !sale_target;
        if (required) {
            setError('Please fill all required fields');
            setTimeout(() => setError(''), 6000);
            return;
        }

        // Check if the user is offline
        if (!navigator.onLine) {
            toast("You're offline");
            return;
        }
        const target_precision = unitPrice * quantity;
        if(sale_target > target_precision){
            setError("Target exceeds stock's total value")
            return;
        }

        try {
            setLoading(true);

            // Send POST request to add stock details
            const response = await api.put(`${api_url}/api/v1/wholesaler/user/${id}/branch/${branch_id}/stock/${data.id}`, stockDetails, {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${token}`
                }
            });

            // Success feedback
            toast.success('Stock updated successfully');
            fetchStocks();
            setStock_status('Stock updated successfully');
            setTimeout(() => {
                toggle()
            }, 3000);
        } catch (error) {
            const errorMsg = error?.response?.data?.error || 'An error occurred';
            setError(errorMsg);
            toast.error(errorMsg);
            setTimeout(() => {
                setError('');
            }, 3000);

        } finally {
            setLoading(false);
        }
    };




    const handleProductsInputChange = (e) => {
        const value = e.target.value;
        setProductsInput(value);

        // Filter retailerss based on the input value
        const filteredProductSuggestions = products.filter(item =>
            item.product_name && item.product_name.toLowerCase().includes(value.toLowerCase())
        );

        // Debugging logs
        console.log('Filtered Suggestions:', filteredProductSuggestions);

        setProductsSuggestions(filteredProductSuggestions);
        setShowProductsSuggestions(true);
    };

    const handleProductFocus = () => {
        // Show all retailers on focus
        setProductsSuggestions(products);
        setShowProductsSuggestions(true);

    };

    const handleSelectProducts = (selectedProduct) => {
        setProductsInput(selectedProduct?.product_name+ '-' + selectedProduct?.manufacturer);
        setSelectedProductId(selectedProduct.id);
        setShowProductsSuggestions(false);
    };
    return (
        <div className='h-[500px]  overflow-auto sm:min-w-[300px] lg:min-w-[600px] mx-auto p-4'>
            <div className=' mb-4'>
                <p className='font-lufga text-center font-semibold text-neutral-500'>Edit Stock</p>

            </div>
            <form className="grid gap-4 mb-2 md:grid-cols-2">
                {loading && ( // Display loader while loading
                    <div className="flex justify-center items-center w-full h-full bg-gradient-to-br from-opacity-40 to-opacity-60 backdrop-blur-sm absolute top-0 left-0 z-10">
                        <InfinitySpin color="#008DDA" loading={loading} size={36} />
                    </div>
                )}

                <div ref={productsInputRef} className="relative flex flex-col gap-2.5">
                    <label className="text-sm font-lufga flex items-center gap-1">Product name<span className='font-serif text-red-400'>*</span></label>
                    <input
                        type="text"
                        value={productsInput}
                        onChange={handleProductsInputChange}
                        onFocus={handleProductFocus}
                        className="px-3 py-2 border border-gray-100 text-gray-500 rounded-md focus:outline-none focus:border-blue-500"
                        placeholder="Type product..."
                        required
                        disabled={true}
                    />
                    {showProductsSuggestions && productsSuggestions.length > 0 && (
                        <div className="absolute z-10 w-full bg-white border max-h-[150px] overflow-auto border-gray-300 rounded-lg shadow-lg mt-20">
                            {productsSuggestions.map((product, index) => (
                                <div
                                    key={index}
                                    className="py-1 px-2 hover:bg-gray-100 cursor-pointer"
                                    onClick={() => handleSelectProducts(product)}
                                >
                                    <p className='text-xs hover:bg-blue-100 px-1 rounded-md shadow-sm'>
                                        {product.product_name} - {product?.manufacturer}
                                    </p>
                                </div>
                            ))}
                        </div>
                    )}
                </div>
                <div className='flex flex-col gap-2.5'>
                    <label className="text-sm font-lufga flex items-center gap-1">Quantity<span className='font-serif text-red-400'>*</span></label>
                    <input
                        type="number"
                        value={quantity}
                        onChange={(e) => { setQuantity(e.target.value) }}
                        placeholder=""
                        className="px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:border-blue-500"
                        required
                    />
                </div>

                <div className='flex flex-col gap-2.5'>
                    <label className="text-sm font-lufga flex items-center gap-1" >Date Received<span className='font-serif text-red-400'>*</span></label>
                    <input
                        type="date"
                        value={date_received}
                        onChange={(e) => { setDate_received(e.target.value) }}
                        placeholder=""
                        className="px-3 py-2 text-sm border border-gray-300 rounded-md focus:outline-none focus:border-blue-500"
                        required
                    />
                </div>
                <div className='flex flex-col gap-2.5'>
                    <label className="text-sm font-lufga flex items-center gap-1">Expiry Date<span className='font-serif text-red-400'>*</span></label>
                    <input
                        type="date"
                        value={expiryDate}
                        onChange={(e) => setExpiryDate(e.target.value)}
                        placeholder=""
                        className="px-3 py-2 text-sm border border-gray-300 rounded-md focus:outline-none focus:border-blue-500"

                    />
                </div>
                <div className='flex flex-col gap-2.5'>
                    <label className="text-sm font-lufga flex items-center gap-1">Unit Price<span className='font-serif text-red-400'>*</span></label>
                    <input
                        type="number"
                        value={unitPrice}
                        onChange={(e) => { setUnitPrice(e.target.value) }}
                        placeholder=""
                        className="px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:border-blue-500"
                        required
                    />
                </div>
                <div className='flex flex-col gap-2.5'>
                    <label className="text-sm font-lufga flex items-center gap-1">Cost on way bill<span className='font-serif text-red-400'>*</span></label>
                    <input
                        type="number"
                        value={bill}
                        onChange={(e) => { setBill(e.target.value) }}
                        placeholder=""
                        className="px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:border-blue-500"
                        required
                    />
                </div>
                <div className='flex flex-col gap-2.5'>
                    <label className="text-sm font-lufga flex items-center gap-1">Sale Target<span className='font-serif text-red-400'>*</span></label>
                    <input
                        type="number"
                        value={sale_target}
                        onChange={(e) => { setSale_target(e.target.value) }}
                        placeholder=""
                        className="px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:border-blue-500"
                        required
                    />
                </div>
                <div className='flex flex-col gap-2.5'>
                    <label className="text-sm font-lufga flex items-center gap-1">Low Stock Value</label>
                    <input
                        type="number"
                        value={lowStockValue}
                        onChange={(e) => { setLowStockValue(e.target.value) }}
                        placeholder=""
                        className="px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:border-blue-500"
                        required
                    />
                </div>
                {/* <div className='flex flex-col gap-1.5'>
                    <label className="text-sm font-lufga flex items-center gap-1">Comments</label>
                    <textarea
                        type="text"
                        rows={3}
                        value={comment}
                        onChange={(e) => { setComment(e.target.value) }}
                        placeholder=""
                        className="px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:border-blue-500"
                        required
                    />
                </div> */}



            </form>
            <div className='flex justify-between items-center'>
                <div>
                    {stock_status ? <div>
                        <p className="bg-green-300 font-lufga rounded-md shadow-md px-2 py-1 text-xs text-center ">{stock_status}</p>
                    </div> : error ? <div>
                        <p className="bg-red-300 font-lufga rounded-md text-center shadow-md px-2 py-1 text-sm ">
                            {typeof error === 'string' ? error : (
                                Object.keys(error).map((key) => (
                                    Array.isArray(error[key]) ? error[key][0] : error[key]
                                ))
                            )}
                        </p>
                    </div> : null}</div>

                <button onClick={updateStock} className="bg-blue-500 font-lufga text-white py-2 px-4 rounded-md hover:bg-blue-600 shadow-md transition duration-300">Update</button>

            </div>

        </div>
    );
}

export default EditStock;
