import React from 'react';
import LoginForm from '../components/LoginForm';
import HomeNav from '../components/LandingPagelib/HomeNav';

const Login = () => {
    return (
        <div>
            {/* <HomeNav /> */}
            <div className=' flex justify-center items-center bg-gray-100 h-screen w-full '>
                <LoginForm />
            </div>
        </div>
    );
}

export default Login;
