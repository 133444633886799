import React from 'react';
import { MapContainer, TileLayer, Marker, Popup } from 'react-leaflet';
import "leaflet/dist/leaflet.css";
import { Icon } from 'leaflet';
import MarkerClusterGroup from 'react-leaflet-cluster';


const Map = () => {
    const markers = [
        {
            geocode: [5.643455164104016, -0.11283345396868569],
            popUp: "NTUBED GLOBAL LTD"
        }
    ]

    const customIcon = new Icon({
        iconUrl: require('../../assets/placeholder.png'),
        iconSize: [38, 38]
    })


    return (
        <MapContainer center={[5.643455164104016, -0.11283345396868569]} zoom={13}>
            <TileLayer
                attribution=''
                url='https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png'
            />
            {/* <Marker position={[5.643455164104016, -0.11283345396868569]}>
      <Popup>
      "NTUBED GLOBAL LTD"
      </Popup>
    </Marker> */}
            <MarkerClusterGroup>
                {markers.map(marker => (
                    <Marker  position={marker.geocode} icon={customIcon}>
                        <Popup>
                            <h2 className='font-bold'>{marker.popUp}</h2>
                        </Popup>
                    </Marker>
                ))}
            </MarkerClusterGroup>
        </MapContainer>
    );
}

export default Map;
