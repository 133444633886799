import React, { useState } from 'react';
import { InfinitySpin } from 'react-loader-spinner';
import { api_url } from '../../../config';
import axios from 'axios';
import { useSelector } from 'react-redux';
import api from '../../../utils/api';
import { toast } from 'react-toastify';

const DeleteStock = ({toggle,data,fetchStocks}) => {
    const [loading,setLoading] = useState(false)
    const[delete_status,setDelete_status] = useState('')
    const [error,setError] = useState('')
    const { id, branch_id, token } = useSelector((state) => state.auth)


    const handleDeleteStock = async () => {
        try {
            setLoading(true)
            await api.delete(api_url + `/api/v1/wholesaler/user/${id}/branch/${branch_id}/stock/${data?.id}`,{
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${token}`
                }
            })
            setDelete_status('Stock deleted successfully')
            setTimeout(() => {
                toggle()
            }, 1500);
            fetchStocks()
            toast.success('Stock deleted successfully')


        } catch (error) {
            setError(error?.response?.data?.error)
            setTimeout(() => {
                setError('');
            }, 6000);
            toast.error('An error occured')

        } finally {
            setLoading(false)
        }
    }

    return (
        <div>
               <div className="card sm:w-96 bg-neutral text-neutral-content">
                <div className="card-body items-center text-center">
                {loading && ( // Display loader while loading
                    <div className="flex justify-center items-center w-full h-full bg-gradient-to-br from-opacity-40 to-opacity-60 backdrop-blur-sm absolute top-0 left-0">
                        <InfinitySpin color="#008DDA" loading={loading} size={36} />
                    </div>
                )}
                    <h2 className="font-bold">Delete Stock</h2>

                    <div>
                        <p className='text-xs sm:text-sm'>Are you sure you want to delete this stock?</p>
                    </div>
                    <div className="flex items-center justify-center gap-3 my-2">
                        <button onClick={() => { toggle() }} className="bg-gray-500 font-lufga text-white py-1 text-xs sm:text-sm px-4 rounded-md hover:bg-gray-600 shadow-md transition duration-300">Cancel</button>
                        <button onClick={handleDeleteStock}  className="bg-blue-500 font-lufga text-white py-1 text-xs sm:text-sm px-4 rounded-md hover:bg-blue-600 shadow-md transition duration-300">YES</button>
                    </div>
                    <div>
                    {delete_status ? <div>
                        <p className="bg-green-300 font-lufga rounded-md shadow-md px-2 py-1 text-xs text-center ">{delete_status}</p>
                    </div> : error ? <div>
                        <p className="bg-red-300 font-lufga rounded-md text-center shadow-md px-2 py-1 text-sm ">
                            {typeof error === 'string' ? error : (
                                Object.keys(error).map((key) => (
                                    Array.isArray(error[key]) ? error[key][0] : error[key]
                                ))
                            )}
                        </p>
                    </div> : null}</div>
                </div>
            </div>
        </div>
    );
}

export default DeleteStock;
