import React, { useState } from 'react';
import { LuPencil } from "react-icons/lu";
import { RiDeleteBinLine } from "react-icons/ri";
import SupplierInfo from '../../components/Wholesalelib/supplier/SupplierInfo';

const Suppliers = () => {
 
    return (

        <div>
           <SupplierInfo/>
        </div>
    );
}

export default Suppliers;
