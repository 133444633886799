import React, { createContext, useState, useEffect, useContext, useCallback } from 'react';
import { useSelector } from "react-redux";
import { toast } from 'react-toastify';
import { api_url } from '../config';
import api from '../utils/api';

const OrderContext = createContext();

export function OrderProvider({ children }) {
  const { id, token, branch_id, product_type } = useSelector((state) => state.auth);
  
  const [orders, setOrders] = useState([]);
  const [metadata, setMetadata] = useState({});
  const [loading, setLoading] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const [limit, setLimit] = useState(50);
  const [filter, setFilter] = useState('');
  const [filter_status, setFilter_status] = useState('');
  const [sortBy, setSortBy] = useState('-created_at');
  const [searchQuery, setSearchQuery] = useState('');
  const [lastPage, setLastPage] = useState(1);


  const fetchOrders = useCallback(async () => {
    if (!navigator.onLine) {
      toast("You're offline");
      setLoading(false);
      return;
    }

    setLoading(true);

    const params = {
      page: currentPage,
      limit,
      sort: sortBy,
      [`filter[${filter}]`]: filter_status,
      search: searchQuery
    };

    const endpoint = product_type === 'pharmtrix-wholesale'
      ? `/api/v1/wholesaler/user/${id}/branch/${branch_id}/order`
      : `/api/v1/retailer/user/${id}/branch/${branch_id}/order`;

    try {
      const response = await api.get(api_url + endpoint, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`
        },
        params
      });

      const { data, meta } = response.data;
      setOrders(data);
      setMetadata(meta);
      setLastPage(meta.last_page)
    } catch (error) {
      console.error("Error fetching orders:", error);
      toast("Error fetching orders");
    } finally {
      setLoading(false);
    }
  }, [id, token, branch_id, product_type, currentPage, limit, filter, filter_status, sortBy, searchQuery]);

  useEffect(() => {
    fetchOrders();
  }, [fetchOrders]);

  const value = {
    orders,
    metadata,
    loading,
    currentPage,
    limit,
    filter,
    filter_status,
    sortBy,
    searchQuery,
    lastPage,
    setCurrentPage,
    setLimit,
    setFilter,
    setFilter_status,
    setSortBy,
    setSearchQuery,
    setLoading,
    fetchOrders
  };

  return <OrderContext.Provider value={value}>{children}</OrderContext.Provider>;
}

export function useOrders() {
  const context = useContext(OrderContext);
  if (context === undefined) {
    throw new Error('useOrders must be used within an OrderProvider');
  }
  return context;
}