import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { api_url } from '../../../config';
import { InfinitySpin } from 'react-loader-spinner';
import { toast } from 'react-toastify';
import { hasPermission } from '../../../Redux/permissionSlice';
import api from '../../../utils/api';

const SaleComments = ({data,roles}) => {
    const [comments, setComments] = useState([]);
    const [comment, setComment] = useState('');
    const [error, setError] = useState('')
    const [status, setStatus] = useState('')
    const { id, branch_id, token } = useSelector((state) => state.auth);
    const [loading, setLoading] = useState(false);


    const fetchComments = async () => {
        setLoading(true);
        try {
            const response = await api.get(
                `${api_url}/api/v1/retailer/user/${id}/branch/${branch_id}/transaction/${data.id}/comments`,
                {
                    headers: {
                        'Content-Type': 'application/json',
                        Authorization: `Bearer ${token}`,
                    },
                }
            );
            const comment_data = response.data.data;
            setComments(comment_data);
        } catch (error) {
            console.log(error);
        } finally {
            setLoading(false);
        }
    };


    const addComments = async () => {
        setLoading(true);
        
        try {
            const response = await api.post(
                `${api_url}/api/v1/retailer/user/${id}/branch/${branch_id}/transaction/${data.id}/comments`,{content:comment},
                {
                    headers: {
                        'Content-Type': 'application/json',
                        Authorization: `Bearer ${token}`,
                    },
                }
            );
            setComment('')
            toast.success('comment added successful');
            setStatus('comment added successful')

            fetchComments()
        } catch (error) {
            const errorMsg = error?.response?.data?.error || 'An error occurred';
            setError(errorMsg);
            toast.error(errorMsg);
        } finally {
            setLoading(false);
            setTimeout(() => setError(''), 3000);
            setTimeout(() => setStatus(''), 3000);
        }
    };

useEffect(()=>{
    fetchComments()
},[data])
    console.log('comments:', comments);
    
    return (
        <div className="relative h-[55vh]">
            {loading && ( // Display loader while loading
                <div className="flex justify-center items-center w-full h-full bg-gradient-to-br from-opacity-40 to-opacity-60 backdrop-blur-sm absolute top-0 left-0">
                    <InfinitySpin color="#059212" loading={loading} size={40} />
                </div>
            )}
            <div className="overflow-y-auto h-full pb-40">
                <div className="flex justify-between items-center mb-4">
                    <h2 className="text-lg font-semibold">Comments</h2>
                </div>
                {comments?.length > 0 ? (
                    comments.map((comment, index) => (
                        <div key={index} className="mb-4">
                            <p className="text-sm">{comment.content}</p>
                            <p className="text-xs text-gray-500">
                                - {`${comment?.user?.first_name} ${comment?.user?.last_name}`}, {new Date(comment?.created_at).toLocaleDateString()}
                            </p>
                        </div>
                    ))
                ) : (
                    <p className="text-sm text-gray-500">No comments available.</p>
                )}
            </div>
          {hasPermission(roles,['transactionWriter','transactionAdmin'])&&(
              <div className="absolute bottom-0 left-0 right-0 px-4 py-2 bg-white border-t">
              <div className="flex flex-col gap-1.5">
                  <label className="text-sm font-lufga flex items-center gap-1">Comments</label>
                  <textarea
                      type="text"
                      rows={2}
                      value={comment}
                      onChange={(e) => {
                          setComment(e.target.value);
                      }}
                      placeholder=""
                      className="px-3 text-sm py-2 border border-gray-300 rounded-md focus:outline-none focus:border-green-500 sm:w-96"
                      required
                  />
              </div>
              <div className='flex items-center gap-4'>
                  <button onClick={addComments} className="bg-green-500 hover:opacity-90 text-white py-1 px-4 rounded-md shadow-sm text-xs my-3">
                      Add comment
                  </button>
                  <div className='flex justify-between items-center'>
                      <div>
                          {status ? <div>
                              <p className="bg-green-300 font-lufga rounded-md shadow-md px-2 py-1 text-xs text-center ">{status}</p>
                          </div> : error ? <div>
                              <p className="bg-red-300 w-80 font-lufga rounded-md text-center shadow-md px-2 py-1 text-xs ">
                                  {typeof error === 'string' ? error : (
                                      Object.keys(error).map((key) => (
                                          Array.isArray(error[key]) ? error[key][0] : error[key]
                                      ))
                                  )}
                              </p>
                          </div> : null}</div>
                  </div>
              </div>
          </div>
          )}
        </div>
    );
}

export default SaleComments;
