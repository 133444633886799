import React from 'react';
import hero from '../../assets/hero.jpg'

const Hero = () => {
    return (
        <div className='mx-auto' >
            <div className='relative max-h-[500px]'>
                <div className='flex  flex-col justify-center items-center '>
                    <div className='absolute top-10 md:top-14 mx-4  flex flex-col justify-center items-center bg-white sm:px-8 py-4 px-4 sm:py-12 opacity-80 max-h-[500px]rounded-md border-b-4 border-[#00A9FF]'>
                        <p className='text-center sm:text-3xl md:text-4xl lg:text-5xl font-semibold text-gray-700 sm:my-2 '>Welcome to Pharmtrix</p>
                        <p className='text-center sm:my-4 mb-2 text-xs sm:text-sm md:text-lg lg:text-xl '>Pharmtrix is a Pharmacy Inventory and Prescription management system
                            that lets you stake <br />control of your pharmacy operations</p>
                        <p className='text-center text-xs sm:text-sm md:text-lg lg:text-xl'>Our solution is scalable and streamlines the functionality of your workflow,improving each segment along the way
                            ,including inventory management,inventory replenishment, stock management,sale transactions and more.
                        </p>
                    </div>
                </div>

                <img src={hero} className='w-full max-h-[500px] object-cover' />
            </div>
        </div>

    );
}

export default Hero;
