import React, { useEffect } from 'react';
import OrderInfo from '../../components/Retaillib/orders/OrderInfo';
import { useOrders } from '../../context/orderContext';
import { useSelector } from 'react-redux';

const RetailOrderPage = () => {

    return (
        <div>
         <OrderInfo/> 
        </div>
    );
}

export default RetailOrderPage;
