import React from 'react';

import RetailerInfo from '../../components/Wholesalelib/retailer/RetailInfo';
const Retailer = () => {
    return (
        <div>
            <RetailerInfo/>
        </div>
    );
}

export default Retailer;
