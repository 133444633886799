import React, { useEffect, useRef, useState } from 'react';
import AddOrderSupplierModal from './AddOrderSupplierModal';
import { MdClose } from 'react-icons/md';
import { ColorRing, InfinitySpin } from 'react-loader-spinner';
import { FcSearch } from 'react-icons/fc';
import { useSelector } from 'react-redux';
import { api_url } from '../../../config';
import api from '../../../utils/api';
import { toast } from 'react-toastify';

const ChooseSupplier = ({ onSelectSupplier }) => {
    const [externalSuppliers, setExternalSuppliers] = useState([]);
    const [internalSuppliers, setInternalSuppliers] = useState([]);
    const [suppliers, setSuppliers] = useState([]);
    const [supplier_status, setSupplier_status] = useState('');
    const [searchTerm, setSearchTerm] = useState('');
    const [searchLoading, setSearchLoading] = useState(false);
    const [showAddSupplierModal, setShowAddSupplierModal] = useState(false);
    const [selectedSupplier, setSelectedSupplier] = useState(null)
    const [activeTab, setActiveTab] = useState('added');
    const [metadata, setMetadata] = useState('')
    const [search, setSearch] = useState('')
    const [externalSuppliers_limit, setExternalSuppliers_limit] = useState()
    const [newLimit, setNewLimit] = useState(50);
    const [limit, setLimit] = useState(50)
    const [loading, setLoading] = useState(true)
    const [currentPage, setCurrentPage] = useState(1);
    const [lastPage, setLastPage] = useState();
    const [hasSearched, setHasSearched] = useState(false);

    const handlePageClick = (pageNumber) => {
        setCurrentPage(pageNumber);
    };


    const handleNextPage = () => {
        if (currentPage < lastPage) {
            setCurrentPage(currentPage + 1);

        }
    };

    const handlePrevPage = () => {
        if (currentPage > 1) {
            setCurrentPage(currentPage - 1);
        }
    };


    function getPageNumbers() {
        const pageNumbers = [];
        const totalDisplayed = 7; // Total number of page buttons to display dey here
        const sideButtons = 2; // Number of buttons to show on each side of the current page

        if (lastPage <= totalDisplayed) {
            // If total pages are less than or equal to totalDisplayed, show all pages
            for (let i = 1; i <= lastPage; i++) {
                pageNumbers.push(i);
            }
        } else {
            // Always show first page
            pageNumbers.push(1);

            if (currentPage > sideButtons + 2) {
                pageNumbers.push('...');
            }

            // Calculate start and end of the current range
            let rangeStart = Math.max(2, currentPage - sideButtons);
            let rangeEnd = Math.min(lastPage - 1, currentPage + sideButtons);

            // Adjust range if at the start or end
            if (currentPage <= sideButtons + 2) {
                rangeEnd = totalDisplayed - 2;
            } else if (currentPage >= lastPage - sideButtons - 1) {
                rangeStart = lastPage - totalDisplayed + 3;
            }

            for (let i = rangeStart; i <= rangeEnd; i++) {
                pageNumbers.push(i);
            }

            if (currentPage < lastPage - sideButtons - 1) {
                pageNumbers.push('...');
            }

            // Always show last page
            pageNumbers.push(lastPage);
        }

        return pageNumbers;
    }

    const modalRef = useRef();
    const { id, branch_id, token } = useSelector((state) => state.auth)

    const fetchExternalSuppliers = async (page, limit, searchQuery) => {
        setLoading(loading)
        if (!navigator.onLine) {
            toast("You're offline");
            setLoading(false);
            return;
        }
        const params = {
            page: page || 1,
            limit: limit || 50,
            // sort: sortby,
            search: `${searchQuery || ''}`

        };
        try {
            const response = await api.get(api_url + `/api/v1/retailer/user/${id}/branch/${branch_id}/supplier/external`, {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${token}`
                },
                params
            })
            const data = response.data.data
            const meta = response.data.meta
            setExternalSuppliers(data)
            setSuppliers(data)
            setMetadata(meta)
            setLastPage(meta.last_page)

        } catch (error) {
            console.log(error);

        } finally {
            setLoading(false)
        }
    }
    const fetchInternalSuppliers = async (page, limit, searchQuery) => {
        setLoading(loading)
        if (!navigator.onLine) {
            toast("You're offline");
            setLoading(false);
            return;
        }
        const params = {
            page: page || 1,
            limit: limit || 50,
            // sort: sortby,
            search: `${searchQuery || ''}`

        };
        try {
            const response = await api.get(api_url + `/api/v1/retailer/user/${id}/branch/${branch_id}/supplier/internal`, {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${token}`
                },
                params
            })
            const data = response.data.data
            const meta = response.data.meta
            setInternalSuppliers(data)
            setSuppliers(data)
            setMetadata(meta)
            setLastPage(meta.last_page)

        } catch (error) {
            console.log(error);

        } finally {
            setLoading(false)
        }
    }
    useEffect(() => {
        if (activeTab === 'added') {
            fetchInternalSuppliers(currentPage, limit)
        } else if (activeTab === 'not-added') {
            fetchExternalSuppliers(currentPage, limit)
        }
    }, [activeTab, currentPage]);

    // const filteredSuppliers = suppliers.filter(supplier =>
    //     supplier.name.toLowerCase().includes(searchTerm.toLowerCase())
    // );

    // const filteredSuppliers = suppliers.filter(supplier =>
    //     supplier.name.toLowerCase().includes(searchTerm.toLowerCase()) &&
    //     (activeTab === 'added' ? supplier.added : !supplier.added)
    // );
    const handleSearchClick = async (searchQuery) => {
        setHasSearched(true);
        setSearchLoading(true)
        try {
            // await fetchExternalSuppliers(1, 50, searchQuery);

            if (activeTab === 'added') {
                fetchInternalSuppliers(1, 50, searchQuery)
            } else if (activeTab === 'not-added') {
                fetchExternalSuppliers(1, 50, searchQuery)
            }
            setHasSearched(true);
        } catch (error) {
            console.log(error);
        } finally {
            setSearchLoading(false)
        }
    };

    const addInternalSupplier = async (data) => {
        const supplierDetails = {
            name: data.name,
            city: data.city,
            email: data.email,
            phone: data.phone,
            address: data.address,
            town: data.town,
            ghana_post: data.ghana_post

        }

        try {
            setLoading(true)
            const response = await api.post(api_url + `/api/v1/retailer/user/${id}/branch/${branch_id}/contact/${data.id}/internal`, supplierDetails, {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${token}`
                }
            })

            fetchExternalSuppliers()
            console.log(response.data);

            setSupplier_status('Supplier added successfully')

        } catch (error) {
            console.log(error);

        } finally {
            setLoading(false)
        }
    }


    const switchTab = (tab) => {
        setLoading(true)
        setActiveTab(tab);
    };


    const externalSupplierToAdd = (supplier) => {
        setSelectedSupplier(supplier)
        setShowAddSupplierModal(true)

    }
    return (
        <div>
            {loading && ( // Display loader while loading
                <div className="flex justify-center items-center w-full h-full bg-gradient-to-br from-opacity-40 to-opacity-60 backdrop-blur-sm absolute top-0 left-0">
                    <InfinitySpin color="#059212" loading={loading} size={40} />
                </div>
            )}
            <div className='flex flex-col sm:flex-row justify-between items-center mb-'>
                <div className='flex overflow-auto xl:gap-10 font-lufga mb-3 '>
                    <button
                        className={`text-xs sm:text-sm cursor-pointer py-2 px-4 ${activeTab === 'added'
                            ? 'border-b-2 border-green-500 text-green-500'
                            : 'text-gray-500'
                            }`}
                        onClick={() => switchTab('added')}
                    >
                        Added Suppliers
                    </button>
                    <button
                        className={`text-xs sm:text-sm cursor-pointer py-2 px-4 ${activeTab === 'not-added'
                            ? 'border-b-2 border-green-500 text-green-500'
                            : 'text-gray-500'
                            }`}
                        onClick={() => switchTab('not-added')}
                    >
                        Non-Added Suppliers
                    </button>

                </div>
                <div className='flex items-center gap-2'>
                    <input
                        type='text'
                        value={search}
                        onChange={(e) => setSearch(e.target.value)}
                        placeholder='Search suppliers'
                        className='w-38 sm:w-[200px] focus:outline-none rounded-md shadow-sm border px-2 py-3 h-3 font-lufga text-xs'
                    />
                    {searchLoading ? (
                        <ColorRing
                            height="20"
                            width="20"
                            ariaLabel="color-ring-loading"
                            wrapperStyle={{}}
                            wrapperClass="color-ring-wrapper"
                            colors={['#e15b64', '#f47e60', '#f8b26a', '#abbd81', '#849b87']}
                        />
                    ) : (
                        <FcSearch onClick={() => { handleSearchClick(search) }} className='hover:scale-110 cursor-pointer' />
                    )}
                </div>
            </div>




            {suppliers?.length > 0 ? (<div className='w-full flex flex-wrap gap-4 px-[2px] justify-center sm:justify-normal items-center py-2 '>
                {suppliers?.map(supplier => (
                    <div
                        key={supplier.id}
                        className="flex justify-center items-start "
                        onClick={() => activeTab === 'added' ? onSelectSupplier(supplier) : externalSupplierToAdd(supplier)}
                    >
                        <div className={`bg-gradient-to-br ${activeTab === 'added' ? 'from-green-100 to-green-300 border-[1px] text-green-900 border-green-500' : 'from-gray-100 to-gray-300 border-[1px] text-gray-900 border-gray-500'} w-[230px] hover:scale-105 duration-150 cursor-pointer ease-in max-h-min rounded-lg shadow-lg p-2 flex flex-col gap-2`}>
                            <div className='flex flex-col'>
                                <h3 className='text-sm w-[190px] truncate hover:text-clip font-lufga'>{supplier?.name}</h3>
                                <p className='text-xs w-[190px] truncate hover:text-clip '>{supplier?.address}</p>
                                <p className='text-xs w-[190px] truncate hover:text-clip '>Contact: {supplier?.phone}</p>
                            </div>
                            <div className='flex justify-end'>
                                {activeTab === 'added' ? (
                                    null) : (
                                    <button
                                        onClick={(e) => {
                                            e.stopPropagation();
                                            setShowAddSupplierModal(true);
                                        }}
                                        className="bg-green-500 hover:opacity-90 text-white py-1 px-4 rounded-md shadow-sm text-xs"
                                    >
                                        Add Supplier
                                    </button>
                                )}
                            </div>
                        </div>
                    </div>
                ))}
            </div>) :
                <div>
                    <p>Empty list</p>
                </div>
            }

            {showAddSupplierModal && (
                <div className="fixed inset-0 flex items-center justify-center bg-gray-800 bg-opacity-50">
                    <div ref={modalRef} className="bg-white rounded-md min-h-max shadow-lg p-6">
                        <MdClose
                            size={24}
                            onClick={() => setShowAddSupplierModal(false)}
                            className='hover:scale-105 hover:text-red-800 transition duration-150 ease-out cursor-pointer'
                        />
                        <AddOrderSupplierModal toggle={() => setShowAddSupplierModal(false)}
                            addInternalSupplier={addInternalSupplier}
                            selectedSupplier={selectedSupplier} />
                    </div>
                </div>
            )}
            {/* <div className='flex items-center gap-4 mt-4'>
                <button onClick={handlePrevPage} disabled={currentPage === 1} className={currentPage === 1 ? 'text-gray-500 text-xs' : 'text-xs text-green-600'}>Previous</button>
                <div className='flex items-center gap-3'>
                    {Array.from({ length: lastPage }, (_, i) => (
                        <button key={i + 1} onClick={() => handlePageClick(i + 1)} className={`text-xs ${currentPage === i + 1 ? 'font-bold' : ''}`}>{i + 1}</button>
                    ))}
                </div>
                <button onClick={handleNextPage} disabled={currentPage === lastPage} className={currentPage === lastPage ? 'text-gray-500 text-xs' : 'text-xs text-green-600'}>Next</button>
            </div> */}
            <div className='flex items-center gap-4 mt-4'>
                <button
                    onClick={handlePrevPage}
                    disabled={currentPage === 1}
                    className={currentPage === 1 ? 'text-gray-500 text-xs' : 'text-xs text-green-600'}
                >
                    Previous
                </button>
                <div className='flex items-center gap-3'>
                    {getPageNumbers().map((pageNumber, index) => (
                        <React.Fragment key={index}>
                            {pageNumber === '...' ? (
                                <span className="text-xs">...</span>
                            ) : (
                                <button
                                    onClick={() => handlePageClick(pageNumber)}
                                    className={`text-xs ${currentPage === pageNumber ? 'font-bold' : ''}`}
                                >
                                    {pageNumber}
                                </button>
                            )}
                        </React.Fragment>
                    ))}
                </div>
                <button
                    onClick={handleNextPage}
                    disabled={currentPage === lastPage}
                    className={currentPage === lastPage ? 'text-gray-500 text-xs' : 'text-xs text-green-600'}
                >
                    Next
                </button>
            </div>
        </div>
    );
}

export default ChooseSupplier;
