import React, { useState } from 'react';
import SaleComments from './SaleComments';
import { GrEdit } from 'react-icons/gr';

const SaleDetail = ({ toggle, data,roles }) => {
    const [view, setView] = useState('details');

    const handleViewComments = () => {
        setView('comments');
    };

    const handleViewDetails = () => {
        setView('details');
    };


    return (
        <div>
            <div className='my-2'>
                {view === 'details' ? <button onClick={handleViewComments} className='font-lufga text-center font-semibold text-neutral-500 p-2 text-xs rounded-md shadow-md flex items-center gap-1 hover:bg-neutral-200'>View comments</button>
                    : <button onClick={handleViewDetails} className='font-lufga text-center font-semibold text-neutral-500 p-2 text-xs rounded-md shadow-md flex items-center gap-1 hover:bg-neutral-200'>
                        View details
                    </button>}
            </div>
            {view === 'details' && <form className="grid gap-4 mb-2 md:grid-cols-2">

                <div className="relative flex flex-col gap-1">
                    <label className="text-sm font-lufga flex items-center gap-1 font-semibold">Retailer name</label>
                    <input
                        type="text"
                        value={data?.contact.name}
                        className={`px-3 py-2  rounded-md focus:outline-none  text-sm `}
                        placeholder=""
                        required
                        disabled={true}
                    />

                </div>
                <div className="relative flex flex-col gap-1">
                    <label className="text-sm font-lufga flex items-center gap-1 font-semibold">Retailer contact</label>
                    <input
                        type="text"
                        value={data?.contact.phone}
                        className={`px-3 py-2  rounded-md focus:outline-none text-sm `}
                        placeholder=""
                        required
                        disabled={true}
                    />

                </div>
                <div className="relative flex flex-col gap-1">
                    <label className="text-sm font-lufga flex items-center gap-1 font-semibold">Retailer email</label>
                    <input
                        type="text"
                        value={data?.contact.email}
                        className={`px-3 py-2  rounded-md focus:outline-none text-sm `}
                        placeholder=""
                        required
                        disabled={true}
                    />

                </div>
                <div className="relative flex flex-col gap-1">
                    <label className="text-sm font-lufga flex items-center gap-1 font-semibold">Retailer address</label>
                    <input
                        type="text"
                        value={data?.contact.address}
                        className={`px-3 py-2  rounded-md focus:outline-none text-sm `}
                        placeholder=""
                        required
                        disabled={true}
                    />

                </div>
                <div className="relative flex flex-col gap-1">
                    <label className="text-sm font-lufga flex items-center gap-1 font-semibold">Amount</label>
                    <input
                        type="text"
                        value={data?.amount}
                        className={`px-3 py-2  rounded-md focus:outline-none text-sm `}
                        placeholder=""
                        required
                        disabled={true}
                    />

                </div>
                <div className="relative flex flex-col gap-1">
                    <label className="text-sm font-lufga flex items-center gap-1 font-semibold">Discount</label>
                    <input
                        type="text"
                        value={data?.discount}
                        className={`px-3 py-2  rounded-md focus:outline-none text-sm `}
                        placeholder=""
                        required
                        disabled={true}
                    />

                </div>
                <div className="relative flex flex-col gap-1">
                    <label className="text-sm font-lufga flex items-center gap-1 font-semibold">Date created</label>
                    <input
                        type="text"
                        value={new Date(data?.created_at).toLocaleDateString()}
                        className={`px-3 py-2  rounded-md focus:outline-none text-sm `}
                        placeholder=""
                        required
                        disabled={true}
                    />

                </div>
                <div className="relative flex flex-col gap-1">
                    <label className="text-sm font-lufga flex items-center gap-1 font-semibold">Sale Personnel</label>
                    <input
                        type="text"
                        value={`${data?.user?.first_name} ${data?.user?.last_name}`}
                        className={`px-3 py-2  rounded-md focus:outline-none text-sm `}
                        placeholder=""
                        required
                        disabled={true}
                    />

                </div>

                <div className='flex flex-col gap-1.5'>
                    <label className="text-sm font-lufga flex items-center gap-1 font-semibold">Sale items</label>

                    <div>
                        <ol className='text-xs max-h-[30vh] overflow-auto list-decimal pl-5'>
                            {data?.stock?.map(item => (
                                <li key={item.id} className='py-2 borber-b border-black'>
                                    <p><strong>Product:</strong> {item?.inventory?.name}</p>
                                    <p><strong>Quantity:</strong> {item?.quantity}</p>
                                    <p><strong>Price:</strong> {item?.amount}</p>
                                    <p><strong>Manufacturer:</strong> {item?.inventory.manufacturer}</p>
                                    <p><strong>Supplier:</strong> {item?.supplier.name}</p>
                                </li>
                            ))}
                        </ol>
                    </div>
                </div>



            </form>}

            {view === 'comments' && (
                <SaleComments data={data} roles={roles}/>
            )}
        </div>
    );
}

export default SaleDetail;
