import React, { useState } from 'react';
import { BsFillFileEarmarkCheckFill } from 'react-icons/bs';

const PreparingOrderComponent = () => {
 return (
        <div className="flex justify-center items-center h-full py-6">
            <div className="bg-white rounded-lg shadow-md p-6 text-center max-w-sm">
                <div className="flex justify-center text-4xl mb-4">
                    <BsFillFileEarmarkCheckFill />
                </div>
                <p className="text-gray-700 leading-relaxed">
                    Your order has been accepted and is currently being prepared.
                </p>
            </div>
        </div>
    );
}

export default PreparingOrderComponent;
