import React, { useState } from 'react';
import { MdShoppingCart } from 'react-icons/md';
import { SiNike } from 'react-icons/si';
import api from '../../../utils/api';
import { api_url } from '../../../config';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { ColorRing } from 'react-loader-spinner';
import { hasPermission } from '../../../Redux/permissionSlice';

const ShippedComponent = ({ order, fetchOrder,roles }) => {

    const { id, token, branch_id } = useSelector((state) => state.auth)
    const [loading, setLoading] = useState(false)
    const UpdateOrder = async () => {
        if (!navigator.onLine) {
            toast("You're offline");
            setLoading(false);
            return;
        }
        const data = {
            status: "received"
        }

        try {
            setLoading(true)
            const response = await api.put(api_url + `/api/v1/retailer/user/${id}/branch/${branch_id}/order/${order.id}`, { data: data }, {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${token}`
                },

            })
            fetchOrder()
        } catch (error) {
            console.log(error);
            toast("Error fetching order")

        } finally {
            setLoading(false)
        }
    }
    return (
        <div>
            <div className="flex justify-center items-center h-full py-6">
                <div className="bg-white rounded-lg shadow-md p-6 text-center max-w-sm">
                    <div className="flex justify-center text-4xl mb-4">
                        <MdShoppingCart />
                    </div>
                    <p className="text-gray-700 leading-relaxed">
                        Your package is being delivered. Please confirm when received!
                    </p>
                </div>

            </div>
          

            {hasPermission(roles, ['orderWriter', 'orderAdmin']) && (
                <div className=' flex justify-end'>
                <button onClick={UpdateOrder} className="bg-green-500  hover:opacity-90 text-white py-1 px-4 rounded-md shadow-md text-xs">
                    {loading ? <ColorRing
                        height="20"
                        width="20"
                        ariaLabel="color-ring-loading"
                        wrapperStyle={{}}
                        wrapperClass="color-ring-wrapper"
                        colors={['#e15b64', '#f47e60', '#f8b26a', '#abbd81', '#849b87']}
                    /> : 'Package Received'}
                </button>

            </div>
            )}
        </div>
    );
}

export default ShippedComponent;
