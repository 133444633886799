import React, { useState } from 'react';
import { InfinitySpin } from 'react-loader-spinner';
import { api_url } from '../../../config';
import { useSelector } from 'react-redux';
import axios from 'axios';
import { toast } from 'react-toastify';
import api from '../../../utils/api';


const AddSupplier = ({ toggle,fetchSuppliers }) => {
    const [suppliers, setSuppliers] = useState([])
    const [empty, setEmpty] = useState(0)
    const [name, setName] = useState('')
    const [location, setLocation] = useState('')
    const [city, setCity] = useState('')
    const [address, setAddress] = useState('')
    const [phone, setPhone] = useState('')
    const [email, setEmail] = useState('')
    const [town, setTown] = useState('')
    const [ghana_post, setGhana_post] = useState('')
    const [regdate, setRegdate] = useState('')
    const [user, setUser] = useState('')
    const [supplier_status, setSupplier_status] = useState('')
    const [selectedStatus, setSelectedStatus] = useState('');
    const status = ['All', 'Active', 'Inactive']
    const [loading, setLoading] = useState(false)
    const [error, setError] = useState('')
    const {id,branch_id,token} =useSelector((state)=>state.auth)
    

    const addSupplier = async (e) => {
        if (e) {
            e.preventDefault();
        }
        const supplierDetails = {
            name,
            city,
            email,
            phone,
            address,
            town,
            ghana_post

        }
        const required = !name || !email;
        if (required) {
            setError(`Please fill all required field`)
            setTimeout(() => {
                setError('');
            }, 6000);
            return
        }
        if(!navigator.onLine){
            toast("You're offline")
            return
           }
        try {
            setLoading(true)
            const response = await api.post(api_url + `/api/v1/wholesaler/user/${id}/branch/${branch_id}/supplier`, supplierDetails, {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${token}`
                }
            })
            toast.success('Supplier added successfully')
            fetchSuppliers()
            setName('')
            setPhone('')
            setEmail('')
            setGhana_post('')
            setCity('')
            setAddress('')
            setTown('')
            setSupplier_status('Supplier added successfully')
            setTimeout(() => {
                toggle()
            }, 2000);
            setLoading(false)

        } catch (error) {
            setError(error?.response?.data?.error)
            setTimeout(() => {
                setError('');
            }, 6000);
            setLoading(false)

        } 
    }

    return (
        <div className='max-w-2xl sm:min-h-max sm:max-h-min max-h-[500px] overflow-auto sm:min-w-[300px] lg:min-w-[600px] mx-auto p-4'>
            <div className=' mb-6'>
                <p className='font-lufga text-center font-semibold text-neutral-500'>Create New Supplier</p>

            </div>
            <form className="grid gap-4 mb-6 md:grid-cols-2">
                {loading && ( // Display loader while loading
                    <div className="flex justify-center items-center w-full h-full bg-gradient-to-br from-opacity-40 to-opacity-60 backdrop-blur-sm absolute top-0 left-0">
                        <InfinitySpin color="#008DDA" loading={loading} size={36} />
                    </div>
                )}

                <div className='flex flex-col gap-3'>
                    <label className="text-sm font-lufga flex items-center gap-1" htmlFor="name">Fullname<span className='font-serif text-red-400'>*</span></label>
                    <input
                        type="text"
                        id="name"
                        name="name"
                        value={name}
                        onChange={(e) => { setName(e.target.value) }}
                        placeholder="Enter supplier's name"
                        className="px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:border-blue-500"
                        required
                    />
                </div>
                <div className='flex flex-col gap-3'>
                    <label className="text-sm font-lufga flex items-center gap-1">Email<span className='font-serif text-red-400'>*</span></label>
                    <input
                        type="text"
                        value={email}
                        onChange={(e) => { setEmail(e.target.value) }}
                        placeholder="Enter supplier'semail"
                        className="px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:border-blue-500"
                        required
                    />
                </div>
                <div className='flex flex-col gap-3'>
                    <label className="text-sm font-lufga flex items-center gap-1" htmlFor="phone">Contact</label>
                    <input
                        type="text"
                        id="phone"
                        name="phone"
                        value={phone}
                        onChange={(e) => { setPhone(e.target.value) }}
                        placeholder="supplier's phone number"
                        className="px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:border-blue-500"
                        required
                    />
                </div>
                <div className='flex flex-col gap-3'>
                    <label className="text-sm font-lufga">City</label>
                    <input
                        type="text"
                        value={city}
                        onChange={(e) => setCity(e.target.value)}
                        placeholder=""
                        className="px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:border-blue-500"

                    />
                </div>
                <div className='flex flex-col gap-3'>
                    <label className="text-sm font-lufga">Town</label>
                    <input
                        type="text"
                        value={town}
                        onChange={(e) => setTown(e.target.value)}
                        placeholder=""
                        className="px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:border-blue-500"

                    />
                </div>
                <div className='flex flex-col gap-3'>
                    <label className="text-sm font-lufga flex items-center gap-1" htmlFor="address">Address</label>
                    <input
                        type="text"
                        value={address}
                        onChange={(e) => { setAddress(e.target.value) }}
                        placeholder=""
                        className="px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:border-blue-500"
                        required
                    />
                </div>
                <div className='flex flex-col gap-3'>
                    <label className="text-sm font-lufga flex items-center gap-1" htmlFor="address">Ghana Post</label>
                    <input
                        type="text"
                        value={ghana_post}
                        onChange={(e) => { setGhana_post(e.target.value) }}
                        placeholder=""
                        className="px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:border-blue-500"
                        required
                    />
                </div>
               
              

            </form>
            <div className='flex justify-between items-center'>
                <div>
                    {supplier_status ? <div>
                        <p className="bg-green-300 font-lufga rounded-md shadow-md px-2 py-1 text-xs text-center ">{supplier_status}</p>
                    </div> : error ? <div>
                        <p className="bg-red-300 font-lufga rounded-md text-center shadow-md px-2 py-1 text-sm ">
                            {typeof error === 'string' ? error : (
                                Object.keys(error).map((key) => (
                                    Array.isArray(error[key]) ? error[key][0] : error[key]
                                ))
                            )}
                        </p>
                    </div> : null}</div>

                <button onClick={addSupplier} className="bg-blue-500 font-lufga text-white py-2 px-4 rounded-md hover:bg-blue-600 shadow-md transition duration-300">Add Supplier</button>

            </div>

        </div>
    );
}

export default AddSupplier;
