import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Navigate, Outlet, useLocation } from 'react-router';
import { clearAuthData, selectRoles } from '../Redux/AuthSlice';
import { hasPermission, } from '../Redux/permissionSlice';

const PrivateRoute = ({ type }) => {
  const location = useLocation();
  const token = useSelector((state) => state.auth.token);
  const productType = useSelector((state) => state.auth.product_type);
  const selectedRoles = useSelector(selectRoles);
  const userRoles = Array.isArray(selectedRoles) ? selectedRoles : selectedRoles?.split(',').map(role => role.trim());
  
  const dispatch = useDispatch();

  

  const isAuthenticated = token && token !== "";
  const isCorrectProductType = productType === type;
  // const requiredRoles = JSON.parse(sessionStorage.getItem('requiredRoles')) || [];
  const requiredRoles = location.state?.requiredRoles || [];
  


  const hasRequiredPermissions = 
  location.pathname === '/wholesale/summary' || location.pathname === '/retail/summary'|| location.pathname === '/wholesale/profile'||location.pathname === '/retail/profile'||
   hasPermission(userRoles, requiredRoles);
  
  
  

  useEffect(() => {
    if (!isAuthenticated || !isCorrectProductType ||!hasRequiredPermissions ) {
      dispatch(clearAuthData());
    }
  }, [dispatch, isAuthenticated, isCorrectProductType, hasRequiredPermissions]);

  return isAuthenticated && isCorrectProductType && hasRequiredPermissions  ? (
    <Outlet />
  ) : (
    <Navigate to="/login" replace />
  );
};

export default PrivateRoute;
