import React, { useRef } from 'react';
import { SiNike } from 'react-icons/si';
import OrderInvoice from './OrderInvoice';
import { useReactToPrint } from 'react-to-print';

const ReceivedComponent = ({order}) => {
    const printRef = useRef();
    const handlePrint = useReactToPrint({
        content: () => printRef.current,
    });
    return (
        <div>
        <div className="flex justify-center items-center h-full py-6">
            <div className="bg-white rounded-lg shadow-md p-6 text-center max-w-sm">
                <div className="flex justify-center text-6xl mb-4">
                    <SiNike />
                </div>
                <p className="text-gray-700 leading-relaxed">
                   Retailer confirmed delivery. This order has been completed successfully
                </p>
            </div>

        </div>
        <div className=' flex justify-end'>
            <button  onClick={handlePrint}  className="bg-blue-500  hover:opacity-90 text-white py-1 px-4 rounded-md shadow-md text-xs">Print invoice</button>

        </div>
        <div style={{ display: 'none' }}>
                <OrderInvoice
                    order={order}
                    ref={printRef}
                />
            </div>
    </div>
    );
}

export default ReceivedComponent;
