import React, { useRef, useState } from 'react';
import { useDBContext } from '../../../context/DBContext';
import { getToday } from '../../../helper';
import { InfinitySpin } from 'react-loader-spinner';
import { useSelector } from 'react-redux';
import axios from 'axios';
import { api_url } from '../../../config';
import { toast } from 'react-toastify';
import api from '../../../utils/api';

const EditInventory = ({inventory,suppliers,toggle,fetchInventories}) => {
    const [name, setName] = useState(inventory?.product_name)
    const [supplierInput, setSupplierInput] = useState(inventory?.contact?.name);
    const [inventory_status, setInventory_status] = useState('')
    const [supplier, setSupplier] = useState(inventory?.contact.name)
    const [manufacturer, setManufacturer] = useState(inventory?.manufacturer);
    const [showSupplierSuggestions, setShowSupplierSuggestions] = useState(false);
    const [supplierSuggestions, setSupplierSuggestions] = useState([]);
    const[user,setUser] = useState()
    const [description, setDescription] = useState(inventory?.description)
    const [selectedStatus, setSelectedStatus] = useState('');
    const [selectedSupplierId, setSelectedSupplierId] = useState(inventory?.contact?.id);
    const status = ['All', 'Active', 'Inactive']
    const [loading, setLoading] = useState(false)
    const [error, setError] = useState('')
    const checker = user?.status === 'active'
    const supplierInputRef = useRef(null);
    const{id,token,branch_id} = useSelector((state)=>state.auth)
    console.log(inventory);
    

    const handleSupplierInputChange = (e) => {
        const value = e.target.value;
        setSupplierInput(value);

        // Filter suppliers based on the input value
        const filteredSuggestions = suppliers.filter(item => 
            item.name && item.name.toLowerCase().includes(value.toLowerCase())
        );

        // Debugging logs
        console.log('Filtered Suggestions:', filteredSuggestions);

        setSupplierSuggestions(filteredSuggestions);
        setShowSupplierSuggestions(true);
    };
    const handleFocus = () => {
        // Show all suppliers on focus
        setSupplierSuggestions(suppliers);
        setShowSupplierSuggestions(true);
    };
    const handleSelectSupplier = (selectedSupplier) => {
        setSupplierInput(selectedSupplier.name);
        setSelectedSupplierId(selectedSupplier.id);
        setShowSupplierSuggestions(false);
    };


    const updateInventory = async (e) => {
        e.preventDefault()
        const details = {
            product_name:name,
            description,
            contact_id:selectedSupplierId,
            manufacturer
        }
        console.log(details);
        
        const required = !name 
        if (required) {
            setError('Please fill all required fields')
            toast.error('Please fill all required fields')
            return
        }
        if(!navigator.onLine){
            toast("You're offline")
            return
           }
        try {
            setLoading(!loading)
            await api.put(api_url + `/api/v1/retailer/user/${id}/branch/${branch_id}/inventory/${inventory.id}`, details, {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${token}`
                }
            })
            fetchInventories()
            setInventory_status('Inventory updated successfully')
            toast.success('Inventory updated succesfully')
            setTimeout(() => {
                toggle()
            }, 3000)
            

        } catch (error) {
            setError(error?.response?.data?.error)
            setTimeout(() => {
                setError('');
            }, 6000);
            toast.error('Update failed')

        } finally {
          
            setLoading(false)
        }

    }

    return (
        <div className='max-w-2xl  overflow-auto sm:min-w-[300px] lg:min-w-[600px] mx-auto p-4'>
            <div className=' mb-6'>
                <p className='font-lufga text-center font-semibold text-neutral-500'>Edit Inventory</p>

            </div>
            <form className="grid gap-4 mb-6 md:grid-cols-2">
                {loading && ( // Display loader while loading
                    <div className="flex justify-center items-center w-full h-full bg-gradient-to-br from-opacity-40 to-opacity-60 backdrop-blur-sm absolute top-0 left-0 z-50">
                        <InfinitySpin color="#059212" loading={loading} size={36} />
                    </div>
                )}

                <div className='flex flex-col gap-3'>
                    <label className="text-sm font-lufga flex items-center gap-1" htmlFor="name">Product name<span className='font-serif text-red-400'>*</span></label>
                    <input
                        type="text"
                        id="name"
                        name="name"
                        value={name}
                        onChange={(e) => { setName(e.target.value) }}
                        placeholder=""
                        className="px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:border-green-500"
                        required
                    />
                </div>
                <div ref={supplierInputRef} className="relative flex flex-col gap-3">
                    <label className="text-sm font-lufga flex items-center gap-1">Supplier</label>
                    <input
                        type="text"
                        value={supplierInput}
                        onChange={handleSupplierInputChange}
                        onFocus={handleFocus}
                        className="px-3 py-2 border text-gray-400 cursor-not-allowed border-gray-200 rounded-md focus:outline-none focus:border-green-500"
                        placeholder="Type supplier..."
                        disabled={true}
                    />
                    {showSupplierSuggestions && supplierSuggestions.length > 0 && (
                        <div className="absolute z-10 w-full bg-white border max-h-[150px] overflow-auto border-gray-300 rounded-lg shadow-lg mt-20">
                            {supplierSuggestions.map((supplier, index) => (
                                <div
                                    key={index}
                                    className="py-1 px-2 hover:bg-gray-100 cursor-pointer"
                                    onClick={() => handleSelectSupplier(supplier)}
                                >
                                    <p className='text-sm'>
                                    {supplier.name}
                                    </p>
                                </div>
                            ))}
                        </div>
                    )}
                </div>
                <div className='flex flex-col gap-3'>
                    <label className="text-sm font-lufga flex items-center gap-1">Manufacturer<span className='font-serif text-red-400'>*</span></label>
                    <input
                        type="text"
                        value={manufacturer}
                        onChange={(e) => setManufacturer(e.target.value)}
                        placeholder="Enter product name"
                        className="px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:border-green-500"
                        required
                    />
                </div>
                <div className='flex flex-col gap-3'>
                    <label className="text-sm font-lufga flex items-center gap-1" >Description</label>
                    <textarea
                        type="text"
                        rows={2}
                        value={description}
                        onChange={(e) => { setDescription(e.target.value) }}
                        placeholder=""
                        className="px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:border-green-500"
                        required
                    />
                </div>
            </form>
            <div className='flex justify-between items-center'>
                <div>
                    {inventory_status ? <div>
                        <p className="bg-green-300 font-lufga rounded-md shadow-md px-2 py-1 text-xs text-center ">{inventory_status}</p>
                    </div> : error ? <div>
                        <p className="bg-red-300 font-lufga rounded-md text-center shadow-md px-2 py-1 text-sm ">
                            {typeof error === 'string' ? error : (
                                Object.keys(error).map((key) => (
                                    Array.isArray(error[key]) ? error[key][0] : error[key]
                                ))
                            )}
                        </p>
                    </div> : null}</div>

                <button onClick={updateInventory} className="bg-green-500 font-lufga text-white py-2 px-4 rounded-md hover:bg-green-600 shadow-md transition duration-300">Update</button>

            </div>

        </div>

    );
}

export default EditInventory;
