import { createSlice } from '@reduxjs/toolkit';

const authSlice = createSlice({
    name: 'auth',
    initialState: {
        id: sessionStorage.getItem('id') || null,
        uuid: sessionStorage.getItem('uuid') || null,
        first_name: sessionStorage.getItem('first_name') || null,
        last_name: sessionStorage.getItem('last_name') || null,
        email: sessionStorage.getItem('email') || null,
        branch_id: sessionStorage.getItem('branch_id'),
        created_at: sessionStorage.getItem('created_at') || null,
        product_type: sessionStorage.getItem('product_type') || null,
        title: sessionStorage.getItem('title') || null,
        reset_password: sessionStorage.getItem('reset_password') || null,
        roles:sessionStorage.getItem('roles') || null,
        // roles: JSON.parse(sessionStorage.getItem('roles')) || [],
        token: sessionStorage.getItem('token') || null,
        token_type: 'Bearer',
        loading: false
    },
    reducers: {
        setAuthData: (state, action) => {
            const { id, uuid, first_name,last_name, email, branch_id, created_at, reset_password, token, product_type,title,roles } = action.payload;
            state.id = id;
            state.uuid = uuid;
            state.first_name = first_name;
            state.last_name = last_name;
            state.email = email;
            state.branch_id = branch_id;
            state.created_at = created_at;
            state.reset_password = reset_password;
            state.product_type = product_type;
            state.title = title;
            state.roles = roles;
            state.token = token;

            // Update sessionStorage...here adey use for the login
            sessionStorage.setItem('token', token);
            sessionStorage.setItem('id', id);
            sessionStorage.setItem('uuid', uuid);
            sessionStorage.setItem('first_name', first_name);
            sessionStorage.setItem('last_name', last_name);
            sessionStorage.setItem('email', email);
            sessionStorage.setItem('created_at', created_at);
            sessionStorage.setItem('reset_password', reset_password);
            sessionStorage.setItem('product_type', product_type);
            sessionStorage.setItem('branch_id', branch_id);
            sessionStorage.setItem('title', title);
            sessionStorage.setItem('roles', roles);
        },
        clearAuthData: (state) => {
            state.id = null;
            state.uuid = null;
            state.first_name = null;
            state.last_name=null;
            state.email = null;
            state.branch_id = null;
            state.created_at = null;
            state.title = null;
            state.reset_password = null;
            state.token = null;
            state.loading = false
            // state.roles = null
            state.roles = [];


            // Clear sessionStorage...usually I dey use for the logout
            sessionStorage.removeItem('token');
            sessionStorage.removeItem('id');
            sessionStorage.removeItem('uuid');
            sessionStorage.removeItem('first_name');
            sessionStorage.removeItem('last_name');
            sessionStorage.removeItem('email');
            sessionStorage.removeItem('created_at');
            sessionStorage.removeItem('product_type');
            sessionStorage.removeItem('reset_password');
            sessionStorage.removeItem('title');
            sessionStorage.removeItem('roles');
            sessionStorage.removeItem('branch_id');
        },
        logoutState: (state, action) => {
            state.loading = action.payload
        }
    },
});

export const selectAuthData = (state) => state.auth;
export const selectRoles = (state) => state.auth.roles;
export const { setAuthData, clearAuthData, logoutState } = authSlice.actions;
export default authSlice.reducer;

