import React from 'react';
import BusinessInfo from '../../components/Retaillib/executive_dashboard/BusinessInfo';

const RetailDashboard = () => {
    return (
        <div className=''>
            <BusinessInfo/>
        </div>
    );
}

export default RetailDashboard;
