import React from 'react';
import { MdShoppingCart, MdShoppingCartCheckout } from 'react-icons/md';

const PaidAwaitingShippingComponent = () => {
      return (
        <div className="flex justify-center items-center h-full py-6">
        <div className="bg-white rounded-lg shadow-md p-6 text-center max-w-sm">
            <div className="flex justify-center text-4xl mb-4">
                <MdShoppingCartCheckout />
            </div>
            <p className="text-gray-700 leading-relaxed">
                Your order is now on  it's way
            </p>
        </div>
    </div>
    );
}

export default PaidAwaitingShippingComponent;
