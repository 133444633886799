import React from 'react';
import { IoMdClose } from 'react-icons/io';

const OrderFullDetails = ({ order, toggle }) => {
    return (
        <div className='bg-gray-100 rounded-lg shadow-md'>
            <IoMdClose size={24} onClick={toggle} className='hover:scale-105 hover:text-red-800 transition duration-150 ease-out cursor-pointer p-1' />

            <div className="max-w-2xl mx-auto p-6  max-h-[70vh] overflow-auto">

                <div className="mb-6">
                    <h3 className="text-base mb-2">Supplier: <span className='font-semibold'>{order?.wholesaler?.name}</span></h3>
                    <p className="text-xs">{order?.status}</p>
                    <p className="text-xs">discount: <span className='font-semibold'>{order?.discount}</span></p>
                    <p className="text-xs">Total: <span className='font-semibold'>{order?.invoice_total}</span></p>
                </div>

                <div className="mb-6">
                    <h3 className="text-base font-semibold mb-2">Cart</h3>
                    <table className="w-full">
                        <thead>
                            <tr className="border-b text-sm">
                                <th className="text-left py-2">#</th>
                                <th className="text-left py-2">Product Name</th>
                                <th className="text-left py-2">Manufacturer</th>
                                <th className="text-right py-2">Quantity</th>
                            </tr>
                        </thead>
                        <tbody className='text-xs'>
                            {order?.order_items?.map((item, index) => (
                                <tr key={item?.id} className="border-b">
                                    <td className="py-2">{index + 1}.</td>
                                    <td className="py-2">{item?.product_name}</td>
                                    <td className="py-2">{item?.manufacturer}</td>
                                    <td className="py-2 text-right">{item?.demand_quantity}</td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>



            </div>
        </div>
    );
}

export default OrderFullDetails;
