import React, { useState ,useEffect } from 'react';
import { toast } from 'react-toastify';
import {api_url} from '../../../config';
import { Switch } from 'antd';
import { InfinitySpin } from 'react-loader-spinner';
import { useSelector } from 'react-redux';
import axios from 'axios';
import api from '../../../utils/api';

const EditRetailer= ({toggle,data,fetchRetailers}) => {
    const [name, setName] = useState(data?.name)
    const [city, setCity] = useState(data?.city)
    const [address, setAddress] = useState(data?.address)
    const [phone, setPhone] = useState(data?.phone)
    const [email, setEmail] = useState(data?.email)
    const [town, setTown] = useState(data?.town)
    const [ghana_post, setGhana_post] = useState(data?.ghana_post)
    const [user, setUser] = useState('')
    const [retailer_status, setRetailer_status] = useState('')
    const [selectedStatus, setSelectedStatus] = useState('');
    const [status,setStatus] =useState(data?.status)
    const [loading, setLoading] = useState(false)
    const [error, setError] = useState('')
    const checker = data?.status === 'active'

    const{id,token,branch_id} = useSelector((state)=>state.auth)

   
    const updateRetailer = async (e) => {
        e.preventDefault()
        const details = {
            name,
            city,
            address,
            phone,
            email,
            town,
            ghana_post,
            status
        }
        const required = !name || !city || !address || !phone || !email
        if (required) {
            setError('Please fill all required fields')
            return
        }
        if(!navigator.onLine){
            toast("You're offline")
            return
           }
        try {
            setLoading(!loading)
            await api.put(api_url + `/api/v1/wholesaler/user/${id}/branch/${branch_id}/contact/${data.id}/external`, details, {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${token}`
                }
            })
            fetchRetailers()
            setRetailer_status('Retailer updated successfully')
            toast.success('Retailer updated succesfully')
            setTimeout(() => {
                toggle()
            }, 3000)
            

        } catch (error) {
            setError(error?.response?.data?.error)
            setTimeout(() => {
                setError('');
            }, 6000);
            toast.error('Update failed')

        } finally {
          
            setLoading(false)
        }

    }
    
    const handleRetailerStatus = (checked) => {
        const status = checked ? 'active' : 'inactive';
        setStatus(status);
    }

    return (
        <div className='max-w-2xl  overflow-auto sm:min-w-[300px] lg:min-w-[600px] mx-auto p-4'>
             <div className=' mb-6 flex justify-between  items-center '>
                <p className='font-lufga text-center font-semibold text-neutral-500'>Edit Retailer</p>
                <div className='flex items-center gap-3 '>
                    <label className="text-sm font-lufga" >Status</label>
                    <Switch
                        defaultChecked={checker}
                        checkedChildren="active"
                        unCheckedChildren="inactive"
                        onChange={handleRetailerStatus}

                        
                       

                    />
                </div>
            </div>
            <form className="grid gap-4 mb-6 md:grid-cols-2">
                {loading && ( // Display loader while loading
                    <div className="flex justify-center items-center w-full h-full bg-gradient-to-br from-opacity-40 to-opacity-60 backdrop-blur-sm absolute top-0 left-0">
                        <InfinitySpin color="#008DDA" loading={loading} size={36} />
                    </div>
                )}

                <div className='flex flex-col gap-3'>
                    <label className="text-sm font-lufga flex items-center gap-1" htmlFor="name">Name<span className='font-serif text-red-400'>*</span></label>
                    <input
                        type="text"
                        id="name"
                        name="name"
                        value={name}
                        onChange={(e) => { setName(e.target.value) }}
                        placeholder=""
                        className="px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:border-blue-500"
                        required
                    />
                </div>
                <div className='flex flex-col gap-3'>
                    <label className="text-sm font-lufga flex items-center gap-1">Email<span className='font-serif text-red-400'>*</span></label>
                    <input
                        type="text"
                        value={email}
                        onChange={(e) => { setEmail(e.target.value) }}
                        placeholder=""
                        className="px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:border-blue-500"
                        required
                    />
                </div>
                <div className='flex flex-col gap-3'>
                    <label className="text-sm font-lufga flex items-center gap-1" htmlFor="phone">Contact</label>
                    <input
                        type="text"
                        id="phone"
                        name="phone"
                        value={phone}
                        onChange={(e) => { setPhone(e.target.value) }}
                        placeholder=""
                        className="px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:border-blue-500"
                        required
                    />
                </div>
                <div className='flex flex-col gap-3'>
                    <label className="text-sm font-lufga">City</label>
                    <input
                        type="text"
                        value={city}
                        onChange={(e) => setCity(e.target.value)}
                        placeholder=""
                        className="px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:border-blue-500"

                    />
                </div>
                <div className='flex flex-col gap-3'>
                            <label className="text-sm font-lufga">Town</label>
                            <input
                                type="text"
                                value={town}
                                onChange={(e) => setTown(e.target.value)}
                                placeholder=""
                                className="px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:border-blue-500"

                            />
                        </div>
                        <div className='flex flex-col gap-3'>
                            <label className="text-sm font-lufga flex items-center gap-1" htmlFor="address">Address</label>
                            <input
                                type="text"
                                value={address}
                                onChange={(e) => { setAddress(e.target.value) }}
                                placeholder=""
                                className="px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:border-blue-500"
                                required
                            />
                        </div>
                        <div className='flex flex-col gap-3'>
                            <label className="text-sm font-lufga flex items-center gap-1" >Ghana Post</label>
                            <input
                                type="text"
                                value={ghana_post}
                                onChange={(e) => { setGhana_post(e.target.value) }}
                                placeholder=""
                                className="px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:border-blue-500"
                                required
                            />
                        </div>
              

            </form>
            <div className='flex justify-between items-center'>
                <div>
                    {retailer_status ? <div>
                        <p className="bg-green-300 font-lufga rounded-md shadow-md px-2 py-1 text-xs text-center ">{retailer_status}</p>
                    </div> : error ? <div>
                        <p className="bg-red-300 font-lufga rounded-md text-center shadow-md px-2 py-1 text-sm ">
                            {typeof error === 'string' ? error : (
                                Object.keys(error).map((key) => (
                                    Array.isArray(error[key]) ? error[key][0] : error[key]
                                ))
                            )}
                        </p>
                    </div> : null}</div>

                <button onClick={updateRetailer} className="bg-blue-500 font-lufga text-white py-2 px-4 rounded-md hover:bg-blue-600 shadow-md transition duration-300">Update</button>

            </div>

        </div>

    );
}

export default EditRetailer;
