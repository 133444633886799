import React, { useState } from 'react';
import { MdShoppingCart, MdShoppingCartCheckout } from 'react-icons/md';
import { useSelector } from 'react-redux';
import api from '../../../utils/api';
import { api_url } from '../../../config';
import { toast } from 'react-toastify';
import { ColorRing } from 'react-loader-spinner';
import { hasPermission } from '../../../Redux/permissionSlice';

const PaidAwaitingShippingComponent = ({order,fetchOrder,roles}) => {
    const [loading, setLoading] = useState(false)
    const [toggle, setToggle] = useState(false)
    const { id, branch_id, token } = useSelector((state) => state.auth)

    
    const UpdateOrder = async (status) => {
        if (!navigator.onLine) {
            toast("You're offline");
            setLoading(false);
            return;
        }


        try {
            setLoading(true)
            await api.put(api_url + `/api/v1/wholesaler/user/${id}/branch/${branch_id}/order/${order.id}`, { data: { status } }, {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${token}`
                },

            })
            fetchOrder()
        } catch (error) {
            console.log(error);
            toast("Error fetching order")

        } finally {
            setLoading(false)

        }
    }


    return (
   
        <div>
            <div className="flex justify-center items-center h-full py-6">
                <div className="bg-white rounded-lg shadow-md p-6 text-center max-w-sm">
                    <div className="flex justify-center text-4xl mb-4">
                        <MdShoppingCart />
                    </div>
                    <p className="text-gray-700 leading-relaxed">
                        Order finalized and confirmed, time to ship the order
                    </p>
                </div>

            </div>
           
            {hasPermission(roles, ['orderWriter', 'orderAdmin']) && (
                <div className='flex justify-end mt-10'>
                {!loading ? <div className='flex items-center gap-3'>            
                    <button onClick={() => { UpdateOrder('shipped') }}  className="bg-blue-500 hover:opacity-90 text-white py-1 px-4 rounded-md shadow-md text-xs">
                        Ship Products
                    </button>
                </div> : <ColorRing height="20" width="20" colors={['#e15b64', '#f47e60', '#f8b26a', '#abbd81', '#849b87']} />}
            </div>
            )}

        </div>
    );
}

export default PaidAwaitingShippingComponent;
