import React from 'react';
import { MdShoppingCartCheckout } from 'react-icons/md';
import { SiNike } from 'react-icons/si';

const ShippedComponent = () => {
    return (
         <div className="flex justify-center items-center h-full py-6">
        <div className="bg-white rounded-lg shadow-md p-6 text-center max-w-sm">
            <div className="flex justify-center text-4xl mb-4">
                <MdShoppingCartCheckout />
            </div>
            <p className="text-gray-700 leading-relaxed">
                Retailer will confirm when package is delivered
            </p>
        </div>
    </div>
    );
}

export default ShippedComponent;
