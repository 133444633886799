import React from 'react';

const OrderInvoice = React.forwardRef((props,ref) => {

   const {order} = props;
console.log(order);

    return (
        <div ref={ref} className="bg-white p-8 max-w-4xl mx-auto my-8 shadow-lg font-lufga" id="invoice-summary">
            <h2 className="text-2xl font-bold mb-4">Invoice Summary</h2>
            <div className="mb-4 text-sm">
                <p><strong>Order ID:</strong> {order?.id}</p>
                <p><strong>Retailer:</strong> {order?.retailer.name}</p>
                <p><strong>Date:</strong> {new Date(order?.created_at).toLocaleDateString()}</p>
                <p><strong>Status:</strong> {order?.status}</p>
            </div>
            <table className="w-full border-collapse border border-gray-300 text-xs mb-4">
                <thead>
                    <tr className="bg-gray-100">
                        <th className="border border-gray-300 p-2">Product Name</th>
                        <th className="border border-gray-300 p-2">Manufacturer</th>
                        <th className="border border-gray-300 p-2">Demanded Quantity</th>
                        <th className="border border-gray-300 p-2">Stocks</th>
                    </tr>
                </thead>
                <tbody>
                    {order?.order_items.map((item) => (
                        <tr key={item.id} className="border-b border-gray-300">
                            <td className="border border-gray-300 p-2">{item?.product_name}</td>
                            <td className="border border-gray-300 p-2">{item?.manufacturer}</td>
                            <td className="border border-gray-300 p-2 text-center">{item?.demand_quantity}</td>
                            <td className="border border-gray-300 p-2">
                                <table className="w-full border-collapse">
                                    <thead>
                                        <tr className="bg-gray-50">
                                            <th className="border border-gray-200 p-1">ID</th>
                                            <th className="border border-gray-200 p-1">Supply Quantity</th>
                                            <th className="border border-gray-200 p-1">Unit Price</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {item?.order_stocks.map((stock) => (
                                            <tr key={stock.id}>
                                                <td className="border border-gray-200 p-1 text-center">{stock?.wholesale_stock?.id}</td>
                                                <td className="border border-gray-200 p-1 text-center">{stock?.wholesale_stock?.supply_quantity}</td>
                                                <td className="border border-gray-200 p-1 text-center">{stock?.wholesale_stock?.unit_price}</td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>
            <div className="text-right text-sm">
                <p><strong>Discount:</strong> {order?.discount}</p>
                <p className="text-base "><strong>Total:</strong> {order?.invoice_total}</p>
            </div>
        </div>
    );
})

export default OrderInvoice;
