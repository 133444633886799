import React, { useState } from 'react';
import { ColorRing, InfinitySpin } from 'react-loader-spinner';
import { useSelector } from 'react-redux';

const AddOrderSupplierModal = ({toggle,addInternalSupplier,selectedSupplier}) => {

    const [loading, setLoading] = useState(false)
    const [error, setError] = useState('')
    const [status, setStatus] = useState('')
    console.log(selectedSupplier);

    const handleAddSupplier = async () => {
        setLoading(true)
        try {
            await addInternalSupplier(selectedSupplier);
            setLoading(false)
            setStatus('Supplier added successfully')
            setTimeout(()=>{
                toggle();
            },2000)

        } catch (error) {
            setError('Failed to add supplier. Please try again.');
            console.error(error);
        }finally{
            setLoading(false)
        }
    };
    
    const { token,id } = useSelector((state) => state.auth)
    return (
        <div>
            <div className="card sm:w-96 bg-neutral text-neutral-content">
                <div className="card-body items-center text-center">
                {loading && ( // Display loader while loading
                    <div className="flex justify-center items-center w-full h-full bg-gradient-to-br from-opacity-40 to-opacity-60 backdrop-blur-sm absolute top-0 left-0">
                        <ColorRing
                       height="20"
                       width="20"
                       ariaLabel="color-ring-loading"
                       wrapperStyle={{}}
                       wrapperClass="color-ring-wrapper"
                       colors={['#e15b64', '#f47e60', '#f8b26a', '#abbd81', '#849b87']}
                   /> 
                    </div>
                )}
                    <h2 className="font-bold">Add Supplier</h2>

                    <div>
                        <p className='text-xs sm:text-sm'>Are you sure you want to add this supplier?</p>
                    </div>
                    <div className="flex items-center justify-center gap-3 my-2">
                        <button onClick={() => { toggle() }} className="bg-gray-500 font-lufga text-white py-1 text-xs sm:text-sm px-4 rounded-md hover:bg-gray-600 shadow-md transition duration-300">Cancel</button>
                        <button onClick={handleAddSupplier} className="bg-green-500 font-lufga text-white py-1 text-xs sm:text-sm px-4 rounded-md hover:bg-green-600 shadow-md transition duration-300">YES</button>
                    </div>
                    <div>
                    {status ? <div>
                        <p className="bg-green-300 font-lufga rounded-md shadow-md px-2 py-1 text-xs text-center ">{status}</p>
                    </div> : error ? <div>
                        <p className="bg-red-300 font-lufga rounded-md text-center shadow-md px-2 py-1 text-sm ">
                            {typeof error === 'string' ? error : (
                                Object.keys(error).map((key) => (
                                    Array.isArray(error[key]) ? error[key][0] : error[key]
                                ))
                            )}
                        </p>
                    </div> : null}</div>
                </div>
            </div>
        </div>
    );
}

export default AddOrderSupplierModal;
