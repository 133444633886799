import React, { useEffect, useRef, useState } from 'react';
import { useLocation, useNavigate } from 'react-router';
import { MdClose, MdOutlineAutoDelete } from 'react-icons/md';
import { FaChevronDown, FaRegEye, FaRegFileArchive } from 'react-icons/fa';
import { ColorRing, InfinitySpin } from 'react-loader-spinner';
import { api_url } from '../../../config';
import axios from 'axios';
import { useDispatch, useSelector } from 'react-redux';
import EditUser from './EditUser';
import { CiExport, CiMenuKebab } from "react-icons/ci";
import { GrEdit } from 'react-icons/gr';
import { IoArchiveOutline } from 'react-icons/io5';
import UserDetails from './UserDetails';
import { IoArrowBackCircleOutline } from "react-icons/io5";
import { VscLayersActive } from 'react-icons/vsc';
import { FcSearch } from 'react-icons/fc';
import { IoIosArrowRoundDown, IoIosArrowRoundUp } from 'react-icons/io';
import { hasPermission } from '../../../Redux/permissionSlice';
import { selectRoles } from '../../../Redux/AuthSlice';
import DeleteUserModal from './DeleteUserModal';
import ArchiveUserModal from './ArchiveUserModal';
import AddUserForm from './AddUserForm';
import api from '../../../utils/api';
import { getPageNumbers } from '../../../utils/getPageNumbers';
import exportFromJSON from 'export-from-json';

const UserInfo = () => {
    const [editMode, setEditMode] = useState(false);
    const [showEditModal, setShowEditModal] = useState(false);
    const [showEditUserModal, setShowEditUserModal] = useState(false);
    const [showDeleteUserModal, setShowDeleteUserModal] = useState(false);
    const [showArchiveUserModal, setShowArchiveUserModal] = useState(false);
    const [showReactivateUserModal, setShowReactivateUserModal] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const [selectedStatus, setSelectedStatus] = useState('');
    const [loading, setLoading] = useState(true)
    const [branchDetail, setBranchDetail] = useState(null)
    const { token, id, branch_id } = useSelector((state) => state.auth)
    const modalRef = useRef();
    const popUpRef = useRef();
    const status = ['', 'All', 'Active', 'Inactive', 'Archived']
    const [users, setUsers] = useState([]);
    const [user_amount, setUser_amount] = useState();
    const [userToEdit, setUserToEdit] = useState([]);
    const [userToDelete, setUserToDelete] = useState([]);
    const [userToArchive, setUserToArchive] = useState([]);
    const [selectedDropdown, setSelectedDropdown] = useState(null);
    const [selectedUser, setSelectedUser] = useState(null);
    const [userView, setUserView] = useState(false)
    const [selectedUserDetails, setSelectedUserDetails] = useState(null);
    const [searchQuery, setSearchQuery] = useState('');
    const [userQuery, setUserQuery] = useState('');
    const [currentPage, setCurrentPage] = useState(1);
    const [lastPage, setLastPage] = useState();
    const [limit, setLimit] = useState(50)
    const [newLimit, setNewLimit] = useState(50);
    const [filter, setFilter] = useState('')
    const [filter_by, setFilter_by] = useState('')
    const [showbtn, setShowbtn] = useState(false)
    const [search, setSearch] = useState('')
    const [hasSearched, setHasSearched] = useState(false);
    const [searchLoading, setSearchLoading] = useState(false)
    const [sortby, setSortby] = useState('-created_at')
    const [showDateSort, setShowDateSort] = useState(false)
    const [hadUsers, setHadUsers] = useState(false);
    const [metadata, setMetadata] = useState('')




    const selectedRoles = useSelector(selectRoles);
    const roles = Array.isArray(selectedRoles) ? selectedRoles : selectedRoles.split(',').map(role => role.trim());

    const handleLimitChange = (e) => {
        setNewLimit(parseInt(e.target.value));
    };

    const applyNewLimit = () => {
        setLimit(newLimit);
    };

    const handlePageClick = (pageNumber) => {
        setCurrentPage(pageNumber);
    };
    const handleStatusChange = (status) => {
        if (status === selectedStatus) {
            setLoading(false);
            return;
        }
        setHasSearched(false)
        setLoading(true);

        let newFilter;
        let newFilter_by;
        if (status === 'Active') {
            newFilter = 'active';
            newFilter_by = 'status';
        } else if (status === 'Inactive') {
            newFilter = 'inactive';
            newFilter_by = 'status';

        } else if (status === 'Archived') {
            newFilter = 'archived';
            newFilter_by = 'status';

        } else if (status === 'All') {
            newFilter = '';
            newFilter_by = '';

        } else {
            newFilter = 'active,inactive,archived';
            newFilter_by = 'status';

        }
        setFilter_by(newFilter_by)
        setFilter(newFilter);
        setShowbtn(true);

        setTimeout(() => {
            setLoading(false);
        }, 1000);
    };

    const handleEdit = () => {
        setShowEditModal(!showEditModal); // Enable edit mode
    };

    const handleDeleteUser = (event, userId) => {
        if (event) {
            event.preventDefault(); // Prevent default behavior
            event.stopPropagation();
        }
        const userToDelete = users.find(user => user.id === userId);
        if (userToDelete) {
            setShowDeleteUserModal(true);
            setUserToDelete(userToDelete); // Assuming you have state to store user to edit
        }
    };

    const togglePopUp = (event, index, user) => {
        event.stopPropagation();
        event.preventDefault();
        if (index === selectedDropdown) {
            setSelectedDropdown(null);
            setSelectedUser(null); // Deselect branch if clicking on the same dropdown
        } else {
            setSelectedDropdown(index);
            setSelectedUser(user);
        }
    };


    useEffect(() => {
        // Function to handle click outside of modal
        const handleClickOutside = (event) => {
            if (modalRef.current && !modalRef.current.contains(event.target)) {
                setShowEditModal(false)
            }
            if (popUpRef.current && !popUpRef.current.contains(event.target)) {
                setSelectedDropdown(null);
            }
        };

        // Attach the event listener
        document.addEventListener('mousedown', handleClickOutside);

        // Cleanup the event listener
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);


    useEffect(() => {
        // Function to handle click outside of modal
        const handleClickOutside = (event) => {
            if (modalRef.current && !modalRef.current.contains(event.target)) {
                setShowModal(false);
                setShowEditModal(false)
                setShowEditUserModal(false)
                setShowDeleteUserModal(false)
                setShowArchiveUserModal(false)
            }
        };

        // Attach the event listener
        document.addEventListener('mousedown', handleClickOutside);

        // Cleanup the event listener
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);
    const handleEditUser = (event, userId) => {
        if (event) {
            event.preventDefault(); // Prevent default behavior
            event.stopPropagation();
        }
        const userToEdit = users.find(user => user.id === userId);
        if (userToEdit) {
            setShowEditUserModal(true);
            setUserToEdit(userToEdit); // Assuming you have state to store user to edit
        }
    };
    const handleArchiveUser = (event, userId) => {
        if (event) {
            event.preventDefault(); // Prevent default behavior
            event.stopPropagation();
        }
        const userToArchive = users.find(user => user.id === userId);
        if (userToArchive) {
            setShowArchiveUserModal(true);
            setUserToArchive(userToArchive); // Assuming you have state to store user to edit
        }
    };

    //using this to get user details and push to the userdetails component
    const handleUserView = (selectedUser) => {
        setSelectedUserDetails(selectedUser);
        setUserView(true);
    }


    const handleNextPage = () => {
        if (currentPage < lastPage) {
            setCurrentPage(currentPage + 1);

        }
    };

    const handlePrevPage = () => {
        if (currentPage > 1) {
            setCurrentPage(currentPage - 1);
        }
    };

    const fetchUsers = async (page, limit, searchQuery) => {
        const params = {
            page: page || 1,
            limit: limit || 50,
            sort: sortby,
            [`filter[${filter_by}]`]: `${filter}`,
            search: `${searchQuery || ''}`

        };

        try {
            const response = await api.get(api_url + `/api/v1/retailer/user/${id}/branch/${branch_id}/user-profile`, {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${token}`
                },
                params
            });
            const data = response.data.data;
            const meta = response.data.meta

            setUsers(data)
            setMetadata(meta)
            setUser_amount(response.data.meta.total);
            setLastPage(response.data.meta.last_page)
            if (data.length > 0) {
                setHadUsers(true);
            }
        } catch (error) {
            console.error('Error fetching users:', error);
        } finally {
            setLoading(false)

        }
    };

    useEffect(() => {
        fetchUsers(currentPage, limit);

    }, [showEditModal, currentPage, limit, filter, filter_by, sortby])


    const handleSearchClick = async (searchQuery) => {


        setShowbtn(false);
        setHasSearched(true);
        setSearchLoading(!searchLoading)

        try {
            await fetchUsers(1, 50, searchQuery);;
            setHasSearched(true);
        } catch (error) {
            console.log(error);
        } finally {
            setSearchLoading(false)
        }


    };

    const handleSortDateAsc = () => {
        setSortby('created_at')
        setShowDateSort(!showDateSort)
    }

    const handleSortDateDsc = () => {
        setSortby('-created_at')
        setShowDateSort(!showDateSort)

    }

    const showEmptydivHelper = () => {
        setHasSearched(false)
        setShowbtn(false)
        setHadUsers(true)
    }

    const toggleUserForm = () => {
        setShowModal(!showModal)
    };

    const exportFunc = () => {
        const data = users?.map(u => ({
            id: u.id,
            Name: u.first_name + " " + u.last_name,
            Position: u.position,
            Email: u.email,
            Username: u.username,
            Contact: u.phone,
            Date_created: new Date(u?.created_at).toLocaleDateString(),
            Created_by: u.created_by,
            Status: u.status


        }))
        const fileName = 'Users'
        const exportType = exportFromJSON.types.csv

        exportFromJSON({ data, fileName, fields: ["id", "Name", "Position", "Email", "Username", "Date_created", "Created_by", "Status"], exportType })

    }


    return (

        <div className='font-lufga'>
            <div className='mb-2 mx-8 '>
                <p className='lg:text-3xl text-xl font-semibold text-slate-600'>Users ({metadata?.total || '-'})</p>
            </div>
            {
                !userView ? (
                    <div>
                        {loading && ( // Display loader while loading
                            <div className="flex justify-center items-center w-full h-full bg-gradient-to-br from-opacity-40 to-opacity-60 backdrop-blur-sm absolute top-0 left-0">
                                <InfinitySpin color="#059212" loading={loading} size={40} />
                            </div>
                        )}
                        <div className={users.length === 0 ? 'hidden' : 'flex flex-col gap-4 mb-4 sm:flex-row justify-between items-center font-lufga mx-6'}>
                            <div className='flex items-center gap-2'>
                                <p className='text-sm'  >List per page</p>
                                <input className='border-[1px] w-16 px-2 text-xs' type="number" id="limit" value={newLimit} onChange={handleLimitChange} />
                                <button className="bg-green-500  hover:opacity-90 text-white py-1 px-4 rounded-md shadow-sm text-xs" onClick={applyNewLimit}>Apply</button>
                            </div>


                            <div className='flex flex-col sm:flex-row items-center gap-4'>
                                <div className='flex items-center gap-2'>
                                    <input type='text' value={search} onChange={(e) => { setSearch(e.target.value) }} placeholder='Search users' className='w-38 sm:w-[200px] focus:outline-none rounded-md shadow-sm border px-2 py-3 h-3 font-lufga text-xs' />
                                    {searchLoading ? <ColorRing
                                        height="20"
                                        width="20"
                                        ariaLabel="color-ring-loading"
                                        wrapperStyle={{}}
                                        wrapperClass="color-ring-wrapper"
                                        colors={['#e15b64', '#f47e60', '#f8b26a', '#abbd81', '#849b87']}
                                    /> : <FcSearch onClick={() => { handleSearchClick(search) }} className='hover:scale-110 cursor-pointer' />}

                                </div>


                                {hasPermission(roles, ['userWriter', 'userAdmin']) && (
                                    <div>
                                        <button onClick={toggleUserForm} className="bg-green-500  hover:opacity-90 text-white py-1 px-4 rounded-md shadow-sm text-xs">Add User</button>
                                    </div>
                                )}
                                <div>
                                    <button
                                        onClick={exportFunc}
                                        className="bg-gray-200  hover:opacity-90 text-black py-1 px-4 rounded-md shadow-sm text-xs flex items-center gap-1">
                                        <CiExport />
                                        <p>Export</p>
                                    </button>

                                </div>
                            </div>

                        </div>
                        {!loading && (<div className='w-full '>
                            {users.length > 0 ? (
                                <div>
                                    <div className='rounded-lg  shadow-md max-h-[60vh] overflow-auto'>

                                        <table className='w-full text-xs  px-2 table-auto  overflow-auto'>
                                            <thead className='bg-green-200 shadow-md sticky top-0'>
                                                <tr>
                                                    <th >No.</th>
                                                    <th >ID</th>
                                                    <th >Name</th>
                                                    <th >Position</th>
                                                    <th >Email</th>
                                                    <th >Username</th>
                                                    <th >Contact</th>
                                                    <th >
                                                        <div className='flex justify-center items-center'>
                                                            <p>Date created</p>
                                                            {showDateSort ? <IoIosArrowRoundUp onClick={handleSortDateAsc} className="text-sm cursor-pointer hover:bg-slate-50 rounded-md" /> :
                                                                <IoIosArrowRoundDown onClick={handleSortDateDsc} className="text-sm cursor-pointer hover:bg-slate-50 rounded-md" />}
                                                        </div>
                                                    </th>
                                                    <th >Created by</th>
                                                    <th className=''>
                                                        <div className="relative">
                                                            <span className=" flex cursor-pointer items-center justify-center gap-1 font-semibold ">
                                                                <p>Status</p>
                                                                <FaChevronDown size={10} />
                                                            </span>
                                                            <select

                                                                name="status"
                                                                value={selectedStatus}
                                                                onChange={(e) => { handleStatusChange(e.target.value); }}
                                                                className="p-2 w-full border rounded-md absolute inset-0 opacity-0"
                                                            >
                                                                {status.map((type) => (
                                                                    <option key={type} value={type}>
                                                                        {type}
                                                                    </option>
                                                                ))}
                                                            </select>
                                                        </div>
                                                    </th>
                                                    {/* <th >Registrar</th> */}
                                                    <th >Action</th>
                                                </tr>
                                            </thead>
                                            <tbody className='py-2'>
                                                {users.sort((a, b) => new Date(b.created_at) - new Date(a.created_at))?.map((data, index) => (
                                                    <tr onClick={() => handleUserView(data)} key={data.id} className={index % 2 === 0 ? 'bg-gray-100 hover:bg-slate-50' : 'bg-white hover:bg-slate-50'}>
                                                        <td className='border-r-2 text-center py-4 h-20 flex-wrap'>{index + 1}</td>
                                                        <td className='border-x-2 text-center py-4 h-20 flex-wrap'>{data?.id}</td>
                                                        <td className='border-x-2 text-center py-4 h-20 flex-wrap'>{`${data?.first_name} ${data?.last_name}`}</td>
                                                        <td className='border-x-2 text-center py-4 h-20 flex-wrap'>{data?.position}</td>
                                                        <td className='border-x-2 text-center py-4 h-20 flex-wrap'>{data?.email}</td>
                                                        <td className='border-x-2 text-center py-4 h-20 flex-wrap'>{data?.username}</td>
                                                        <td className='border-x-2 text-center py-4 h-20 flex-wrap'>{data?.phone}</td>
                                                        <td className='border-x-2 text-center py-4 h-20 flex-wrap'>{new Date(data?.created_at).toLocaleDateString()}</td>
                                                        <td className='border-x-2 text-center py-4 h-20 flex-wrap'>{data?.created_by}</td>
                                                        <td className={`${data?.status === 'active' ? 'text-green-600 ' : 'text-red-500'} border-x-2 text-center py-4 h-20 flex-wrap `}><p className={`${data?.status === 'active' ? 'bg-green-200 border border-green-400 ' : data?.status === 'inactive' ? 'bg-red-200 border px-[5px] border-red-400' : data?.status === 'archived' ? 'bg-gradient-to-r from-gray-400 via-gray-600 to-black border border-gray-900 text-white px-5' : ''} shadow-md mx-2 py-[1px] font-bold rounded-md text-xs`}>{data?.status}</p></td>


                                                        {/* <td className='border-x-2 text-center py-4 h-20 flex-wrap'>{data.regDate}</td> */}


                                                        <td className='flex items-center justify-end h-20 relative'>
                                                            <CiMenuKebab className='cursor-pointer ' size={18} onClick={(event) => togglePopUp(event, data.id, data)} />
                                                            {selectedDropdown === data.id && (
                                                                <div ref={popUpRef} className='absolute flex flex-col z-10 gap-2 top-6 right-0 bg-white border rounded shadow-md min-h-max p-2 min-w-max w-20'>
                                                                    <div className='cursor-pointer justify-center  hover:bg-slate-50 flex gap-2 items-center'>
                                                                        <FaRegEye />
                                                                        <p className=' text-xs' >
                                                                            View
                                                                        </p>
                                                                    </div>

                                                                    {(data.status === 'inactive' || data.status === "active") ? (<div onClick={(event) => { handleEditUser(event, data.id) }} className='cursor-pointer justify-center hover:bg-slate-50 flex gap-2 items-center'>
                                                                        <GrEdit />
                                                                        <p className=' text-xs ' >
                                                                            Edit
                                                                        </p>
                                                                    </div>) : null}

                                                                    {/* {data.status === 'archived' ? <div onClick={(event) => { handleArchiveUser(event, data.id) }} className='cursor-pointer justify-center hover:bg-slate-50 flex gap-1 items-center'>
                                                                        <VscLayersActive />
                                                                        <p className=' text-xs sm:text-sm' >
                                                                            Re-activate
                                                                        </p>
                                                                    </div> : <div onClick={(event) => { handleEditUser(event, data.id) }} className='cursor-pointer justify-center hover:bg-slate-50 flex gap-2 items-center'>
                                                                        <GrEdit />
                                                                        <p className=' text-xs sm:text-sm' >
                                                                            Edit
                                                                        </p>
                                                                    </div>} */}
                                                                    {/* {data.status === 'archived' && <div onClick={(event) => { handleDeleteUser(event, data.id) }} className='cursor-pointer justify-center hover:bg-slate-50 flex gap-2 items-center'>
                                                                        <MdOutlineAutoDelete />
                                                                        <p className=' text-xs sm:text-sm' >
                                                                            Delete
                                                                        </p>
                                                                    </div>} */}
                                                                    {/* {data.status === 'inactive' && <div onClick={(event) => { handleArchiveUser(event, data.id) }} className='cursor-pointer justify-center hover:bg-slate-50 flex gap-2 items-center'>
                                                                        <FaRegFileArchive />
                                                                        <p className=' text-xs sm:text-sm' >
                                                                            Archive
                                                                        </p>
                                                                    </div>} */}

                                                                    {/* Add other dropdown items as needed */}
                                                                </div>
                                                            )}
                                                        </td>
                                                    </tr>

                                                ))}
                                            </tbody>
                                        </table>

                                    </div>
                                    {/* <div className='flex items-center gap-4 mt-4'>
                                        <button onClick={handlePrevPage} disabled={currentPage === 1} className={currentPage === 1 ? 'hidden' : 'text-xs text-green-600'}>Previous</button>
                                        <div className='flex items-center gap-3'>
                                            {Array.from({ length: lastPage }, (_, i) => (
                                                <button key={i + 1} onClick={() => handlePageClick(i + 1)} className={`text-xs ${currentPage === i + 1 ? 'font-bold' : ''}`}>{i + 1}</button>
                                            ))}
                                        </div>
                                        <button onClick={handleNextPage} disabled={currentPage === lastPage} className={currentPage === lastPage ? 'hidden' : 'text-xs text-green-600'}>Next</button>
                                    </div> */}
                                    <div className='flex items-center gap-4 mt-4'>
                                        <button
                                            onClick={handlePrevPage}
                                            disabled={currentPage === 1}
                                            className={currentPage === 1 ? 'text-gray-500 text-xs' : 'text-xs text-green-600'}
                                        >
                                            Previous
                                        </button>
                                        <div className='flex items-center gap-3'>
                                            {getPageNumbers(currentPage, lastPage).map((pageNumber, index) => (
                                                <React.Fragment key={index}>
                                                    {pageNumber === '...' ? (
                                                        <span className="text-xs">...</span>
                                                    ) : (
                                                        <button
                                                            onClick={() => handlePageClick(pageNumber)}
                                                            className={`text-xs ${currentPage === pageNumber ? 'font-bold' : ''}`}
                                                        >
                                                            {pageNumber}
                                                        </button>
                                                    )}
                                                </React.Fragment>
                                            ))}
                                        </div>
                                        <button
                                            onClick={handleNextPage}
                                            disabled={currentPage === lastPage}
                                            className={currentPage === lastPage ? 'text-gray-500 text-xs' : 'text-xs text-green-600'}
                                        >
                                            Next
                                        </button>
                                    </div>
                                </div>
                            ) : (
                                <div>
                                    {showbtn ? (
                                        <div className="flex flex-col items-center justify-center font-lufga h-[80vh] sm:h-[65vh]">
                                            <p className="text-gray-500 text-center text-xs">{`No ${filter} users`}</p>
                                            <button
                                                onClick={() => {
                                                    handleStatusChange('All');
                                                    setHadUsers(false);

                                                }}
                                                className="bg-green-500 hover:opacity-90 text-white py-2 px-4 rounded-sm shadow-md mt-4">Go back</button>
                                        </div>
                                    ) : hasSearched ? (
                                        <div className="flex flex-col items-center justify-center font-lufga h-[80vh] sm:h-[65vh]">
                                            <p className="text-gray-500 text-center text-xs">No users found</p>
                                            <button onClick={() => { handleSearchClick() }} className="bg-green-500 hover:opacity-90 text-white py-2 px-4 rounded-sm shadow-md mt-4">{searchLoading ? <ColorRing
                                                height="20"
                                                width="20"
                                                ariaLabel="color-ring-loading"
                                                wrapperStyle={{}}
                                                wrapperClass="color-ring-wrapper"
                                                colors={['#e15b64', '#f47e60', '#f8b26a', '#abbd81', '#849b87']}
                                            /> : "Go back"}</button>
                                        </div>
                                    ) : hadUsers ? (
                                        <div className="flex flex-col items-center justify-center font-lufga h-[80vh] sm:h-[65vh]">
                                            <p className="text-gray-500 text-center text-xs">Empty List</p>
                                            {hasPermission(roles, ['userWriter', 'userAdmin']) && (
                                                <button onClick={toggleUserForm} className="bg-green-500 hover:opacity-90 text-white py-2 px-4 rounded-sm shadow-md mt-4">Add Users</button>

                                            )}
                                        </div>
                                    ) : (
                                        <div className="flex flex-col items-center justify-center font-lufga h-[80vh] sm:h-[65vh]">
                                            <p className="text-gray-500 text-center text-xs">Get Started</p>
                                            {hasPermission(roles, ['userWriter', 'userAdmin']) && (
                                                <button onClick={toggleUserForm} className="bg-green-500 hover:opacity-90 text-white py-2 px-4 rounded-sm shadow-md mt-4">Add Users</button>

                                            )}                                        
                                            </div>

                                    )}
                                </div>
                            )}
                            {showEditUserModal && (
                                <div className="fixed inset-0 flex items-center justify-center bg-gray-800 bg-opacity-50">
                                    <div ref={modalRef} className="bg-white rounded-md max-h-[90vh] overflow-auto shadow-lg p-6">
                                        <MdClose size={24} onClick={() => setShowEditUserModal(false)} className='hover:scale-105 hover:text-red-800 transition duration-150 ease-out cursor-pointer ' />
                                        <EditUser toggle={() => setShowEditUserModal(false)} user={userToEdit} fetchUsers={fetchUsers} users={users} />
                                    </div>
                                </div>
                            )}
                            {showDeleteUserModal && (
                                <div className="fixed inset-0 flex items-center justify-center bg-gray-800 bg-opacity-50">
                                    <div ref={modalRef} className="bg-white rounded-md min-h-max shadow-lg p-6">
                                        <MdClose size={24} onClick={() => setShowDeleteUserModal(false)} className='hover:scale-105 hover:text-red-800 transition duration-150 ease-out cursor-pointer ' />
                                        <DeleteUserModal toggle={() => setShowDeleteUserModal(false)} user={userToDelete} fetchUsers={fetchUsers} users={users} showEmptydivHelper={showEmptydivHelper} />
                                    </div>
                                </div>
                            )}
                            {showArchiveUserModal && (
                                <div className="fixed inset-0 flex items-center justify-center bg-gray-800 bg-opacity-50">
                                    <div ref={modalRef} className="bg-white rounded-md min-h-max shadow-lg p-6">
                                        <MdClose size={24} onClick={() => setShowArchiveUserModal(false)} className='hover:scale-105 hover:text-red-800 transition duration-150 ease-out cursor-pointer ' />
                                        <ArchiveUserModal toggle={() => setShowArchiveUserModal(false)} user={userToArchive} fetchUsers={fetchUsers} users={users} />
                                    </div>
                                </div>
                            )}


                        </div>)}

                        {showModal && (
                            <div className="fixed inset-0 flex items-center justify-center bg-gray-800 bg-opacity-50">
                                <div ref={modalRef} className="bg-white rounded-md max-h-[90vh] overflow-auto shadow-lg p-6">
                                    <MdClose size={24} onClick={toggleUserForm} className='hover:scale-105 hover:text-red-800 transition duration-150 ease-out cursor-pointer ' />
                                    <AddUserForm toggle={toggleUserForm} fetchUsers={fetchUsers} />
                                </div>
                            </div>
                        )}



                    </div>

                ) : userView && (<div className=''>
                    <div>
                        <p onClick={() => { setUserView(false) }} className='mb-4 flex items-center gap-1 cursor-pointer hover:text-neutral-400'><IoArrowBackCircleOutline size={28} />Go back</p>

                    </div>
                    <div className='grid lg:grid-cols-4 gap-8 lg:gap-0 sm:border shadow-md rounded-sm'>
                        <div className='col-span-4 lg:col-span-1 lg:border-r relative flex flex-col gap-2 max-h-[60vh] sm:max-h-[90vh] overflow-auto bg-neutral-100'>
                            <div className='sticky top-0 z-1 bg-neutral-100 p-2 w-100'>
                                <p className='text-sm font-lufga w-full border-b '>User List</p>

                            </div>
                            {users.map((data) => (
                                <div key={data?.id} className={`cursor-pointer px-2 py-1 hover:bg-slate-200 ${selectedUserDetails && selectedUserDetails.id === data.id ? 'bg-neutral-200' : ''}`} onClick={() => handleUserView(data)}>
                                    <p className='text-xs '>{`${data?.first_name} ${data?.last_name}`}</p>
                                    <p className='text-xs font-bold'>{data?.email} </p>


                                </div>
                            ))}
                            <div className="sticky bottom-0 left-0 right-0 bg-neutral-100  p-2">
                                <div className='flex items-center gap-1'>
                                    <button onClick={handlePrevPage} disabled={currentPage === 1} className={currentPage === 1 ? 'hidden' : 'text-xs text-green-600'}>Prev</button>
                                    <div className='flex items-center gap-1'>
                                        {Array.from({ length: lastPage }, (_, i) => (
                                            <button key={i + 1} onClick={() => handlePageClick(i + 1)} className={`text-xs ${currentPage === i + 1 ? 'font-bold' : ''}`}>{i + 1}</button>
                                        ))}
                                    </div>
                                    <button onClick={handleNextPage} disabled={currentPage === lastPage} className={currentPage === lastPage ? 'hidden' : 'text-xs text-green-600'}>Next</button>
                                </div>
                            </div>
                        </div>

                        <div className='col-span-4 lg:col-span-3 p-2 max-h-[60vh] lg:max-h-[80vh] overflow-auto'>
                            <p className='text-sm font-lufga w-full sm:border-b'>Details</p>

                            <UserDetails user={selectedUserDetails} roles={roles} users={users} />
                        </div>
                    </div>



                </div>)}

        </div>
    );
}

export default UserInfo;
