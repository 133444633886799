import React, { useState } from 'react';

const PendingComponent = () => {
    const [products, setProducts] = useState([
        {
            id: 1001,
            productName: "Ibuprofen",
            supplier: 'Unichem industries',
            expiryDate: "28/02/2030",
            quantity: 1
        },
        {
            id: 1002,
            productName: "Ibuprofen",
            supplier: 'Unichem industries',
            expiryDate: "28/02/2030",
            quantity: 1
        },
        {
            id: 1003,
            productName: "Ibuprofen",
            supplier: 'Unichem industries',
            expiryDate: "28/02/2030",
            quantity: 1
        },
        {
            id: 1004,
            productName: "Ibuprofen",
            supplier: 'Unichem industries',
            expiryDate: "28/02/2030",
            quantity: 1
        },
    ])
    return (
    <div>
            <div className="w-full mx-auto bg-white shadow-md my-4  rounded-lg overflow-auto">
            <table className="w-full ">
                <thead className="bg-gray-50">
                    <tr>
                        {/* <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">ID</th> */}
                        <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Product Name</th>
                        <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Supplier</th>
                        <th className="px-6 py-3 text-right text-xs font-medium text-gray-500 uppercase tracking-wider">Quantity</th>
                    </tr>
                </thead>
                <tbody className="bg-white divide-y divide-gray-200">
                    {products.map((product, index) => (
                        <tr key={product.id}>
                            {/* <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">{product.id}</td> */}
                            <td className="px-6 py-4 whitespace-nowrap text-xs font-medium text-gray-900">{product.productName}</td>
                            <td className="px-6 py-4 whitespace-nowrap text-xs text-gray-500">{product.supplier}</td>
                            <td className="px-6 py-4 whitespace-nowrap text-xs text-gray-500 text-right">{product.quantity}</td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
        <div className=' flex justify-end'>
            <div className='flex items-center gap-3'>
            <button className="bg-gray-200  hover:opacity-90 text-black py-1 px-4 rounded-md shadow-md text-xs">Cancel Order</button>
            <button className="bg-blue-500  hover:opacity-90 text-white py-1 px-4 rounded-md shadow-md text-xs">Accept Order</button>
            </div>
            </div>
    </div>
    );
}

export default PendingComponent;
