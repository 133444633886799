import React, { useEffect, useState } from 'react';
import { IoBagHandle, IoPieChart, IoPeople, IoCart, IoWarningOutline, IoSaveOutline, IoScanCircle, IoTimerOutline, } from 'react-icons/io5'
import { FcExpired } from 'react-icons/fc';
import { IoIosHelpCircleOutline } from 'react-icons/io'
import { api_url } from '../../config';
import { useSelector } from 'react-redux';
import axios from 'axios';
import { ColorRing } from 'react-loader-spinner';
import api from '../../utils/api';

const SummaryInfo = () => {
    const [summary, setSummary] = useState([]);
    const { id, branch_id, token } = useSelector((state) => state.auth);
    const [loader, setLoader] = useState(false)



    const fetchSummary = async () => {

        try {
            setLoader(true)
            const response = await api.get(api_url + `/api/v1/wholesaler/user/${id}/branch/${branch_id}/summary`, {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${token}`
                }
            })
            const data = response.data.data
            setSummary(data)
        } catch (error) {
            console.log(error);

        } finally {
            setLoader(false)
        }
    }
    useEffect(() => {
        fetchSummary()
    }, [id, token])
    // async function fetchSummaryData() {


    //     const output = await (await fetch(api_url+'/wholesale/dashboard/summary.php', {
    //         method: 'POST',
    //         headers: {
    //             "Authorization": getToken()
    //         }
    //     })).json()
    //     if (output.status == 200) {
    //        let newdata = output.data;

    //        SetData(newdata);
    //     } else {
    //         await toast.error(output.message);
    //     }
    // }

    // useState(()=>{fetchSummaryData()},[])

    return (
        <div>
            {/* <ManagementInfo /> */}
            <div className='p-4 font-lufga border-b border-slate-200 w-full grid grid-cols-2 md:grid-cols-3  lg:grid-cols-4 3xl:grid-cols-8 gap-4'>
            <div className="bg-gradient-to-br  from-sky-100 to-sky-300 rounded-md   shadow-md p-2 sm:p-4 flex items-center gap-2 hover:scale-105 duration-300 md:gap-6 justify-center">
                <div><IoBagHandle size={44} className='bg-sky-500 p-2 text-white rounded-full  ' /></div>
                <div className='text-xs sm:text-sm flex flex-col '>
                    <p className='text-gray-500 font-light 2xl:px-2'>Suppliers</p>
                    <div className='flex items-center space-x-1 sm:space-x-2 mx-auto'>
                        {loader ? <ColorRing
                            height="40"
                            width="40"
                            ariaLabel="color-ring-loading"
                            wrapperStyle={{}}
                            wrapperClass="color-ring-wrapper"
                            colors={['#e15b64', '#f47e60', '#f8b26a', '#abbd81', '#849b87']}
                        /> : <p className='text-lg text-gray-700 font-semibold'>{summary?.supplier_count || '-'}</p>
                        }
                        {/* <p className='text-sm text-green-500 font-semibold'>+2</p> */}
                    </div>
                </div>
            </div>
            <div className="bg-gradient-to-br from-orange-100 to-orange-300 rounded-md   shadow-md p-2 sm:p-4 flex items-center gap-2 hover:scale-105 duration-300 md:gap-6 justify-center">
                <div><IoPieChart size={44} className='bg-orange-500 p-2 text-white rounded-full ' /></div>
                <div className='text-xs sm:text-sm flex flex-col '>
                    <p className='text-gray-500 font-light 2xl:px-2'>Retailers</p>
                    <div className='flex items-center space-x-1 sm:space-x-2 mx-auto'>
                        {loader ? <ColorRing
                            height="40"
                            width="40"
                            ariaLabel="color-ring-loading"
                            wrapperStyle={{}}
                            wrapperClass="color-ring-wrapper"
                            colors={['#e15b64', '#f47e60', '#f8b26a', '#abbd81', '#849b87']}
                        /> : <p className='text-lg text-gray-700 font-semibold'>{summary?.retailer_count || '-'}</p>
                        }
                        {/* <p className='text-sm text-green-500 font-semibold'>+2</p> */}
                    </div>
                </div>
            </div>
            {/* <div className="bg-white  shadow-md p-2 sm:p-4 flex items-center gap-2 hover:scale-105 duration-300 md:gap-6 justify-center">
            <div><IoPeople size={44} className='bg-yellow-400 p-2 text-white rounded-full ' /></div>
            <div className='text-xs sm:text-sm flex flex-col '>
                <p className='text-gray-500 font-light 2xl:px-2'>Users</p>
                <div className='flex items-center space-x-1 sm:space-x-2 mx-auto'>
                    <p className='text-lg text-gray-700 font-semibold'>0</p>
                </div>
            </div>
        </div> */}
            <div className="bg-gradient-to-br from-green-100 to-green-300 rounded-md   shadow-md p-2 sm:p-4 flex items-center gap-2 hover:scale-105 duration-300 md:gap-6 justify-center">
                <div><IoCart size={44} className='bg-green-600 p-2 text-white rounded-full ' /></div>
                <div className='text-xs sm:text-sm flex flex-col '>
                    <p className='text-gray-500 font-light 2xl:px-2'>Pending orders</p>
                    <div className='flex items-center space-x-1 sm:space-x-2 mx-auto'>
                        <p className='text-lg text-gray-700 font-semibold'>{summary?.pending_orders || '-'}</p>
                        {/* <p className='text-sm text-green-500 font-semibold'>+2</p> */}
                    </div>
                </div>
            </div>
            <div className="bg-gradient-to-br from-blue-100 to-blue-300 rounded-md  shadow-md p-2 sm:p-4 flex items-center gap-2 hover:scale-105 duration-300 md:gap-6 justify-center">
                <div><IoTimerOutline size={44} className='bg-sky-500 p-2 text-white rounded-full ' /></div>
                <div className='text-xs sm:text-sm  flex flex-col '>
                    <p className='text-gray-500 font-light 2xl:px-2'>Expiring soon</p>
                    <div className='flex items-center space-x-1 sm:space-x-2 mx-auto'>
                        {loader ?  <ColorRing
                            height="40"
                            width="40"
                            ariaLabel="color-ring-loading"
                            wrapperStyle={{}}
                            wrapperClass="color-ring-wrapper"
                            colors={['#e15b64', '#f47e60', '#f8b26a', '#abbd81', '#849b87']}
                        /> : <p className='text-lg text-gray-700 font-semibold'>{summary?.expiring_soon || '-'}</p>
}
                        {/* <p className='text-sm text-green-500 font-semibold'>+2</p> */}
                    </div>
                </div>
            </div>
            <div className="bg-gradient-to-br from-yellow-50 to-yellow-200 rounded-md   shadow-md p-2 sm:p-4 flex items-center gap-2 hover:scale-105 duration-300 md:gap-6 justify-center">
                <div><FcExpired size={44} className='bg-slate-300 p-2 text-white rounded-full ' /></div>
                <div className='text-xs sm:text-sm flex flex-col '>
                    <p className='text-gray-500 font-light 2xl:px-2'>Expired</p>
                    <div className='flex items-center space-x-1 sm:space-x-2 mx-auto'>
                        {loader ?  <ColorRing
                            height="40"
                            width="40"
                            ariaLabel="color-ring-loading"
                            wrapperStyle={{}}
                            wrapperClass="color-ring-wrapper"
                            colors={['#e15b64', '#f47e60', '#f8b26a', '#abbd81', '#849b87']}
                        />:<p className='text-lg text-gray-700 font-semibold'>{summary?.expired || '-'}</p>
}
                        {/* <p className='text-sm text-green-500 font-semibold'>+2</p> */}
                    </div>
                </div>
            </div>
            <div className="bg-gradient-to-br from-red-100 to-red-300 rounded-md   shadow-md p-2 sm:p-4 flex items-center gap-2 hover:scale-105 duration-300 md:gap-6 justify-center">
                <div><IoIosHelpCircleOutline size={44} className='bg-red-500 p-2 text-white rounded-full ' /></div>
                <div className='text-xs sm:text-sm flex flex-col '>
                    <p className='text-gray-500 font-light 2xl:px-2'>Out of stock</p>
                    <div className='flex items-center space-x-1 sm:space-x-2 mx-auto'>
                        {loader ?  <ColorRing
                            height="40"
                            width="40"
                            ariaLabel="color-ring-loading"
                            wrapperStyle={{}}
                            wrapperClass="color-ring-wrapper"
                            colors={['#e15b64', '#f47e60', '#f8b26a', '#abbd81', '#849b87']}
                        /> : <p className='text-lg text-gray-700 font-semibold'>{summary?.out_of_stock || '-'}</p>}
                        {/* <p className='text-sm text-green-500 font-semibold'>+2</p> */}
                    </div>
                </div>
            </div>
            <div className="bg-gradient-to-br from-pink-100 to-pink-300 rounded-md  shadow-md p-2 sm:p-4 flex items-center gap-2 hover:scale-105 duration-300 md:gap-6 justify-center">
                <div><IoWarningOutline size={44} className='bg-pink-500 p-2 text-white rounded-full ' /></div>
                <div className='text-xs sm:text-sm flex flex-col '>
                    <p className='text-gray-500 font-light 2xl:px-2'>Low on stock</p>
                    <div className='flex items-center space-x-1 sm:space-x-2 mx-auto'>
                        {loader ?  <ColorRing
                            height="40"
                            width="40"
                            ariaLabel="color-ring-loading"
                            wrapperStyle={{}}
                            wrapperClass="color-ring-wrapper"
                            colors={['#e15b64', '#f47e60', '#f8b26a', '#abbd81', '#849b87']}
                        /> :  <p className='text-lg text-gray-700 font-semibold'>{summary?.low_on_stock || '-'}</p>
}
                        {/* <p className='text-sm text-green-500 font-semibold'>+2</p> */}
                    </div>
                </div>
            </div>

        </div>
            <div>

                <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-4 p-4 font-lufga">
                    <div className="bg-blue-50 p-4 hover:bg-blue-100 rounded-md shadow-md">
                        <h2 className="text-slate-600 text-xs sm:text-sm font-bold mb-2">Quantity Received</h2>
                        <p className="text-slate-500">Number: <span>{summary?.quantity_received?.number}</span></p>
                        <p className="text-slate-500">Cost: <span>{summary?.quantity_received?.cost}</span></p>
                        <p className="text-slate-500">Sale Target: <span>{summary?.quantity_received?.saleTarget}</span></p>
                    </div>

                    <div className="bg-green-50 p-4  hover:bg-green-100 rounded-md shadow-md">
                        <h2 className="text-slate-600 text-xs sm:text-sm  font-bold mb-2">Quantity Sold</h2>
                        <p className="text-slate-500">Number: <span>{summary?.quantity_sold?.number}</span></p>
                        <p className="text-slate-500">Value: <span>{summary?.quantity_sold?.value}</span></p>
                    </div>

                    <div className="bg-orange-50 hover:bg-orange-100 p-4 rounded-md shadow-md">
                        <h2 className="text-slate-600 text-xs sm:text-sm font-bold mb-2">Quantity Available</h2>
                        <p className="text-slate-500">Number: <span>{summary?.quantity_available?.number}</span></p>
                        <p className="font-bold">Target:  <span className={`${summary?.quantity_available?.value < 0 ? 'text-green-600 ' : 'text-red-600'}`}>
                            {typeof summary?.quantity_available?.value === 'number' ? Math.abs(summary.quantity_available.value).toLocaleString() : 'N/A'}
                        </span>
                        </p>
                    </div>

                </div>
            </div>

        </div>
    );
}

export default SummaryInfo;
