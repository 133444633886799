import React from 'react';
import RetailSummaryInfo from '../../components/Retaillib/RetailSummaryInfo';

const RetailSummary = () => {
    return (
        <div className='max-h-screen overflow-auto'>
            <p className='text-2xl font-lufga font-bold text-center border-b text-gray-600'>Summary</p>

            <RetailSummaryInfo/>
        </div>
    );
}

export default RetailSummary;
