import * as yup from 'yup'

export function getPrams() {
    let all_searched = {};
    let usp = new URLSearchParams(window.location.search)
    let allentriesIT = usp.entries()
    let output = allentriesIT.next()
    while (output.done == false) {
        all_searched[output["value"][0]] = output["value"][1]
        output = allentriesIT.next()
    }
    return all_searched;
}

export function getToday(type = 'mm/dd/yyyy') {
    var today = new Date();
    var dd = String(today.getDate()).padStart(2, '0');
    var mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
    var yyyy = today.getFullYear();
    if (type == 'mm/dd/yyyy'){
        today = mm + '/' + dd + '/' + yyyy;

    }else if (type == 'yyyy-mm-dd'){
        today =yyyy + "-"+ mm + '-' + dd ;

    }
    return today;
}


export function formatISODate(olddate){
    var date = new Date(olddate);
    var dd = String(date.getDate()).padStart(2, '0');
    var mm = String(date.getMonth() + 1).padStart(2, '0'); //January is 0!
    var yyyy = date.getFullYear();
   date =yyyy + "-"+ mm + '-' + dd ;

    return date
}

export const loginShema = yup.object().shape({
    email:yup.string().email("Please enter a vaild email").required("Please enter email address"),
    password: yup.string().min(5).required("Please enter password")
})
export const signupShema = yup.object().shape({
    code:yup.number("Please enter a vaild code").required("Please enter code"),
    company: yup.string(),
    product: yup.string().required("Please enter product"),
    fname: yup.string().required("Please enter first name"),
    lname: yup.string().required("Please enter last name"),
    lname: yup.string().required("Please enter last name"),
    email:yup.string().email("Please enter a vaild email").required("Please enter email address"),
    password: yup.string().min(5).required("Please enter password"),
    confirm_password: yup.string().min(5).required("Please enter confirm password")
    .oneOf([yup.ref('password'), null], 'Password must match')
})