import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { HiOutlinePhone, HiOutlineEnvelope } from 'react-icons/hi2';
import { FaFacebookF } from 'react-icons/fa'
import { BiLogoInstagram, BiLogoTwitter } from 'react-icons/bi';
import { IoMdMenu } from "react-icons/io";
import { IoClose, IoMenu } from 'react-icons/io5';
import { Link } from 'react-router-dom';

const HomeNav = () => {
    const [userCountry, setUserCountry] = useState('');
    const [countryFlag, setCountryFlag] = useState('');
    const [location, setLocation] = useState({})
    const [data, setData] = useState([])
    const [menu, setMenu] = useState(false)
    const toggleMenu = () => {
        setMenu(!menu)
    }

    //   this gets me the user location cos I need the city

    useEffect(() => {
        const fetchData = async () => {
            try {
                const locationResponse = await axios.get('https://ipapi.co/json');
                setLocation(locationResponse.data);
                //insert the city here cos i need the flag
                if (locationResponse.data.city) {
                    const url = `https://restcountries.com/v3.1/capital/${locationResponse.data.city}`;
                    const dataResponse = await axios.get(url);
                    setData(dataResponse.data);
                    console.log(dataResponse.data);
                }
            } catch (error) {
                // Handle errors
                console.error('Error fetching data:', error);
            }
        };

        fetchData();
    }, []);

    return (
        <div>
            <div className=' shadow-md '>
                <div className='w-full flex items-center justify-around bg-[#00A9FF] p-1 font-medium'>
                    <p className=' text-center text-white font-lufga'>NTUBED GLOBAL GROUP</p>
                    <div className='md:flex items-center hidden gap-4'>
                        <p className=' text-center text-white font-lufga flex items-center gap-2'><HiOutlinePhone size={28} className=' mx-auto border-2 border-dotted rounded-full border-[#fff] text-white p-[2px] ' />+233 (0)30 396 4289</p>
                        <p className=' text-center text-white font-lufga flex items-center gap-2'><HiOutlineEnvelope size={28} className=' mx-auto border-2 border-dotted rounded-full border-[#fff] text-white p-[2px] ' />info@ntubedglobal.com</p>
                    </div>
                    <div className=' md:flex hidden items-center gap-4'>
                        <BiLogoInstagram size={42} className='border-b-[1px] border-black rounded-full cursor-pointer p-2' />
                        <FaFacebookF size={38} className='border-b-[1px] border-black rounded-full p-2 cursor-pointer' />
                        <BiLogoTwitter size={42} className='border-b-[1px] border-black rounded-full p-2 cursor-pointer' />
                    </div>

                </div>

                <div className='md:flex justify-between xl:p-6 xl:mx-16 md:py-6 md:mx-10 items-center hidden'>
                    <div>
                        <Link to='/'>
                            <p className='xl:text-5xl md:text-3xl text-[#00A9FF] cursor-pointer font-lufga'>Pharmtrix</p>
                        </Link>

                    </div>
                    <div>
                        <ul className=' flex items-center font-lufga'>
                            <Link to='/'>
                                <li className='xl:mx-6 md:mx-3 text-sm text-gray-500 hover:text-[#00A9FF] cursor-pointer font-medium'>HOME</li>
                            </Link>
                            <Link to='/login' target='_blank'>
                                <li className='xl:mx-6 md:mx-3 text-sm text-gray-500 hover:text-[#00A9FF] cursor-pointer font-medium'>LOG IN</li>
                            </Link>
                            <a href='https://test2.ntubedglobal.com/signup' target='_blank' rel='noopener noreferrer'>
                                <li className='xl:mx-6 md:mx-3 text-sm text-gray-500 hover:text-[#00A9FF] cursor-pointer font-medium'>CREATE ACCOUNT</li>
                            </a>
                            <a href='https://test3.ntubedglobal.com' target='_blank' rel='noopener noreferrer'>
                                <li className='xl:mx-6 md:mx-3 text-sm text-gray-500 hover:text-[#00A9FF] cursor-pointer font-medium'>SUSCRIBE</li>
                            </a>

                            {/* <li className='xl:mx-6 md:mx-3 text-sm text-gray-500 hover:text-[#00A9FF] cursor-pointer font-medium'>SUBSCRIBE</li> */}
                            <li className='xl:mx-6 md:mx-3 text-sm text-gray-500 hover:text-[#00A9FF] cursor-pointer font-medium'>CONTACT US</li>
                        </ul>
                    </div>
                    <div className='flex gap-2 items-center'>
                        <img src={data[0]?.flags.png} alt='/' className='h-4 w-6' />
                        <p>{location.country_name}</p>
                    </div>
                </div>

                {/* mobile nav */}

                <div className='md:hidden'>
                    <div className='flex items-center py-2 relative'>
                        <div className='px-[2px]'><IoMenu size={28} onClick={toggleMenu} /></div>
                        <div className='flex-1'><p className='text-center text-2xl text-[#00A9FF] cursor-pointer font-lufga'>Pharmtrix</p></div>
                    </div>

                </div>
            </div>
            <div className={!menu ? ' h-full w-full bg-[#00A9FF]/90 p-4 absolute top-8 left-[-100%] ease-in duration-300 z-10' : ' md:hidden h-full z-10 w-full bg-[#e1f5ff]  flex p-4 absolute top-8 left-0 ease-in duration-300'}>

                <div className='flex flex-col flex-1 items-center my-8'>
                    <ul className='flex flex-col gap-8 flex-1'>
                        <Link to='/'>
                            <li className='border-b-[1px] border-[#00A9FF] py-[2px] text-center'>HOME</li>
                        </Link>
                        <Link to='/login' target='_blank'>
                            <li className='border-b-[1px] border-[#00A9FF] py-[2px] text-center'>LOG IN</li>
                        </Link>
                        <a href='https://test2.ntubedglobal.com/signup' target='_blank' rel='noopener noreferrer'>
                            <li className='xl:mx-6 md:mx-3 text-sm text-gray-500 hover:text-[#00A9FF] cursor-pointer font-medium'>CREATE ACCOUNT</li>
                        </a>
                        <a href='https://test3.ntubedglobal.com' target='_blank' rel='noopener noreferrer'>
                            <li className='xl:mx-6 md:mx-3 text-sm text-gray-500 hover:text-[#00A9FF] cursor-pointer font-medium'>SUSCRIBE</li>
                        </a>
                        <li className='border-b-[1px] border-[#00A9FF] py-[2px] text-center'>CONTACT US</li>

                    </ul>
                    <ul className='flex flex-col gap-4 justify-center items-center'>
                        <li><p className=' text-center text-black font-lufga flex items-center gap-2'><HiOutlinePhone size={28} className=' mx-auto border-2 border-dotted rounded-full border-[#00A9FF]  p-[2px] ' />+233 (0)30 396 4289</p>
                        </li>
                        <li><p className=' text-center text-black font-lufga flex items-center gap-2'><HiOutlineEnvelope size={28} className=' mx-auto border-2 border-dotted rounded-full border-[#00A9FF]  p-[2px] ' />info@ntubedglobal.com</p>
                        </li>
                        <li className=' flex items-center gap-4'>
                            <BiLogoInstagram size={42} className='border-b-[1px] border-black rounded-full cursor-pointer p-2' />
                            <FaFacebookF size={38} className='border-b-[1px] border-black rounded-full p-2 cursor-pointer' />
                            <BiLogoTwitter size={42} className='border-b-[1px] border-black rounded-full p-2 cursor-pointer' />
                        </li>
                        <li className='flex gap-2 items-center'>
                            <img src={data[0]?.flags.png} alt='/' className='h-4 w-6' />
                            <p>{location.country_name}</p>
                        </li>
                    </ul>
                </div>
                <div className=''>
                    <IoClose size={24} onClick={toggleMenu} />
                </div>

            </div>
        </div>

    );
}

export default HomeNav;
