import React, { useEffect, useRef } from 'react';
import useState from 'react-usestateref'
import { FaChevronDown, FaUserClock, FaUserLock } from "react-icons/fa6";
import { toast } from 'react-toastify';
import { useDBContext } from '../../../context/DBContext';
import EditSupplier from './EditSupplier';
import { ColorRing, InfinitySpin } from 'react-loader-spinner';
import AddSupplier from './AddSupplier';
import { IoIosArrowDown, IoIosArrowRoundDown, IoIosArrowRoundUp, IoIosArrowUp, IoMdClose, IoMdCloseCircle } from 'react-icons/io';
import axios from 'axios';
import { api_url } from '../../../config';
import { useSelector } from 'react-redux';
import { CiExport, CiMenuKebab } from 'react-icons/ci';
import { FaRegEye, FaRegFileArchive } from 'react-icons/fa';
import { GrEdit } from 'react-icons/gr';
import SupplierDetail from './SupplierDetail';
import { IoArrowBackCircleOutline } from 'react-icons/io5';
import { MdOutlineAutoDelete } from 'react-icons/md';
import DeleteSupplier from './DeleteSupplier';
import { FcSearch } from 'react-icons/fc';
import ArchiveSupplier from './ArchiveSupplier';
import { VscLayersActive } from 'react-icons/vsc';
import { hasPermission } from '../../../Redux/permissionSlice';
import { selectRoles } from '../../../Redux/AuthSlice';
import api from '../../../utils/api';
import { getPageNumbers } from '../../../utils/getPageNumbers';
import exportFromJSON from 'export-from-json';


const SupplierInfo = () => {
    const [showModal, setShowModal] = useState(false)
    const [supplier_view, setSupplier_view] = useState(false)
    const [supplier, setSupplier] = useState('')
    const [supplier_amount, setSupplier_amount] = useState('')
    const [editModal, setEditModal] = useState(false)
    const [showbtn, setShowbtn] = useState(false)
    const [deleteModal, setDeleteModal] = useState(false)
    const [archiveModal, setArchiveModal] = useState(false)
    const [selectedSupplier, setSelectedSupplier] = useState()
    const [selectedStatus, setSelectedStatus] = useState('');
    const [selectedDropdown, setSelectedDropdown] = useState(null);
    const status = ['*', 'All', 'Active', 'Inactive', 'Archived']
    const [loading, setLoading] = useState(true)
    const [currentPage, setCurrentPage] = useState(1);
    const [lastPage, setLastPage] = useState();
    const [limit, setLimit] = useState(50)
    const [newLimit, setNewLimit] = useState(50);
    const [filter, setFilter] = useState('')
    const [filter_by, setFilter_by] = useState('')
    const [sortby, setSortby] = useState('-created_at')
    const [showNameSort, setShowNameSort] = useState(false)
    const [showDateSort, setShowDateSort] = useState(false)
    const [metadata, setMetadata] = useState('')
    const [suppliers, setSuppliers] = useState([])
    const [search, setSearch] = useState('')
    const [hasSearched, setHasSearched] = useState(false);
    const [searchLoading, setSearchLoading] = useState(false);
    const [hadSuppliers, setHadSuppliers] = useState(false);
    const [activeTab, setActiveTab] = useState('All');


    






    const modalRef = useRef()

    const handleLimitChange = (e) => {
        setNewLimit(parseInt(e.target.value));
    };

    const applyNewLimit = () => {
        setLimit(newLimit);
    };

    const handlePageClick = (pageNumber) => {
        setCurrentPage(pageNumber);
    };


    const handleNextPage = () => {
        if (currentPage < lastPage) {
            setCurrentPage(currentPage + 1);

        }
    };

    const handlePrevPage = () => {
        if (currentPage > 1) {
            setCurrentPage(currentPage - 1);
        }
    };

    const showEditModal = (event, data) => {
        if (event) {
            event.preventDefault(); // Prevent default behavior
            event.stopPropagation();
        }
        setEditModal(!editModal)
        setSelectedSupplier(data)

    }
    const showDeleteModal = (event, data) => {
        if (event) {
            event.preventDefault(); // Prevent default behavior
            event.stopPropagation();
        }
        setDeleteModal(!deleteModal)
        setSelectedSupplier(data)


    }
    const showArchiveModal = (event, data) => {
        if (event) {
            event.preventDefault(); // Prevent default behavior
            event.stopPropagation();
        }
        setArchiveModal(!archiveModal)
        setSelectedSupplier(data)

    }

    const popUpRef = useRef()

    //to show a particular supplier view
    const showSupplierDetail = (data) => {
        setSupplier_view(!supplier_view)
        setSupplier(data)
    }

    const togglePopUp = (event, index, user) => {
        event.stopPropagation();
        event.preventDefault();
        if (index === selectedDropdown) {
            setSelectedDropdown(null);
            // Deselect branch if clicking on the same dropdown
        } else {
            setSelectedDropdown(index);

        }
    };
    useEffect(() => {
        // Function to handle click outside of modal
        const handleClickOutside = (event) => {
            if (popUpRef.current && !popUpRef.current.contains(event.target)) {
                setSelectedDropdown(null);
            }
        };

        // Attach the event listener
        document.addEventListener('mousedown', handleClickOutside);

        // Cleanup the event listener
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);


    const toggleSupplierForm = (id) => {
        setShowModal(!showModal)
    };
    const { id, branch_id, token } = useSelector((state) => state.auth)



    const fetchSuppliers = async (page, limit, searchQuery) => {
        setLoading(loading);
        if (!navigator.onLine) {
            toast("You're offline");
            setLoading(false);
            return;
        }
        const params = {
            page: page || 1,
            limit: limit || 50,
            sort: sortby,
            search: `${searchQuery || ''}`

        };
        if (Array.isArray(filter_by)) {
            filter_by.forEach((filterKey, index) => {
                params[`filter[${filterKey}]`] = filter[index];
            });
        } else {
            params[`filter[${filter_by}]`] = `${filter}`;
        }

        try {
            const response = await api.get(api_url + `/api/v1/retailer/user/${id}/branch/${branch_id}/contact/inbranch`, {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${token}`,
                },
                params,
            });
            const data = response.data.data
            const meta = response.data.meta
            setSuppliers(data)
            // setHadSuppliers(data.length > 0 || hadSuppliers);
            setMetadata(meta)
            setSupplier_amount(response.data.meta.total);
            setLastPage(response.data.meta.last_page)

        } catch (error) {
            console.log(error);

        } finally {
            setLoading(false)
        }
    }


    useEffect(() => {
        fetchSuppliers(currentPage, limit)
    }, [id, token, currentPage, limit, filter, sortby, filter_by])




    const handleSortNameAsc = () => {
        setSortby('name')
        setShowNameSort(!showNameSort)
    }

    const handleSortNameDsc = () => {
        setSortby('-name')
        setShowNameSort(!showNameSort)

    }

    const handleSortDateAsc = () => {
        setSortby('created_at')
        setShowDateSort(!showDateSort)
    }

    const handleSortDateDsc = () => {
        setSortby('-created_at')
        setShowDateSort(!showDateSort)

    }


    const handleClickOutside = (event) => {
        if (modalRef.current && !modalRef.current.contains(event.target)) {
            setShowModal(false);
            setEditModal(false)
            setDeleteModal(false)
            setArchiveModal(false)
        }
    };

    useEffect(() => {
        // Attach the event listener when the modal is open
        if (showModal || editModal || deleteModal || archiveModal) {
            document.addEventListener('mousedown', handleClickOutside);
        } else {
            // Remove the event listener when the modal is closed
            document.removeEventListener('mousedown', handleClickOutside);
        }
        // Clean up function to remove event listener on unmount
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [showModal, editModal, deleteModal, archiveModal]);

    const handleStatusChange = (status) => {
        if (status === selectedStatus) {
            setLoading(false);
            return;
        }
        
        setHasSearched(false)
        setLoading(true);

        let newFilter;
        let newFilter_by;
        if (status === 'Active') {
            newFilter = 'active';
            newFilter_by = 'status';
        } else if (status === 'Inactive') {
            newFilter = 'inactive';
            newFilter_by = 'status';

        } else if (status === 'Archived') {
            newFilter = 'archived';
            newFilter_by = 'status';

        } else if (status === 'All') {
            newFilter = '';
            newFilter_by = '';

        } else {
            newFilter = 'active,inactive,archived';
            newFilter_by = 'status';

        }
        setFilter_by(newFilter_by)
        setFilter(newFilter);
        setShowbtn(true);

        setTimeout(() => {
            setLoading(false);
        }, 1000);
    };


    const showEmptydivHelper = () =>{
        setHasSearched(false)
        setShowbtn(false)
        setHadSuppliers(true)
    }

    const handleSearchClick = async (searchQuery) => {
        
        setShowbtn(false);
        setHasSearched(true);
        setSearchLoading(!searchLoading)
        try {
            await fetchSuppliers(1, 50, searchQuery);;
            setHasSearched(true);
        } catch (error) {
            console.log(error);
        } finally {
            setSearchLoading(false)
        }


    };

    const selectedRoles = useSelector(selectRoles);
    const roles = Array.isArray(selectedRoles) ? selectedRoles : selectedRoles.split(',').map(role => role.trim());

    const switchTab = (value) => {
        setActiveTab(value);
        setLoading(true);
        setHasSearched(false);
        switch (value) {
            case 'All':
                setFilter_by('status'); // Set filter_by to 'status' for the 'All' tab
                setFilter('active,inactive,archived'); // Set filter to display all statuses
                break;
            case 'external':
                setFilter_by(['on_pharmtrix', 'status']); // Set filter_by to an array with 'on_pharmtrix' and 'status'
                setFilter(['0', 'active,inactive,archived']); // Set filter to display external suppliers with all statuses
                break;
            case 'internal':
                setFilter_by(['on_pharmtrix', 'status']); // Set filter_by to an array with 'on_pharmtrix' and 'status'
                setFilter(['1', 'active,inactive,archived']); // Set filter to display internal suppliers with all statuses
                break;
            default:
                setFilter_by('');
                setFilter('');
        }
    };

    const exportFunc = () => {
        const data = suppliers?.map(s=>({
            id: s.id,
            Name:s.name,
            City:s.city,
            Town:s.town,
            Address:s.address,
            Email:s.email,
            Contact:s.phone,
            Status:s.status,
            On_pharmtrix:s.on_pharmtrix,
            Date_created:new Date(s?.created_at).toLocaleDateString()


        }))
        const fileName = 'Suppliers'
        const exportType = exportFromJSON.types.csv

        exportFromJSON({ data, fileName,fields:["id","Name","City","Town","Address","Email","Contact","Date_created","Status","On_pharmtrix"], exportType })

    }

    return (

        <div className=''>
            {!supplier_view ? <div className='font-lufga '>
                {loading && ( // Display loader while loading
                    <div className="flex justify-center items-center w-full h-full bg-gradient-to-br from-opacity-40 to-opacity-60 backdrop-blur-sm absolute top-0 left-0">
                        <InfinitySpin color="#059212" loading={loading} size={40} />
                    </div>
                )}
                <div className='mb-2 '>
                    <p className='lg:text-3xl text-xl font-semibold text-slate-600'>Suppliers ({metadata?.total || '-'})</p>
                </div>
                <div className='flex overflow-auto xl:gap-10 font-lufga mb-3 '>
                    <button
                        className={`text-xs sm:text-sm cursor-pointer py-2 px-4 ${activeTab === 'All'
                            ? 'border-b-2 border-green-500 text-green-500'
                            : 'text-gray-500'
                            }`}
                        onClick={() => switchTab('All')}
                    >
                        All
                    </button>
                    <button
                        className={`text-xs sm:text-sm cursor-pointer py-2 px-4 ${activeTab === 'external'
                            ? 'border-b-2 border-green-500 text-green-500'
                            : 'text-gray-500'
                            }`}
                        onClick={() => switchTab('external')}
                    >
                        Non-Pharmtrix Suppliers
                    </button>
                    <button
                        className={`text-xs sm:text-sm cursor-pointer py-2 px-4 ${activeTab === 'internal'
                            ? 'border-b-2 border-green-500 text-green-500'
                            : 'text-gray-500'
                            }`}
                        onClick={() => switchTab('internal')}
                    >
                        Pharmtrix Suppliers
                    </button>

                </div>
                <div className={suppliers.length === 0 ? 'hidden' : 'flex flex-col gap-4 mb-4 sm:flex-row justify-between items-center font-lufga mx-6'}>
                    {currentPage === 1 ? <div className='flex items-center gap-2'>
                        <p className='text-sm'  >List per page</p>
                        <input className='border-[1px] w-16 px-2 text-xs' type="number" id="limit" value={newLimit} onChange={handleLimitChange} />
                        <button className="bg-green-500  hover:opacity-90 text-white py-1 px-4 rounded-md shadow-sm text-xs" onClick={applyNewLimit}>
                        {loading ? <ColorRing
                                height="20"
                                width="20"
                                ariaLabel="color-ring-loading"
                                wrapperStyle={{}}
                                wrapperClass="color-ring-wrapper"
                                colors={['#e15b64', '#f47e60', '#f8b26a', '#abbd81', '#849b87']}
                            /> : 'Apply'}
                        </button>
                       
                    </div> :
                        <div>
                            <p className='text-sm'  >List</p>
                        </div>}

                    <div className='flex flex-col sm:flex-row items-center gap-4'>
                        <div className='flex items-center gap-2'>
                            <input type='text' value={search} onChange={(e) => { setSearch(e.target.value) }} placeholder='Search suppliers' className='w-38 sm:w-[200px] focus:outline-none rounded-md shadow-sm border px-2 py-3 h-3 font-lufga text-xs' />
                            {searchLoading ? <ColorRing
                                height="20"
                                width="20"
                                ariaLabel="color-ring-loading"
                                wrapperStyle={{}}
                                wrapperClass="color-ring-wrapper"
                                colors={['#e15b64', '#f47e60', '#f8b26a', '#abbd81', '#849b87']}
                            /> : <FcSearch onClick={() => { handleSearchClick(search) }} className='hover:scale-110 cursor-pointer' />}

                        </div>
                        {hasPermission(roles, ['supplierWriter', 'supplierAdmin']) &&
                            <div>
                                <button onClick={toggleSupplierForm} className="bg-green-500  hover:opacity-90 text-white py-1 px-4 rounded-md shadow-sm text-xs">Add supplier</button>
                            </div>}
                            <div>
                            <button
                                onClick={exportFunc}
                                className="bg-gray-200  hover:opacity-90 text-black py-1 px-4 rounded-md shadow-sm text-xs flex items-center gap-1">
                                    <CiExport/>
                                    <p>Export</p>
                                    </button>

                        </div>
                    </div>
                </div>
                {!loading && (<div className='w-full '>
                    {suppliers.length > 0 ? (
                        <div>
                            <div className=' rounded-lg  shadow-md max-h-[60vh] overflow-auto'>

                                <table className='w-full text-xs  px-2 table-auto  overflow-auto'>
                                    <thead className="bg-green-200 shadow-lg sticky top-0">
                                        <tr>
                                            <th className="">No.</th>
                                            <th className="">ID</th>

                                            <th className=" ">
                                                <div className='flex justify-center items-center gap-1'>
                                                    <p>Name</p>
                                                    {showNameSort ? <IoIosArrowRoundUp onClick={handleSortNameAsc} className="text-sm cursor-pointer hover:bg-slate-50 rounded-md" /> :
                                                        <IoIosArrowRoundDown onClick={handleSortNameDsc} className="text-sm cursor-pointer hover:bg-slate-50 rounded-md" />}
                                                </div>
                                            </th>
                                            <th className=" ">City</th>
                                            <th className=" ">Town</th>
                                            <th className=" ">Address</th>
                                            <th className=" ">Email</th>
                                            <th className=" ">Contact</th>
                                            <th className=" ">
                                                <div className='flex justify-center items-center'>
                                                    <p>Date created</p>
                                                    {showDateSort ? <IoIosArrowRoundUp onClick={handleSortDateAsc} className="text-sm cursor-pointer hover:bg-slate-50 rounded-md" /> :
                                                        <IoIosArrowRoundDown onClick={handleSortDateDsc} className="text-sm cursor-pointer hover:bg-slate-50 rounded-md" />}                                                </div>
                                            </th>
                                            <th className=" ">
                                                <div className="relative">
                                                    <span className="flex items-center justify-center gap-1 font-semibold cursor-pointer">
                                                        <p>Status</p>
                                                        <FaChevronDown size={10} />
                                                    </span>
                                                    <select
                                                        id="status"
                                                        name="status"
                                                        value={selectedStatus}
                                                        onChange={(e) => { handleStatusChange(e.target.value); }}
                                                        className="absolute inset-0 w-full h-full opacity-0 cursor-pointer"
                                                    >
                                                        {status.map((type) => (
                                                            <option key={type} value={type}>
                                                                {type}
                                                            </option>
                                                        ))}
                                                    </select>
                                                </div>
                                            </th>
                                            <th className="px-4 py-2">Action</th>
                                        </tr>
                                    </thead>

                                    <tbody className='py-2'>
                                        {suppliers?.map((data, index) => (
                                            <tr key={data.id} onClick={() => { showSupplierDetail(data) }} className={index % 2 === 0 ? 'bg-gray-100 hover:bg-slate-50' : 'bg-white hover:bg-slate-50'}>
                                                <td className='border-r-2 text-center py-4 h-20 flex-wrap'>{index + 1}</td>
                                                <td className='border-x-2 text-center py-4 h-20 flex-wrap'>{data?.id}</td>
                                                <td className='border-x-2 text-center py-4 h-20 flex-wrap'>{data?.name}</td>
                                                <td className='border-x-2 text-center py-4 h-20 flex-wrap'>{data?.city}</td>
                                                <td className='border-x-2 text-center py-4 h-20 flex-wrap'>{data?.town}</td>
                                                <td className='border-x-2 text-center py-4 h-20 flex-wrap'>{data?.address}</td>
                                                <td className='border-x-2 text-center py-4 h-20 flex-wrap'>{data?.email}</td>
                                                <td className='border-x-2 text-center py-4 h-20 flex-wrap'>{data?.phone}</td>
                                                <td className='border-x-2 text-center py-4 h-20 flex-wrap'>{new Date(data?.created_at).toLocaleDateString()}</td>
                                                <td className={`${data?.status === 'active' ? 'text-green-600 ' : 'text-red-500'} border-x-2 text-center py-4 h-20 flex-wrap `}><p className={`${data?.status === 'active' ? 'bg-green-200 border border-green-400 px-[5px]' : data?.status === 'inactive' ? 'bg-red-200 border px-[3px] border-red-400' : data?.status === 'archived' ? 'bg-gradient-to-r from-gray-400 via-gray-600 to-black border border-gray-900 text-white px-[5px]' : ''} shadow-md mx-2 py-[1px] font-bold rounded-md text-xs`}>{data?.status}</p></td>

                                                {/* <td className='flex items-center justify-center h-20 '>

                                        <p onClick={() => { showEditModal(data) }} className='text-green-500 hover:border-b text-center font-semibold hover:border-b-green-500 cursor-pointer'>Edit</p>

                                    </td> */}
                                                <td className='flex items-center justify-end h-20 relative'>
                                                    <CiMenuKebab className='cursor-pointer' size={18} onClick={(event) => togglePopUp(event, data.id, data)} />
                                                    {selectedDropdown === data.id && (
                                                        <div ref={popUpRef} className='absolute flex flex-col z-10 gap-2 top-8 right-0 bg-white border rounded shadow-md min-h-max p-2 min-w-max w-20'>
                                                            <div className='cursor-pointer justify-center  hover:bg-slate-50 flex gap-2 items-center'>
                                                                <FaRegEye />
                                                                <p className=' text-xs sm:text-sm ' >
                                                                    View
                                                                </p>
                                                            </div>
                                                            {data.status === 'archived' ? <div onClick={(event) => { showArchiveModal(event, data) }} className='cursor-pointer justify-center hover:bg-slate-50 flex gap-2 items-center'>
                                                                <VscLayersActive />
                                                                <p className=' text-xs sm:text-sm' >
                                                                    Re-activate
                                                                </p>
                                                            </div> :

                                                                hasPermission(roles, ['supplierAdmin', 'supplierWriter']) && (
                                                                    <div onClick={(event) => { showEditModal(event, data) }} className={`${data.on_pharmtrix ? 'hidden' : 'cursor-pointer justify-center hover:bg-slate-50 flex gap-2 items-center'} `}>
                                                                        <GrEdit />
                                                                        <p className=' text-xs sm:text-sm' >
                                                                            Edit
                                                                        </p>
                                                                    </div>
                                                                )}

                                                            {data.status == 'active' && data.on_pharmtrix || data.status === 'archived' ?

                                                                hasPermission(roles, ['supplierAdmin']) && (
                                                                    <div onClick={(event) => { showDeleteModal(event, data) }} className='cursor-pointer justify-center hover:bg-slate-50 flex gap-2 items-center'>
                                                                        <MdOutlineAutoDelete />
                                                                        <p className=' text-xs sm:text-sm' >
                                                                            Delete
                                                                        </p>
                                                                    </div>
                                                                )
                                                                : null}

                                                            {data.status === 'inactive' &&

                                                                hasPermission(roles, ['supplierAdmin']) && (
                                                                    <div onClick={(event) => { showArchiveModal(event, data) }} className='cursor-pointer justify-center hover:bg-slate-50 flex gap-2 items-center'>
                                                                        <FaRegFileArchive />
                                                                        <p className=' text-xs sm:text-sm' >
                                                                            Archive
                                                                        </p>
                                                                    </div>
                                                                )}

                                                            {/* Add other dropdown items as needed */}
                                                        </div>
                                                    )}

                                                </td>
                                            </tr>

                                        ))}
                                    </tbody>
                                </table>
                            </div>
                            {/* <div className='flex items-center gap-4 mt-4'>
                                <button onClick={handlePrevPage} disabled={currentPage === 1} className={currentPage === 1 ? 'hidden' : 'text-xs text-green-600'}>Previous</button>
                                <div className='flex items-center gap-3'>
                                    {Array.from({ length: lastPage }, (_, i) => (
                                        <button key={i + 1} onClick={() => handlePageClick(i + 1)} className={`text-xs ${currentPage === i + 1 ? 'font-bold' : ''}`}>{i + 1}</button>
                                    ))}
                                </div>
                                <button onClick={handleNextPage} disabled={currentPage === lastPage} className={currentPage === lastPage ? 'hidden' : 'text-xs text-green-600'}>Next</button>
                            </div> */}
                             <div className='flex items-center gap-4 mt-4'>
                <button
                    onClick={handlePrevPage}
                    disabled={currentPage === 1}
                    className={currentPage === 1 ? 'text-gray-500 text-xs' : 'text-xs text-green-600'}
                >
                    Previous
                </button>
                <div className='flex items-center gap-3'>
                    {getPageNumbers(currentPage, lastPage).map((pageNumber, index) => (
                        <React.Fragment key={index}>
                            {pageNumber === '...' ? (
                                <span className="text-xs">...</span>
                            ) : (
                                <button
                                    onClick={() => handlePageClick(pageNumber)}
                                    className={`text-xs ${currentPage === pageNumber ? 'font-bold' : ''}`}
                                >
                                    {pageNumber}
                                </button>
                            )}
                        </React.Fragment>
                    ))}
                </div>
                <button
                    onClick={handleNextPage}
                    disabled={currentPage === lastPage}
                    className={currentPage === lastPage ? 'text-gray-500 text-xs' : 'text-xs text-green-600'}
                >
                    Next
                </button>
            </div>

                        </div>
                    ) : (
                        <div>
                            {showbtn ? (
                                <div className="flex flex-col items-center justify-center font-lufga h-[80vh] sm:h-[65vh]">
                                    <p className="text-gray-500 text-center text-xs">{`No ${filter} suppliers`}</p>
                                    <button
                                        onClick={() => {
                                            handleStatusChange('All');
                                            // setHadSuppliers(false);

                                        }}
                                        className="bg-green-500 hover:opacity-90 text-white py-2 px-4 rounded-sm shadow-md mt-4">Go back</button>
                                </div>
                            ) : hasSearched ? (
                                <div className="flex flex-col items-center justify-center font-lufga h-[80vh] sm:h-[65vh]">
                                    <p className="text-gray-500 text-center text-xs">No suppliers found</p>
                                    <button onClick={() => { handleSearchClick('') }} className="bg-green-500 hover:opacity-90 text-white py-2 px-4 rounded-sm shadow-md mt-4">{searchLoading ? <ColorRing
                                        height="20"
                                        width="20"
                                        ariaLabel="color-ring-loading"
                                        wrapperStyle={{}}
                                        wrapperClass="color-ring-wrapper"
                                        colors={['#e15b64', '#f47e60', '#f8b26a', '#abbd81', '#849b87']}
                                    /> : "Go back"}</button>
                                </div>
                            ) : hadSuppliers ? (
                                <div className="flex flex-col items-center justify-center font-lufga h-[80vh] sm:h-[65vh]">
                                    <p className="text-gray-500 text-center text-xs">Empty List</p>
                                    {hasPermission(roles, ['supplierWriter', 'supplierAdmin']) && (
                                        <button onClick={toggleSupplierForm} className="bg-green-500 hover:opacity-90 text-white py-2 px-4 rounded-sm shadow-md mt-4">Add Suppliers</button>

                                    )}
                                </div>
                            ) : (
                                <div className="flex flex-col items-center justify-center font-lufga h-[80vh] sm:h-[65vh]">
                                    <p className="text-gray-500 text-center text-xs">Get Started</p>
                                    {hasPermission(roles, ['supplierWriter', 'supplierAdmin']) && (
                                        <button onClick={toggleSupplierForm} className="bg-green-500 hover:opacity-90 text-white py-2 px-4 rounded-sm shadow-md mt-4">Add Suppliers</button>

                                    )}
                                </div>

                            )}
                        </div>
                    )}
                    {editModal && (
                        <div className="fixed inset-0 flex items-center justify-center bg-gray-800 bg-opacity-50">
                            <div ref={modalRef} className="bg-white rounded-md min-h-max shadow-lg p-6">
                                <IoMdCloseCircle size={24} onClick={() => setEditModal(false)} className='hover:scale-105 hover:text-red-800 transition duration-150 ease-out cursor-pointer ' />
                                <EditSupplier toggle={() => setEditModal(false)} data={selectedSupplier} fetchSuppliers={fetchSuppliers} />
                            </div>
                        </div>
                    )}
                    {deleteModal && (
                        <div className="fixed inset-0 flex items-center justify-center bg-gray-800 bg-opacity-50">
                            <div ref={modalRef} className="bg-white rounded-md min-h-max shadow-lg p-6">
                                <IoMdCloseCircle size={24} onClick={() => setDeleteModal(false)} className='hover:scale-105 hover:text-red-800 transition duration-150 ease-out cursor-pointer ' />
                                <DeleteSupplier toggle={() => setDeleteModal(false)} data={selectedSupplier} fetchSuppliers={fetchSuppliers} showEmptydivHelper={showEmptydivHelper}/>
                            </div>
                        </div>
                    )}
                    {archiveModal && (
                        <div className="fixed inset-0 flex items-center justify-center bg-gray-800 bg-opacity-50">
                            <div ref={modalRef} className="bg-white rounded-md min-h-max shadow-lg p-6">
                                <IoMdCloseCircle size={24} onClick={() => setArchiveModal(false)} className='hover:scale-105 hover:text-red-800 transition duration-150 ease-out cursor-pointer ' />
                                <ArchiveSupplier toggle={() => setArchiveModal(false)} data={selectedSupplier} fetchSuppliers={fetchSuppliers} />
                            </div>
                        </div>
                    )}

                </div>)}

                {showModal && (
                    <div className="fixed inset-0 flex items-center justify-center bg-gray-800 bg-opacity-50">
                        <div ref={modalRef} className="bg-white rounded-md min-h-max shadow-lg p-6">
                            <IoMdClose size={24} onClick={toggleSupplierForm} className='hover:scale-105 hover:text-red-800 transition duration-150 ease-out cursor-pointer ' />
                            <AddSupplier toggle={toggleSupplierForm} fetchSuppliers={fetchSuppliers} />
                        </div>
                    </div>
                )}



            </div> :
                <div className='font-lufga'>

                    <div>
                        <p onClick={() => { setSupplier_view(false) }} className='flex items-center gap-1 cursor-pointer hover:text-neutral-400'><IoArrowBackCircleOutline size={28} />Go back</p>

                    </div>
                    <div className='my-4 mx-8 '>
                        <p className='lg:text-3xl text-xl font-semibold text-slate-600'>Supplier Details</p>
                    </div>
                    <div className='grid lg:grid-cols-4 gap-8 lg:gap-0 sm:border shadow-md rounded-sm'>
                        <div className='col-span-4 lg:col-span-1 lg:border-r   bg-neutral-100'>
                            <div className='flex flex-col gap-2 max-h-[60vh] overflow-auto'>
                                <div className='sticky top-0 z-1 bg-neutral-100 p-2 w-100'>
                                    <p className='text-sm font-lufga w-full border-b '>Supplier List</p>
                                    <div>
                                        {/* <input type='text' value={searchQuery} onChange={(e) => { setSearchQuery(e.target.value) }} placeholder='Search Users' className='w-56 sm:w-[150px] focus:outline-none rounded-md shadow-sm border px-2 py-3 h-3 font-lufga text-xs' /> */}
                                    </div>
                                </div>
                                {suppliers.map((data) => (
                                    <div key={data.id} className={`cursor-pointer px-[5px] hover:bg-slate-200 py-1 ${supplier.id === data.id ? 'bg-neutral-200' : ''}`} onClick={() => setSupplier(data)}>
                                        <p className='text-sm'>{data?.name}</p>
                                        <p className='text-xs font-bold text-gray-600'>{data?.email}</p>
                                        <p className='text-xs font-bold'>{data?.city} - {data?.town}</p>
                                    </div>
                                ))}

                            </div>
                            <div className="sticky bottom-0 left-0 right-0 bg-neutral-100 z-10 p-2">
                                <div className='flex items-center gap-1'>
                                    <button onClick={handlePrevPage} disabled={currentPage === 1} className={currentPage === 1 ? 'hidden' : 'text-xs text-green-600'}>Prev</button>
                                    <div className='flex items-center gap-1'>
                                        {Array.from({ length: lastPage }, (_, i) => (
                                            <button key={i + 1} onClick={() => handlePageClick(i + 1)} className={`text-xs ${currentPage === i + 1 ? 'font-bold' : ''}`}>{i + 1}</button>
                                        ))}
                                    </div>
                                    <button onClick={handleNextPage} disabled={currentPage === lastPage} className={currentPage === lastPage ? 'hidden' : 'text-xs text-green-600'}>Next</button>
                                </div>
                            </div>

                        </div>
                        <div className='col-span-4 lg:col-span-3 p-2 '>
                            <p className='text-sm font-lufga w-full sm:border-b'>Details</p>
                            <div className='max-h-[60vh] overflow-auto'>
                                <SupplierDetail toggle={supplier_view} fetchSuppliers={() => fetchSuppliers(currentPage, limit, '', () => { })} supplier={supplier} suppliers={suppliers} />

                            </div>

                        </div>

                    </div>
                </div>
            }
        </div>

    );
}

export default SupplierInfo;
