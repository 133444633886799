import React, { useEffect, useRef, useState } from 'react';
import axios from 'axios';
import { HiOutlineBell, HiOutlineLogout, HiOutlineMail } from 'react-icons/hi';
import { Link, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { IoLogOutOutline } from 'react-icons/io5';
import { CgProfile } from "react-icons/cg";
import rlogo from '../../assets/rlogo.png';
import { clearAuthData, logoutState } from '../../Redux/AuthSlice';
import { api_url } from '../../config';
import { toast } from 'react-toastify';


const RetailHeader = () => {

    const [location, setLocation] = useState({})
    const [data, setData] = useState([])
    const dispatch = useDispatch();
    const [isDropdownVisible, setDropdownVisible] = useState(false);
    const {first_name,last_name,email} = useSelector((state) => state.auth)
    const token = useSelector((state) => state.auth.token)

    //   this gets me the user location cos I need the city

    // useEffect(() => {
    //     const fetchData = async () => {
    //         try {
    //             const locationResponse = await axios.get('https://ipapi.co/json');
    //             setLocation(locationResponse.data);

    //             if (locationResponse.data.city) {
    //                 const url = `https://restcountries.com/v3.1/capital/${locationResponse.data.city}`;
    //                 const dataResponse = await axios.get(url);
    //                 setData(dataResponse.data);

    //             }
    //         } catch (error) {
    //             // Handle errors
    //             console.error('Error fetching data:', error);
    //         }
    //     };

    //     fetchData();
    // }, []);

    const handleLogout = async (e) => {
        e.preventDefault();
        try {
            dispatch(logoutState(true))
                await axios.post(api_url+'/api/retailer/logout', {}, {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                });
            
            dispatch(clearAuthData());

            // Manipulate the browser's history to prevent going back to protected pages
            window.history.pushState(null, '', '/login');
            window.onpopstate = () => {
                window.history.pushState(null, '', '/login');
            };
            dispatch(logoutState(false))
            toast.success('Logout successful')
            // Navigate to the login page
            navigate('/login', { replace: true });
        } catch (error) {
            console.error('Logout error:', error);
            // Handle error if needed
        }
    };


    const navigate = useNavigate()

    const toggleDropdown = () => {
        setDropdownVisible(!isDropdownVisible);
    };

    const dropdownRef = useRef(null);
    const handleClickOutside = (event) => {
        if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
            setDropdownVisible(false);
        }
    };

    useEffect(() => {
        document.addEventListener('click', handleClickOutside);
        return () => {
            document.removeEventListener('click', handleClickOutside);
        };
    }, []);

    function getInitials(name) {
        const words = name?.split(' ');
        const firstNameInitial = words?.[0]?.charAt(0).toUpperCase();
        const lastNameInitial = words?.[words.length - 1]?.charAt(0).toUpperCase();
        return firstNameInitial + lastNameInitial;
    }
    const name= `${first_name} ${last_name}`
    const initials = getInitials(name);
    

    return (
        <div className=' shadow-md  font-lufga'>
            <div className='flex justify-between items-center px-2 sm:px-8 py-1 '>
                {/* <p className='text-xs sm:text-sm sm:flex gap-2'><span className='font-lufga flex items-center gap-2'><img src={wlogo} className='w-[30px] shadow-md'/>Pharmtrix Wholesale </span>|| <span className='font-lufga'>Company name</span> </p> */}
                <p className='text-xs sm:text-sm sm:flex gap-2'><img src={rlogo} className='w-[30px] shadow-md' />Welcome, {name}</p>
                <div className='flex justify-center items-center gap-4 z-50'>

                    <div className='relative font-lufga' ref={dropdownRef}>
                        <p
                            className='px-1 py-2.5 border border-black h-10 w-10 rounded-full text-xs text-center sm:text-sm cursor-pointer'
                            onClick={toggleDropdown}
                        >
                            {initials}
                        </p>
                        {isDropdownVisible && (
                            <div className='absolute flex flex-col gap-2 top-8 right-0 bg-white border rounded shadow-md p-2 min-w-max'>
                                <div onClick={handleLogout} className='cursor-pointer hover:bg-slate-50 flex gap-2 items-center'>
                                    <IoLogOutOutline size={22} />
                                    <p className=' text-xs sm:text-sm' >
                                        Logout
                                    </p>
                                </div>
                                <Link to='/retail/profile'>
                               <div className='cursor-pointer hover:bg-slate-50 flex gap-2 items-center'>
                                    <CgProfile size={22} />
                                    <p className=' text-xs sm:text-sm' >
                                        Profile
                                    </p>
                                </div>
                               </Link>

                            </div>
                        )}
                    </div>
                </div>

            </div>
        </div>
    );
}

export default RetailHeader;
