import React, { useState } from 'react';
import { MdShoppingCartCheckout } from "react-icons/md";
import { ColorRing } from 'react-loader-spinner';
import api from '../../../utils/api';
import { api_url } from '../../../config';
import { toast } from 'react-toastify';
import { useSelector } from 'react-redux';
import { hasPermission } from '../../../Redux/permissionSlice';


const AwaitingPaymentComponent = ({ order, fetchOrder ,roles}) => {

    const [loading, setLoading] = useState(false)
    const { id, branch_id, token } = useSelector((state) => state.auth)

    const UpdateOrder = async (status) => {
        setLoading(true)
        if (!navigator.onLine) {
            toast("You're offline");
            setLoading(false);
            return;
        }
        

        try {
            setLoading(true)
            const response = await api.put(api_url + `/api/v1/retailer/user/${id}/branch/${branch_id}/order/${order.id}`, { data: {status} }, {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${token}`
                },

            })
            fetchOrder()
        } catch (error) {
            console.log(error);
            toast("Error fetching orders")

        } finally {
            setLoading(false)
        }
    }
    const products = []

    const subtotal = products.reduce((sum, product) => sum + product.cost, 0);
    const fees = 1.00;
    const discount = 0.00;
    const total = subtotal + fees - discount;
    console.log(order);

    return (
        <div>
            <div className="w-full mx-auto my-4 bg-white shadow-md rounded-lg max-h-[50vh] overflow-auto">
                <table className="w-full border-collapse border border-gray-300 text-xs">
                    <thead>
                        <tr className="bg-gray-100">
                            <th className="border border-gray-300 p-2">Product Name</th>
                            <th className="border border-gray-300 p-2">Manufacturer</th>
                            <th className="border border-gray-300 p-2">Demanded Quantity</th>
                            <th className="border border-gray-300 p-2">Stocks</th>
                        </tr>
                    </thead>
                    <tbody>
                        {order?.order_items.map((item) => (
                            <tr key={item.id} className="border-b border-gray-300">
                                <td className="border border-gray-300 p-2">{item.product_name}</td>
                                <td className="border border-gray-300 p-2">{item.manufacturer}</td>
                                <td className="border border-gray-300 p-2 text-center">{item.demand_quantity}</td>
                                <td className="border border-gray-300 p-2">
                                    <table className="w-full border-collapse">
                                        <thead>
                                            <tr className="bg-gray-50">
                                                <th className="border border-gray-200 p-1 ">ID</th>
                                                <th className="border border-gray-200 p-1 ">Supply Quantity</th>
                                                <th className="border border-gray-200 p-1 ">Unit Price</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {item.stocks.map((stock) => (
                                                <tr key={stock.id}>
                                                    <td className="border border-gray-200 p-1  text-center">{stock.wholesale_stock.id}</td>
                                                    <td className="border border-gray-200 p-1  text-center">{stock.wholesale_stock.supply_quantity}</td>
                                                    <td className="border border-gray-200 p-1  text-center">{stock.wholesale_stock.unit_price}</td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
                <div className="border-t border-gray-200 px-4 py-2">
                    {/* <div className="flex justify-between">
                        <span>Subtotal</span>
                        <span>{subtotal.toFixed(2)}</span>
                    </div> */}
                    {/* <div className="flex justify-between">
                        <span>Fees & Estimated Tax</span>
                        <span>{fees.toFixed(2)}</span>
                    </div> */}
                    <div className="flex justify-between">
                        <span>Discount</span>
                        <span>{order?.discount}</span>
                    </div>
                    <div className="flex justify-between font-bold mt-2">
                        <span>TOTAL</span>
                        <span>{order?.invoice_total}</span>
                    </div>
                </div>
            </div>
            {/* <div className=' flex justify-end'>
                <button onClick={UpdateOrder} className="bg-green-500  hover:opacity-90 text-white py-1 px-4 rounded-md shadow-md text-xs">
                    {loading ? <ColorRing
                        height="20"
                        width="20"
                        ariaLabel="color-ring-loading"
                        wrapperStyle={{}}
                        wrapperClass="color-ring-wrapper"
                        colors={['#e15b64', '#f47e60', '#f8b26a', '#abbd81', '#849b87']}
                    /> : 'Mark as Payment sent'}
                </button>
            </div> */}
          
            {hasPermission(roles, ['orderWriter', 'orderAdmin']) && (
                 <div className='flex justify-end mt-10'>
                 {!loading ? <div className='flex items-center gap-3'>
                      <button onClick={()=>{UpdateOrder('cancelled')}} className="bg-gray-200 hover:opacity-90 text-black py-1 px-4 rounded-md shadow-md text-xs">
                          Cancel order
                      </button>
                      <button onClick={()=>{UpdateOrder('awaiting-payment')}} className="bg-green-500 hover:opacity-90 text-white py-1 px-4 rounded-md shadow-md text-xs">
                      Mark as Payment sent
                      </button>
                  </div> :<ColorRing height="20" width="20" colors={['#e15b64', '#f47e60', '#f8b26a', '#abbd81', '#849b87']} />}
              </div>
            )}

        </div>
    );
}

export default AwaitingPaymentComponent;
