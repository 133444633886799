import React, { useState, useEffect } from 'react';
import { FcSearch } from 'react-icons/fc';
import { ColorRing, InfinitySpin } from 'react-loader-spinner';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { api_url } from '../../../config';
import api from '../../../utils/api';
import { toast } from 'react-toastify';
import { getPageNumbers } from '../../../utils/getPageNumbers';

const SelectProducts = ({ supplier, onProductsSelected }) => {
    const [products, setProducts] = useState([]);
    const [selectedProducts, setSelectedProducts] = useState([]);
    const [limit, setLimit] = useState(100)
    const [loading, setLoading] = useState(true)
    const [currentPage, setCurrentPage] = useState(1);
    const [lastPage, setLastPage] = useState();
    const [metadata, setMetadata] = useState('')
    const [search, setSearch] = useState('')
    const [searchLoading, setSearchLoading] = useState(false)
    const [hasSearched, setHasSearched] = useState(false);

    const { id, branch_id, token } = useSelector((state) => state.auth)

    const handleContinue = () => {
        if (selectedProducts.length === 0) {
            toast.warning('Please select at least one product to proceed')
            return
        }
        onProductsSelected(selectedProducts)
    }


    const fetchProducts = async (page, limit, searchQuery) => {
        setLoading(loading)
        if (!navigator.onLine) {
            toast("You're offline");
            setLoading(false);
            return;
        }
        const params = {
            page: page || 1,
            limit: limit || 50,
            // sort: sortby,
            search: `${searchQuery || ''}`

        };
        try {
            const response = await api.get(api_url + `/api/v1/retailer/user/${id}/retailbranch/${branch_id}/wholesalebranch/${supplier?.branch_id}/inventory`, {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${token}`
                },
                params
            })
            const data = response.data.data
            const meta = response.data.meta
            setProducts(data)
            setMetadata(meta)
            setLastPage(meta.last_page)

        } catch (error) {
            console.log(error);

        } finally {
            setLoading(false)
        }
    }




    useEffect(() => {
        fetchProducts(currentPage, limit)
    }, [id, token, currentPage, supplier])

    const handleQuantityChange = (product, quantity) => {
        setSelectedProducts(prevSelected => {
            const updatedProducts = [...prevSelected];
            const index = updatedProducts.findIndex(p => p.id === product.id);
            if (index !== -1) {
                updatedProducts[index] = { ...updatedProducts[index], quantity };
            } else {
                updatedProducts.push({
                    id: product?.id,
                    name: product?.product_name,
                    manufacturer: product?.manufacturer,
                    quantity
                });
            }
            return updatedProducts.filter(p => p.quantity > 0);
        });
    };

    const handlePageClick = (pageNumber) => {
        setCurrentPage(pageNumber);
    };


    const handleNextPage = () => {
        if (currentPage < lastPage) {
            setCurrentPage(currentPage + 1);

        }
    };

    const handlePrevPage = () => {
        if (currentPage > 1) {
            setCurrentPage(currentPage - 1);
        }
    };


    // function getPageNumbers() {
    //     const pageNumbers = [];
    //     const totalDisplayed = 7; // Total number of page buttons to display
    //     const sideButtons = 2; // Number of buttons to show on each side of the current page

    //     if (lastPage <= totalDisplayed) {
    //         // If total pages are less than or equal to totalDisplayed, show all pages
    //         for (let i = 1; i <= lastPage; i++) {
    //             pageNumbers.push(i);
    //         }
    //     } else {
    //         // Always show first page
    //         pageNumbers.push(1);

    //         if (currentPage > sideButtons + 2) {
    //             pageNumbers.push('...');
    //         }

    //         // Calculate start and end of the current range
    //         let rangeStart = Math.max(2, currentPage - sideButtons);
    //         let rangeEnd = Math.min(lastPage - 1, currentPage + sideButtons);

    //         // Adjust range if at the start or end
    //         if (currentPage <= sideButtons + 2) {
    //             rangeEnd = totalDisplayed - 2;
    //         } else if (currentPage >= lastPage - sideButtons - 1) {
    //             rangeStart = lastPage - totalDisplayed + 3;
    //         }

    //         for (let i = rangeStart; i <= rangeEnd; i++) {
    //             pageNumbers.push(i);
    //         }

    //         if (currentPage < lastPage - sideButtons - 1) {
    //             pageNumbers.push('...');
    //         }

    //         // Always show last page
    //         pageNumbers.push(lastPage);
    //     }

    //     return pageNumbers;
    // }



    const handleSearchClick = async (searchQuery) => {
        setSearchLoading(!searchLoading)
        try {
            await fetchProducts(1, 50, searchQuery);;
            setHasSearched(true);
        } catch (error) {
            console.log(error);
        } finally {
            setSearchLoading(false)
        }
    };

    return (
        <div className="container mx-auto px-4 text-xs">
            {loading && ( // Display loader while loading
                <div className="flex justify-center items-center w-full h-full bg-gradient-to-br from-opacity-40 to-opacity-60 backdrop-blur-sm absolute top-0 left-0">
                    <InfinitySpin color="#059212" loading={loading} size={40} />
                </div>
            )}
            <div className='flex items-center justify-between mb-2'>
                <p>{supplier?.name}</p>
                <div className='flex items-center gap-2'>
                    <input
                        type='text'
                        value={search}
                        onChange={(e) => setSearch(e.target.value)}
                        placeholder='Search suppliers'
                        className='w-38 sm:w-[200px] focus:outline-none rounded-md shadow-sm border px-2 py-3 h-3 font-lufga text-xs'
                    />
                    {searchLoading ? (
                        <ColorRing
                            height="20"
                            width="20"
                            ariaLabel="color-ring-loading"
                            wrapperStyle={{}}
                            wrapperClass="color-ring-wrapper"
                            colors={['#e15b64', '#f47e60', '#f8b26a', '#abbd81', '#849b87']}
                        />
                    ) : (
                        <FcSearch onClick={() => { handleSearchClick(search) }} className='hover:scale-110 cursor-pointer' />
                    )}
                </div>
            </div>
            <table className="w-full border-collapse">
                <thead>
                    <tr className="bg-gray-100 text-left">
                        <th className="py-2 px-4 border-b">Product Name</th>
                        <th className="py-2 px-4 border-b">Manufacturer</th>
                        <th className="py-2 px-4 border-b text-right">Total Quantity Available</th>
                        <th className="py-2 px-4 border-b text-right">Choose Quantity</th>
                    </tr>
                </thead>
                <tbody>
                    {products.map(product => (
                        <tr key={product?.id} className="border-b">
                            <td className="py-2 px-4">{product?.product_name}</td>
                            <td className="py-2 px-4">{product?.manufacturer}</td>
                            <td className="py-2 px-4 text-right">{product?.total}</td>
                            <td className="py-2 px-4">
                                <div className="flex justify-end items-center">
                                    <button
                                        className="bg-gray-200 px-2 py-1 rounded-l"
                                        onClick={() => handleQuantityChange(product, (selectedProducts.find(p => p.id === product.id)?.quantity || 0) - 1)}
                                    >
                                        -
                                    </button>
                                    <input
                                        type="number"
                                        min="0"
                                        max={product.total}
                                        value={selectedProducts.find(p => p.id === product.id)?.quantity || 0}
                                        onChange={(e) => handleQuantityChange(product, parseInt(e.target.value))}
                                        className="w-12 text-center border-t border-b"
                                    />
                                    <button
                                        className="bg-gray-200 px-2 py-1 rounded-r"
                                        onClick={() => handleQuantityChange(product, (selectedProducts.find(p => p.id === product.id)?.quantity || 0) + 1)}
                                    >
                                        +
                                    </button>
                                </div>
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>
            <div className='flex items-center gap-4 mt-4'>
                <button
                    onClick={handlePrevPage}
                    disabled={currentPage === 1}
                    className={currentPage === 1 ? 'text-gray-500 text-xs' : 'text-xs text-green-600'}
                >
                    Previous
                </button>
                <div className='flex items-center gap-3'>
                    {getPageNumbers(currentPage, lastPage).map((pageNumber, index) => (
                        <React.Fragment key={index}>
                            {pageNumber === '...' ? (
                                <span className="text-xs">...</span>
                            ) : (
                                <button
                                    onClick={() => handlePageClick(pageNumber)}
                                    className={`text-xs ${currentPage === pageNumber ? 'font-bold' : ''}`}
                                >
                                    {pageNumber}
                                </button>
                            )}
                        </React.Fragment>
                    ))}
                </div>
                <button
                    onClick={handleNextPage}
                    disabled={currentPage === lastPage}
                    className={currentPage === lastPage ? 'text-gray-500 text-xs' : 'text-xs text-green-600'}
                >
                    Next
                </button>
            </div>
            <div className='flex justify-between mt-4'>
                <Link to='/retail/orders/addOrders/' state={{ requiredRoles: ['orderViewer', 'orderWriter', 'orderAdmin'] }}>
                    <button className="bg-gray-300 hover:bg-gray-400 text-gray-800 py-1 px-4 rounded-md shadow-sm text-xs">Back</button>
                </Link>

                <button
                    // onClick={() => onProductsSelected(selectedProducts)}
                    onClick={handleContinue}
                    className="bg-green-500  hover:opacity-90 text-white py-1 px-4 rounded-md shadow-sm text-xs">
                    Continue
                </button>
            </div>



        </div>
    );
};

export default SelectProducts;