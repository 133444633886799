import React, { useEffect, useRef, useState } from 'react';
import { FaRegEye } from 'react-icons/fa6';
import { InfinitySpin } from 'react-loader-spinner';
import { GrEdit } from "react-icons/gr";
import { CiMenuKebab } from "react-icons/ci";
import { IoIosArrowRoundDown, IoIosArrowRoundUp, IoMdClose } from 'react-icons/io';
import EditSupplier from '../supplier/EditSupplier';
import AddSupplier from '../supplier/AddSupplier';
import AddSales from './AddSales';
import { useDispatch, useSelector } from 'react-redux';
import axios from 'axios';
import { toast } from 'react-toastify';
import { api_url } from '../../../config';
import SaleDetail from './SaleDetail';
import { IoArrowBackCircleOutline } from 'react-icons/io5';
import { clearCart } from '../../../Redux/SalesSlice';
import { useLocation } from 'react-router';
import { FcSearch } from 'react-icons/fc';
import { hasPermission } from '../../../Redux/permissionSlice';
import { selectRoles } from '../../../Redux/AuthSlice';
import api from '../../../utils/api';
import { ExpandableProductList } from '../../../utils/ExpandableCells';
import { getPageNumbers } from '../../../utils/getPageNumbers';

const SalesInfo = () => {
    const [sales, setSales] = useState([])
    const [sale, setSale] = useState([])
    const [loading, setLoading] = useState(true)
    const [selectedDropdown, setSelectedDropdown] = useState(null);
    const [selectedSales, setSelectedSales] = useState(null);
    const [salesToEdit, setSalesToEdit] = useState(null);
    const [showModal, setShowModal] = useState(false)
    const [editModal, setEditModal] = useState(false)
    const [selectedSalesId, setSelectedSalesId] = useState()
    const modalRef = useRef();
    const [currentPage, setCurrentPage] = useState(1);
    const [lastPage, setLastPage] = useState();
    const [limit, setLimit] = useState(50)
    const [newLimit, setNewLimit] = useState(50);
    const [sales_limit, setSales_limit] = useState('')
    const [filter, setFilter] = useState('')
    const [filter_action, setFilter_action] = useState(null)
    const [filter_by, setFilter_by] = useState('')
    const [start_date, setStart_date] = useState('')
    const [end_date, setEnd_date] = useState('')
    const [showbtn, setShowbtn] = useState(true)
    const [sale_view, setSale_view] = useState(false)

    const [sortby, setSortby] = useState('-created_at')
    const [showAmountSort, setShowAmountSort] = useState(false)
    const [showDateSort, setShowDateSort] = useState(false)
    const [showDateSoldSort, setShowDateSoldSort] = useState(false)
    const [metadata, setMetadata] = useState('')
    const [expandedRowIndex, setExpandedRowIndex] = useState(null);


    const dispatch = useDispatch()
    const location = useLocation();


    const getPath = (specificPath) => {
        return location.pathname === specificPath;
    };
    const isRender = getPath('/wholesale/dashboard');



    const handleSortAmountAsc = () => {
        setSortby('amount')
        setShowAmountSort(!showAmountSort)
    }

    const handleSortAmountDsc = () => {
        setSortby('-amount')
        setShowAmountSort(!showAmountSort)

    }

    const handleSortDateAsc = () => {
        setSortby('created_at')
        setShowDateSort(!showDateSort)
    }

    const handleSortDateDsc = () => {
        setSortby('-created_at')
        setShowDateSort(!showDateSort)

    }
    const handleSortDateSoldAsc = () => {
        setSortby('date_sold')
        setShowDateSoldSort(!showDateSoldSort)
    }

    const handleSortDateSoldDsc = () => {
        setSortby('-date_sold')
        setShowDateSoldSort(!showDateSoldSort)

    }



    const handleLimitChange = (e) => {
        setNewLimit(parseInt(e.target.value));
    };

    const applyNewLimit = () => {
        setLimit(newLimit);
    };

    const handlePageClick = (pageNumber) => {
        setCurrentPage(pageNumber);
    };


    const handleNextPage = () => {
        if (currentPage < lastPage) {
            setCurrentPage(currentPage + 1);

        }
    };

    const handlePrevPage = () => {
        if (currentPage > 1) {
            setCurrentPage(currentPage - 1);
        }
    };

    const togglePopUp = (event, index, sales) => {
        event.stopPropagation();
        event.preventDefault();
        if (index === selectedDropdown) {
            setSelectedDropdown(null);
            setSelectedSales(null); // Deselect branch if clicking on the same dropdown
        } else {
            setSelectedDropdown(index);
            setSelectedSales(sales);
        }
    };
    useEffect(() => {
        // Function to handle click outside of modal
        const handleClickOutside = (event) => {
            if (modalRef.current && !modalRef.current.contains(event.target)) {
                setShowModal(false);
                setEditModal(false)
            }
        };

        // Attach the event listener
        document.addEventListener('mousedown', handleClickOutside);

        // Cleanup the event listener
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);
    useEffect(() => {
        // Function to handle click outside of modal
        const handleClickOutside = (event) => {
            if (popUpRef.current && !popUpRef.current.contains(event.target)) {
                setSelectedDropdown(null);
            }
        };

        // Attach the event listener
        document.addEventListener('mousedown', handleClickOutside);

        // Cleanup the event listener
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    const popUpRef = useRef();
    const showEditModal = (salesId) => {
        setEditModal(!editModal)
        setSelectedSalesId(salesId)
    }
    const toggleSalesForm = () => {
        dispatch(clearCart())
        setShowModal(!showModal)
    };

    const handleEditSales = (event, salesId) => {
        if (event) {
            event.preventDefault(); // Prevent default behavior
            event.stopPropagation();
        }
        const salesToEdit = sales.find(sales => sales.id === salesId);
        if (salesToEdit) {
            setEditModal(true);
            setSalesToEdit(salesToEdit);
        }
    };

    const { id, branch_id, token } = useSelector((state) => state.auth)

    const handleFilterDate = (start_date, end_date) => {
        setShowbtn(false)
        if (start_date === '' && end_date === '') {
            setFilter_by('');
        } else {
            setFilter_by('dates');
        }
        setFilter_action(!filter_action)

    };



    const fetchSaleTransactions = async (page, limit) => {
        setLoading(loading);
        if (!navigator.onLine) {
            toast("You're offline");
            setLoading(false);
            return;
        }
        try {
            const params = {
                page: page || 1,
                limit: limit || 50,
                sort: sortby,
                [`filter[${filter_by}]`]: `${start_date},${end_date}`
            };
            // if (filter_by) {
            //     params[`filter[${filter_by}]`] = `${start_date},${end_date}`;
            // }

            const response = await api.get(`${api_url}/api/v1/wholesaler/user/${id}/branch/${branch_id}/transaction`, {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${token}`
                },
                params
            });
            const data = response.data.data;
            const meta = response.data.meta
            setMetadata(meta)

            setSales(data);
            setSales_limit(response.data.meta.total);
            setLastPage(response.data.meta.last_page);
        } catch (error) {
            console.error(error);
        } finally {
            setLoading(false);
        }
    };



    const showSaleDetail = (data) => {
        setSale_view(!sale_view)
        setSale(data)
    }

    const selectedRoles = useSelector(selectRoles);
    const roles = Array.isArray(selectedRoles) ? selectedRoles : selectedRoles.split(',').map(role => role.trim());




    useEffect(() => {
        fetchSaleTransactions(currentPage, limit);
    }, [id, token, currentPage, limit, filter_by, filter_action, sortby]);




    return (
        <div className='font-lufga'>
            {!sale_view ? <div className='h-full overflow-auto '>
                {loading && ( // Display loader while loading
                    <div className="flex justify-center items-center w-full h-full bg-gradient-to-br from-opacity-40 to-opacity-60 backdrop-blur-sm absolute top-0 left-0">
                        <InfinitySpin color="#008DDA" loading={loading} size={40} />
                    </div>
                )}
                <div className='mx-8 mb-2'>
                    <p className='lg:text-3xl text-xl font-semibold text-slate-600'>Sales ({metadata?.total || '-'})</p>
                </div>

                <div className='font-lufga'>


                    <div className={sales.length === 0 ? 'hidden' : 'flex flex-col gap-4 mb-4 md:flex-row justify-between items-center font-lufga mx-6'}>
                        {currentPage === 1 ? <div className='flex items-center gap-2'>
                            <p className='text-sm'  >List per page</p>
                            <input className='border-[1px] w-16 px-2 text-xs' type="number" id="limit" value={newLimit} onChange={handleLimitChange} />
                            <button className="bg-blue-500  hover:opacity-90 text-white py-1 px-4 rounded-md shadow-sm text-xs" onClick={applyNewLimit}>Apply</button>
                        </div> :
                            <div>
                                <p className='text-sm'  >List</p>
                            </div>}

                        <div className='flex flex-col gap-2'>
                            <div className='flex flex-col sm:flex-row items-center gap-4'>
                                {/* <div className='flex items-center gap-2'>
                                    <input type='text' placeholder='Search sales' className='w-38 sm:w-[200px] focus:outline-none rounded-md shadow-sm border px-2 py-3 h-3 font-lufga text-xs' />
                                    <FcSearch  className='hover:scale-110 cursor-pointer'/>
                                </div> */}
                                {isRender ? null : <div>
                                    {hasPermission(roles, ['transactionWriter', 'transactionAdmin']) && (<button onClick={toggleSalesForm} className="bg-blue-500  hover:opacity-90 text-white py-1 px-4 rounded-md shadow-sm text-xs">Add sale</button>
                                    )}
                                </div>}

                            </div>

                        </div>

                    </div>
                    <div className='flex flex-col md:flex-row gap-2 mb-3 mx-6'>
                        <div>
                            <label className="text-xs text-gray-700 font-lufga flex items-center gap-1">
                                Start Date
                            </label>
                            <input value={start_date} onChange={(e) => { setStart_date(e.target.value) }} type='date' placeholder='Start Date' className='w-38 sm:w-[200px] focus:outline-none rounded-md shadow-sm border px-2 py-3 h-3 font-lufga text-xs' />

                        </div>
                        <div>
                            <label className="text-xs text-gray-700 font-lufga flex items-center gap-1">
                                End Date
                            </label>
                            <input value={end_date} onChange={(e) => { setEnd_date(e.target.value) }} type='date' placeholder='Start Date' className='w-38 sm:w-[200px] focus:outline-none rounded-md shadow-sm border px-2 py-3 h-3 font-lufga text-xs' />

                        </div>
                        <div>
                            <label className="text-xs text-gray-700 font-lufga flex items-center gap-1">
                                *
                            </label>
                            <button onClick={() => { handleFilterDate(start_date, end_date) }} className="bg-blue-500  hover:opacity-90 text-white py-1 px-4 rounded-md shadow-sm text-xs">Submit</button>

                        </div>
                    </div>
                    {!loading && (<div className='w-full '>
                        {sales.length > 0 ? (
                            <div>
                                <div className=' rounded-lg  shadow-md max-h-[60vh] overflow-auto'>

                                    <table className='w-full text-xs  px-2  table-auto  overflow-auto'>
                                        <thead className='bg-blue-200 shadow-lg sticky top-0 z-10'>
                                            <tr>
                                                <th className=''>No.</th>
                                                <th >ID</th>
                                                <th className=''>Retailer name</th>
                                                <th className=''>Products</th>
                                                {/* <th className=''>Retailer address</th> */}
                                                <th className=" ">
                                                    <div className='flex justify-center items-center '>
                                                        <p>Total Amount</p>
                                                        {showAmountSort ? <IoIosArrowRoundUp onClick={handleSortAmountAsc} className="text-sm cursor-pointer hover:bg-slate-50 rounded-md" /> :
                                                            <IoIosArrowRoundDown onClick={handleSortAmountDsc} className="text-sm cursor-pointer hover:bg-slate-50 rounded-md" />}                                                </div>
                                                </th>
                                                <th className=" ">
                                                    <div className='flex justify-center items-center'>
                                                        <p>Date Sold</p>
                                                        {showDateSoldSort ? <IoIosArrowRoundUp onClick={handleSortDateSoldAsc} className="text-sm cursor-pointer hover:bg-slate-50 rounded-md" /> :
                                                            <IoIosArrowRoundDown onClick={handleSortDateSoldDsc} className="text-sm cursor-pointer hover:bg-slate-50 rounded-md" />}                                                </div>
                                                </th>
                                                <th className=''>Sale Personnel</th>

                                                {/* <th className=" ">
                                                    <div className='flex justify-center items-center'>
                                                        <p>Date created</p>
                                                        {showDateSort ? <IoIosArrowRoundUp onClick={handleSortDateAsc} className="text-sm cursor-pointer hover:bg-slate-50 rounded-md" /> :
                                                            <IoIosArrowRoundDown onClick={handleSortDateDsc} className="text-sm cursor-pointer hover:bg-slate-50 rounded-md" />}                                                </div>
                                                </th> */}
                                               {!isRender && <th className=''>Actions</th>}

                                                {/* <th >Registrar</th> */}

                                            </tr>
                                        </thead>
                                        <tbody className='py-2'>
                                            {sales.map((data, index) => (
                                                <tr key={data.id} className={index % 2 === 0 ? 'bg-gray-100 hover:bg-slate-100' : 'bg-white hover:bg-slate-100'}>
                                                    <td className='border-r-2 text-center py-4 flex-wrap'>{index + 1}</td>
                                                    <td className='border-x-2 text-center py-4 flex-wrap'>{data?.id}</td>
                                                    <td className='border-x-2 text-center py-4 flex-wrap'>{data?.contact.name}</td>
                                                    {/* <td className='border-x-2 text-center py-4 h-20 flex-wrap'>{data?.stock.map((item) => {
                                                        return (
                                                            <div key={item?.id}>
                                                                <ol className=''>
                                                                    <li className='my-2'><span className='font-bold'>{item?.inventory?.name} </span> - {item?.manufacturer} -<span className='font-bold'>#</span>{item.quantity} </li>


                                                                </ol>
                                                            </div>
                                                        )
                                                    })}
                                                    </td> */}
                                                     <td className='border-x-2 text-center py-4'>
                                                        <ExpandableProductList
                                                            products={data?.stock.map(item => (  <li className=''><span className='font-bold'>{item?.inventory?.name} </span> - {item?.inventory?.manufacturer} -<span className='font-bold'>#</span>{item.quantity} </li>))}
                                                        />
                                                    </td>
                                                    {/* <td className='border-x-2 text-center py-4 h-20 flex-wrap'>{data?.contact.address}</td> */}
                                                    <td className='border-x-2 text-center py-4 flex-wrap'>{data?.amount.toLocaleString()}</td>
                                                    <td className='border-x-2 text-center py-4 flex-wrap'>{new Date(data?.date_sold).toLocaleDateString()}</td>
                                                    <td className='border-x-2 text-center py-4 flex-wrap'>{`${data?.user?.first_name} ${data?.user?.last_name}`}</td>
                                                    {/* <td className='border-x-2 text-center py-4 h-20 flex-wrap'>{new Date(data?.created_at).toLocaleDateString()}</td> */}
                                                 { !isRender &&  <td className='text-center py-4'>

                                                        <p onClick={() => { showSaleDetail(data) }} className='text-blue-500 text-center font-semibold hover:underline cursor-pointer'>
                                                            view
                                                        </p>

                                                    </td>}
                                                    {/* <td className='flex items-center justify-center h-20 relative'>
                                                    <CiMenuKebab className='cursor-pointer' size={18} onClick={(event) => togglePopUp(event, data.id, data)} />
                                                    {selectedDropdown === data.id && (
                                                        <div ref={popUpRef} className='absolute flex flex-col z-10 gap-2 top-10 right-2 bg-white border rounded shadow-md min-h-max p-2 min-w-max w-20'>
                                                            <div className='cursor-pointer justify-center  hover:bg-slate-50 flex gap-2 items-center'>
                                                                <FaRegEye />
                                                                <p className=' text-xs sm:text-sm ' >
                                                                    View
                                                                </p>
                                                            </div>
                                                            <div onClick={(event) => { handleEditSales(event, data.id) }} className='cursor-pointer justify-center hover:bg-slate-50 flex gap-2 items-center'>
                                                                <GrEdit />
                                                                <p className=' text-xs sm:text-sm' >
                                                                    Edit
                                                                </p>
                                                            </div>

                                                        </div>
                                                    )}
                                                </td> */}
                                                </tr>

                                            ))}
                                        </tbody>
                                    </table>
                                </div>
                                {/* <div className='flex items-center gap-4 mt-4'>
                                    <button onClick={handlePrevPage} disabled={currentPage === 1} className={currentPage === 1 ? 'hidden' : 'text-xs text-blue-600'}>Previous</button>
                                    <div className='flex items-center gap-3'>
                                        {Array.from({ length: lastPage }, (_, i) => (
                                            <button key={i + 1} onClick={() => handlePageClick(i + 1)} className={`text-xs ${currentPage === i + 1 ? 'font-bold' : ''}`}>{i + 1}</button>
                                        ))}
                                    </div>
                                    <button onClick={handleNextPage} disabled={currentPage === lastPage} className={currentPage === lastPage ? 'hidden' : 'text-xs text-blue-600'}>Next</button>
                                </div> */}
                                    <div className='flex items-center gap-4 mt-4'>
                                <button
                                    onClick={handlePrevPage}
                                    disabled={currentPage === 1}
                                    className={currentPage === 1 ? 'text-gray-500 text-xs' : 'text-xs text-blue-600'}
                                >
                                    Previous
                                </button>
                                <div className='flex items-center gap-3'>
                                    {getPageNumbers(currentPage, lastPage).map((pageNumber, index) => (
                                        <React.Fragment key={index}>
                                            {pageNumber === '...' ? (
                                                <span className="text-xs">...</span>
                                            ) : (
                                                <button
                                                    onClick={() => handlePageClick(pageNumber)}
                                                    className={`text-xs ${currentPage === pageNumber ? 'font-bold' : ''}`}
                                                >
                                                    {pageNumber}
                                                </button>
                                            )}
                                        </React.Fragment>
                                    ))}
                                </div>
                                <button
                                    onClick={handleNextPage}
                                    disabled={currentPage === lastPage}
                                    className={currentPage === lastPage ? 'text-gray-500 text-xs' : 'text-xs text-blue-600'}
                                >
                                    Next
                                </button>
                            </div>

                            </div>
                        ) : (
                            <div className="flex flex-col items-center justify-center font-lufga h-[80vh] sm:h-[65vh]">
                                {showbtn ? (hasPermission(roles, ['transactionWriter', 'transactionAdmin']) && (<button onClick={toggleSalesForm} className="bg-blue-500  hover:opacity-90 text-white py-2 px-4 rounded-sm shadow-md mt-4">Add sales</button>))
                                    : <p>Empty list</p>}
                            </div>
                        )}
                        {/* {editModal && (
                            <div className="fixed inset-0 flex items-center justify-center bg-gray-800 bg-opacity-50">
                                <div ref={modalRef} className="bg-white rounded-md min-h-max shadow-lg p-6">
                                    <IoMdClose size={24} onClick={() => setEditModal(false)} className='hover:scale-105 hover:text-red-800 transition duration-150 ease-out cursor-pointer ' />
                                    <EditSale toggle={() => setEditModal(false)} />
                                </div>
                            </div>
                        )} */}

                    </div>)}

                    {showModal && (
                        <div className="fixed inset-0 flex items-center justify-center bg-gray-800 bg-opacity-50 z-10">
                            <div ref={modalRef} className="bg-white rounded-md min-h-max shadow-lg p-4">
                                <IoMdClose size={24} onClick={toggleSalesForm} className='hover:scale-105 hover:text-red-800 transition duration-150 ease-out cursor-pointer ' />
                                <AddSales toggle={toggleSalesForm} fetchSaleTransactions={fetchSaleTransactions} />
                            </div>
                        </div>
                    )}




                </div>
            </div>
                :
                <div className='font-lufga'>

                    <div>
                        <p onClick={() => { setSale_view(false) }} className='flex items-center gap-1 cursor-pointer hover:text-neutral-400'><IoArrowBackCircleOutline size={28} />Go back</p>

                    </div>
                    <div className='my-4 mx-8 '>
                        <p className='lg:text-3xl text-xl font-semibold text-slate-600'>Sale Details</p>
                    </div>
                    <div className='grid lg:grid-cols-4 gap-8 lg:gap-0 sm:border shadow-md rounded-sm'>
                        <div className='col-span-4 lg:col-span-1 lg:border-r   bg-neutral-100'>
                            <div className='flex flex-col gap-2 max-h-[60vh] overflow-auto'>
                                <div className='sticky top-0 z-1 bg-neutral-100 p-2 w-100'>
                                    <p className='text-sm font-lufga w-full border-b '>Sale List</p>
                                    <div>
                                        {/* <input type='text' value={searchQuery} onChange={(e) => { setSearchQuery(e.target.value) }} placeholder='Search Users' className='w-56 sm:w-[150px] focus:outline-none rounded-md shadow-sm border px-2 py-3 h-3 font-lufga text-xs' /> */}
                                    </div>
                                </div>
                                {sales.map((data) => (
                                    <div key={data.id} className={`cursor-pointer px-[5px] hover:bg-slate-200 py-1 ${sale.id === data.id ? 'bg-neutral-200' : ''}`} onClick={() => setSale(data)}>
                                        <p className='text-sm'>{data?.contact?.name}</p>
                                        <p className='text-xs'><span className='font-bold'>Amount: </span>{data?.amount}</p>
                                        <p className='text-xs'>{new Date(data?.created_at).toLocaleDateString()}</p>
                                    </div>
                                ))}

                            </div>
                            <div className='flex items-center gap-4 mt-4'>
                                    <button
                                        onClick={handlePrevPage}
                                        disabled={currentPage === 1}
                                        className={currentPage === 1 ? 'text-gray-500 text-xs' : 'text-xs text-blue-600'}
                                    >
                                        Prev
                                    </button>
                                    <div className='flex items-center gap-3'>
                                        {getPageNumbers(currentPage, lastPage).map((pageNumber, index) => (
                                            <React.Fragment key={index}>
                                                {pageNumber === '...' ? (
                                                    <span className="text-xs">...</span>
                                                ) : (
                                                    <button
                                                        onClick={() => handlePageClick(pageNumber)}
                                                        className={`text-xs ${currentPage === pageNumber ? 'font-bold' : ''}`}
                                                    >
                                                        {pageNumber}
                                                    </button>
                                                )}
                                            </React.Fragment>
                                        ))}
                                    </div>
                                    <button
                                        onClick={handleNextPage}
                                        disabled={currentPage === lastPage}
                                        className={currentPage === lastPage ? 'text-gray-500 text-xs' : 'text-xs text-blue-600'}
                                    >
                                        Next
                                    </button>
                                </div>


                        </div>
                        <div className='col-span-4 lg:col-span-3 p-2 '>
                            <p className='text-sm font-lufga w-full sm:border-b'>Details</p>
                            <div className='max-h-[60vh] overflow-auto'>
                                <SaleDetail toggle={sale_view} data={sale} roles={roles} />

                            </div>

                        </div>

                    </div>
                </div>

            }
        </div>
    );
}

export default SalesInfo;
