import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    roles:sessionStorage.getItem('roles')|| null,
};

export const permissionsSlice = createSlice({
  name: 'permissions',
  initialState,
  reducers: {
    setRoles: (state, action) => {
      // state.roles = Array.isArray(action.payload) ? action.payload : [action.payload];
    state.roles = action.payload;

    },
  },
});

export const { setRoles } = permissionsSlice.actions;

export const selectRoles = (state) => state.auth.roles;

export const hasPermission = (userRoles, requiredRoles) => {
  const permissions = Array.isArray(userRoles) ? userRoles : (typeof userRoles === 'string' ? userRoles.split(',') : [userRoles]);
  return permissions.some(role => requiredRoles.includes(role.trim()));
};

export default permissionsSlice.reducer;