import React, { useEffect, useRef, useState } from 'react';
import { HiOutlineChevronDown } from 'react-icons/hi';
import { useSelector } from 'react-redux';
import axios from 'axios';
import ManagementInfo from './ManagementInfo';
import SalesInfo from '../sales/SalesInfo';
import InventoryInfo from '../inventories/InventoryInfo';
import { api_url } from '../../../config';
import { ColorRing } from 'react-loader-spinner';
import api from '../../../utils/api';

const BusinessInfo = () => {
    const modalRef = useRef();
    const popUpRef = useRef();
    const [modal, setModal] = useState(false);
    const [start_date, setStart_date] = useState('');
    const [end_date, setEnd_date] = useState('');
    const [inventoriesModal, setInventoriesModal] = useState(false);
    const [loader, setLoader] = useState(false);
    const [open, setOpen] = useState(false);
    const [summary, setSummary] = useState([]);
    const { id, branch_id, token } = useSelector((state) => state.auth);

    const fetchSummary = async () => {
        try {
            setLoader(true)
            const response = await api.get(`${api_url}/api/v1/wholesaler/user/${id}/branch/${branch_id}/executive/summary?start_date=${start_date}&end_date=${end_date}`, {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${token}`
                }
            });
            const data = response.data.data;
            setSummary(data);
            setOpen(false)
        } catch (error) {
            console.log(error);
        } finally {
            setLoader(false)
        }
    };

    useEffect(() => {
        fetchSummary();
    }, [id, token]);

    const handleClickOutside = (event) => {
        if (popUpRef.current && popUpRef.current.contains(event.target)) {
            return;
        }
        if (modalRef.current && !modalRef.current.contains(event.target)) {
            setModal(false);
            setInventoriesModal(false);
        }
        if (popUpRef.current && !popUpRef.current.contains(event.target)) {
            setOpen(false);
        }
    };

    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    const togglePopUp = (event) => {
        event.stopPropagation();
        event.preventDefault();
        setOpen(!open);
    };

    const showModal = () => {
        setModal(!modal);
        setOpen(false);
    };

    const showInventories = () => {
        setInventoriesModal(!inventoriesModal);
        setOpen(false);
    };


    return (
        <div>
            <ManagementInfo summary={summary} />
            <div className='grid grid-cols-3 gap-2 my-3 font-lufga'>
                <div className="relative shadow-md bg-gradient-to-tr from-[#5C5470] rounded-md text-white to-[#0F0F0F] p-2 hover:opacity-90">
                    <div className='flex justify-start items-center '>
                        <button className='sm:py-2 w-full h-full text-xs sm:text-base'>Live Stock(Period)</button>
                        <HiOutlineChevronDown onClick={togglePopUp} className='cursor-pointer' />
                    </div>
                    <p className='text-xs text-center'>
                        {start_date || '-'}  to  {end_date || '-'}
                    </p>


                    {open && (
                        <div ref={popUpRef} className="absolute top-10 z-10 right-0 left-2 mt-2 w-32 min-w-max rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none">
                            <div className="py-2 px-4 " role="menu" aria-orientation="vertical" aria-labelledby="options-menu">
                                <div>
                                    <label className="text-sm text-gray-700 font-lufga flex items-center gap-1">
                                        Start Date<span className='font-serif text-red-400'>*</span>
                                    </label>
                                    <input
                                        type="date"
                                        value={start_date}
                                        onChange={(e) => setStart_date(e.target.value)}
                                        className="px-3 py-2 text-sm border text-gray-700  border-gray-300 rounded-md focus:outline-none focus:border-blue-500"
                                    />
                                </div>
                                <div>
                                    <label className="text-sm text-gray-700 font-lufga flex items-center gap-1">
                                        End Date<span className='font-serif text-red-400'>*</span>
                                    </label>
                                    <input
                                        type="date"
                                        value={end_date}
                                        onChange={(e) => setEnd_date(e.target.value)}
                                        className="px-3 py-2 text-sm border border-gray-300 text-gray-700  rounded-md focus:outline-none focus:border-blue-500"
                                    />
                                </div>
                                <button onClick={fetchSummary} className="bg-blue-500 my-2  hover:opacity-90 text-white py-1 px-4 rounded-md shadow-sm text-xs">Submit</button>

                            </div>

                        </div>
                    )}
                </div>
                <button onClick={showModal} className='sm:py-2 hover:opacity-90 text-xs sm:text-base rounded-md shadow-md bg-gradient-to-tr from-[#435B66] text-white to-[#0F0F0F]'>View Sale Transactions</button>
                <button onClick={showInventories} className='sm:py-2 hover:opacity-90 text-xs sm:text-base rounded-md shadow-md bg-gradient-to-tr from-[#0E8388] text-white to-[#0F0F0F]'>View Inventories</button>
            </div>

            <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-4 p-4 font-lufga">
                <div className="bg-blue-50 p-4 hover:bg-blue-100 rounded-md shadow-md">
                    <h2 className="text-slate-600 text-xs sm:text-sm font-bold mb-2">Quantity Received</h2>
                    {loader ? <ColorRing
                        height="40"
                        width="40"
                        ariaLabel="color-ring-loading"
                        wrapperStyle={{}}
                        wrapperClass="color-ring-wrapper"
                        colors={['#e15b64', '#f47e60', '#f8b26a', '#abbd81', '#849b87']}
                    /> : <div>
                        <p className="text-slate-500">Number: <span>{summary?.quantity_received?.number}</span></p>
                        <p className="text-slate-500">Cost: <span>{summary?.quantity_received?.cost}</span></p>
                        <p className="text-slate-500">Sale Target: <span>{summary?.quantity_received?.saleTarget}</span></p>
                    </div>}
                </div>
                <div className="bg-green-50 p-4 hover:bg-green-100 rounded-md shadow-md">
                    <h2 className="text-slate-600 text-xs sm:text-sm font-bold mb-2">Quantity Sold</h2>
                   {loader ?  <ColorRing
                        height="40"
                        width="40"
                        ariaLabel="color-ring-loading"
                        wrapperStyle={{}}
                        wrapperClass="color-ring-wrapper"
                        colors={['#e15b64', '#f47e60', '#f8b26a', '#abbd81', '#849b87']}
                    />: <div>
                    <p className="text-slate-500">Number: <span>{summary?.quantity_sold?.number}</span></p>
                    <p className="text-slate-500">Value: <span>{summary?.quantity_sold?.value}</span></p></div>}
                </div>
                <div className="bg-orange-50 hover:bg-orange-100 p-4 rounded-md shadow-md">
                    <h2 className="text-slate-600 text-xs sm:text-sm font-bold mb-2">Quantity Available</h2>
                   {loader ? <ColorRing
                        height="40"
                        width="40"
                        ariaLabel="color-ring-loading"
                        wrapperStyle={{}}
                        wrapperClass="color-ring-wrapper"
                        colors={['#e15b64', '#f47e60', '#f8b26a', '#abbd81', '#849b87']}
                    /> : <div>
                    <p className="text-slate-500">Number: <span>{summary?.quantity_available?.number}</span></p>
                    <p className="font-bold">
                        Target: <span className={`${summary?.quantity_available?.value < 0 ? 'text-green-600' : 'text-red-600'}`}>
                            {typeof summary?.quantity_available?.value === 'number' ? Math.abs(summary.quantity_available.value).toLocaleString() : 'N/A'}
                        </span>
                    </p></div>}
                </div>
            </div>

            {/* {modal && (
                <div className="fixed inset-0 flex items-center justify-center bg-gray-800 bg-opacity-50">
                    <div ref={modalRef} className="bg-white rounded-md w-full  mx-10 shadow-lg p-4">
                        <SalesInfo toggle={showModal} />
                    </div>
                </div>
            )}
            {inventoriesModal && (
                <div className="fixed inset-0 flex items-center justify-center bg-gray-800 bg-opacity-50">
                    <div ref={modalRef} className="bg-white rounded-md w-full mx-10 shadow-lg p-4">
                        <InventoryInfo toggle={showInventories} />
                    </div>
                </div>
            )} */}
            {modal && (
                <div className="fixed inset-0 flex items-center justify-center bg-gray-800 bg-opacity-50">
                    <div ref={modalRef} className="bg-white rounded-md w-full h-auto max-h-[95vh] mx-4 sm:mx-6 md:mx-10 shadow-lg p-4 overflow-auto">
                        <SalesInfo toggle={showModal} />
                    </div>
                </div>
            )}
            {inventoriesModal && (
                <div className="fixed inset-0 flex items-center justify-center bg-gray-800 bg-opacity-50">
                    <div ref={modalRef} className="bg-white rounded-md w-full h-auto max-h-[95vh] mx-4 sm:mx-6 md:mx-10 shadow-lg p-4 overflow-auto">
                        <InventoryInfo toggle={showInventories} />
                    </div>
                </div>
            )}
        </div>
    );
};

export default BusinessInfo;
