import React from 'react';

const PrintableReceipt = React.forwardRef((props, ref) => {
  const { retailersInput, email, contact, saleitems, discount, calculateGrandTotal, formattedDateTime } = props;


  return (
    <div ref={ref} className="print-content" style={{ width: '80mm', fontFamily: 'monospace', fontSize: '12px', textAlign: 'center' }}>
      <h2 style={{ fontWeight: 'bold', marginBottom: '10px' }}>Receipt</h2>
      <p>Retailer's name: {retailersInput}</p>
      <p>Retailer's email: {email}</p>
      <p>Retailer's contact: {contact}</p>

      <div style={{ borderTop: '1px dashed black', borderBottom: '1px dashed black', margin: '10px 0', padding: '10px 0' }}>
        {saleitems.map((item, index) => (
          <div key={index} style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '5px' }}>
            <span style={{ fontWeight: 'bold', textAlign: 'left', flex: 2 }}>{item.productName}</span>
            <span style={{ textAlign: 'right', flex: 1 }}>Quantity: {item.quantity}</span>
            <span style={{ textAlign: 'right', flex: 1 }}>Price: {item.price}</span>
          </div>
        ))}
      </div>

      <p>discount: {discount}</p>
      <p>total: {calculateGrandTotal()}</p>
      <p>Date: {formattedDateTime}</p>
    </div>
  );
});

export default PrintableReceipt;