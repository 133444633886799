import React from 'react';
import UserInfo from '../../components/Wholesalelib/users/UserInfo';

const Users = () => {
    return (
        <div>
            <UserInfo/>
        </div>
    );
}

export default Users;
