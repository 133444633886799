import React from 'react';
import StocksInfo from '../../components/Wholesalelib/stocks/StocksInfo';

const Stocks = () => {
    return (
       <div>
         <StocksInfo/>
       </div>        
       
    );
}

export default Stocks;
