import React from 'react';
import Tabs from '../../components/Wholesalelib/orders/Tabs';
import OrdersInfo from '../../components/Wholesalelib/orders/OrdersInfo';

const Orders = () => {
    return (
        <div>
           <OrdersInfo/>
        </div>
    );
}

export default Orders;
