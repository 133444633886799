import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { useLocation, useNavigate, useParams } from 'react-router';
import { useOrders } from '../../../context/orderContext';
import { ColorRing } from 'react-loader-spinner';

const OrderList = ({currentOrderId}) => {
    // const orders = useSelector(state => state?.orders); 
    const location = useLocation()
    
   const{orders,loading} = useOrders()
   
    const {id} = useParams()
    const orderId = parseInt(id)
    
    
    const navigate = useNavigate();
  
    return (
      <div className="w-64 min-w-[30vw] lg:min-w-[15vw]  bg-gray-100 max-h-[80vh] overflow-auto p-4 hidden lg:block rounded-md shadow-md">
        <h2 className="text-base font-semibold mb-4">Order List  ({orders.length})</h2>
      {loading && <div className="grid place-items-center w-full ">
                    <ColorRing
                        height="50"
                        width="50"
                        loading={loading} />
                </div>}
        <div className="space-y-2">
          {orders.map(order => (
            <div 
              key={order.id}
              className={`p-3 rounded-lg cursor-pointer transition-colors ${
                order?.id === parseInt(orderId) ? 'bg-green-100 border-[1px] border-green-400' : 'bg-white hover:bg-gray-50'
              }`}
              onClick={() => navigate(`/retail/orders/${order.id}`, { state: { requiredRoles: [ 'orderViewer', 'orderWriter', 'orderAdmin' ] }})}
            >
              <div className="flex items-center space-x-3">
                <div className="w-1/4 h-8 bg-gray-300 rounded-full flex items-center justify-center text-sm font-medium text-gray-600">
                  {order?.wholesaler?.name.toString().slice(0, 2)}
                </div>
                <div className='w-3/4'>
                  <p className="font-medium text-xs">Order #{order?.id}</p>
                  <p className="text-xs text-gray-500">{order?.wholesaler?.name}</p>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    );
  };

export default OrderList;
