import React from 'react';
import { ColorRing } from 'react-loader-spinner';
import { Link } from 'react-router-dom';

const OrderSummary = ({ orderData, onCommentChange, onSubmitOrder,loading }) => {

    console.log(orderData);
    
    return (
        <div className="max-w-2xl mx-auto p-6 bg-gray-100 rounded-lg shadow-md max-h-[70vh] overflow-auto">
            <div className="mb-6">
                <h3 className="text-base font-semibold mb-2">Supplier Details</h3>
                <p className="text-xs">{orderData?.supplier?.name}</p>
                <p className="text-xs">{orderData?.supplier?.address}</p>
                <p className="text-xs">Contact: {orderData?.supplier?.phone}</p>
            </div>

            <div className="mb-6">
                <h3 className="text-base font-semibold mb-2">Order Cart</h3>
                <table className="w-full">
                    <thead>
                        <tr className="border-b text-sm">
                            <th className="text-left py-2">#</th>
                            <th className="text-left py-2">Product Name</th>
                            <th className="text-left py-2">Manufacturer</th>
                            <th className="text-right py-2">Quantity</th>
                        </tr>
                    </thead>
                    <tbody className='text-xs'>
                        {orderData.products.map((product, index) => (
                            <tr key={product?.id} className="border-b">
                                <td className="py-2">{index + 1}.</td>
                                <td className="py-2">{product?.name}</td>
                                <td className="py-2">{product?.manufacturer}</td>
                                <td className="py-2 text-right">{product?.quantity}</td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>

            <div className="mb-2">
                <h3 className="text-base font-semibold mb-2">Add Comment</h3>
                <textarea
                    value={orderData?.comment}
                    onChange={(e) => onCommentChange(e.target.value)}
                    placeholder="Message..."
                    className="w-full p-2 border rounded-md text-xs"
                    rows="3"
                />
            </div>

            <div className="flex justify-between">
                <Link to='/retail/orders/addOrders/select-products'
                    state={{ requiredRoles: ['orderViewer', 'orderWriter', 'orderAdmin'] }}
                >
                    <button
                        className="bg-gray-300  hover:opacity-90 text-black py-1 px-4 rounded-md shadow-sm text-xs">Back</button>
                </Link>
                
                { loading ? <div className="">
                    <ColorRing
                        height="20"
                        width="20"
                        loading={loading} />
                
                </div>:<button
                    onClick={onSubmitOrder}
                    className="bg-green-500  hover:opacity-90 text-white py-1 px-4 rounded-md shadow-sm text-xs">
                    Create order
                </button>}

            </div>
            
        </div>
    );
};

export default OrderSummary;