import React from 'react';
import firstbanner from '../../assets/firstbanner.jpg'
const Banner = () => {
    return (
        <div className='max-w-[2440px] m-auto px-4 py-12 grid sm:grid-cols-2 xl:grid-cols-4 gap-4 mt-4'>

            <div className='rounded-md shadow-md px-6 py-8 '>
                <img alt='/' src={firstbanner} className='w-10 h-10 bg-slate-600 mb-4 rounded-full ' />
                <p className='text-lg font-semibold mb-6'>PharmtriX: Elevating Pharmacy Operations</p>
                <p className='tracking-wide'>PharmtriX empowers you to take control of your pharmacy operations. It is an integrated software
                    solution, used in Whole,Retail, as well as hospital outpatient and inpatient pharmacies.
                </p>
            </div>

            <div className='rounded-md shadow-md px-6 py-8 '>
                <img alt='/' className='w-10 h-10 bg-slate-600 mb-4 rounded-full ' />
                <p className='text-lg font-semibold mb-6'>Efficient Prescription Handling in Retail Pharmacies with Integration</p>
                <p className='tracking-wide'>This integration provides a dependable, repeatable process for dispensing and checking prescription orders in the retail pharmacy.
                </p>
            </div>

            <div className='rounded-md shadow-md px-6 py-8 '>
                <img alt='/' className='w-10 h-10 bg-slate-600 mb-4 rounded-full ' />
                <p className='text-lg font-semibold mb-6'>Driving Efficiency and Insight in Pharmacy Operations: Our Solutions, Your Success</p>
                <p className='tracking-wide'>Our solutions enable you to reduce costs and errors, improve efficiency and gain valuable insights into your pharmacy operations and returns.
                </p>
            </div>

            <div className='rounded-md shadow-md  px-6 py-8 '>
                <img alt='/' className='w-10 h-10 bg-slate-600 mb-4 rounded-full ' />
                <p className='text-lg font-semibold mb-6'>Expert Partnerships, Optimal Pharmacy Performance</p>
                <p className='tracking-wide'>Additionally, our team of experts will partner with you to optimize your pharmacy activities so you can focus on providing excellent care to your customers and patients
                </p>
            </div>

        </div>
    );
}

export default Banner;
