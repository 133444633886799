import React, { useState } from 'react';
import { InfinitySpin } from 'react-loader-spinner';
import { useSelector } from 'react-redux';
import axios from 'axios';
import { api_url } from '../../../config';
import api from '../../../utils/api';

const ResetUserPassword = ({toggle,user}) => {
    const [loading, setLoading] = useState(false)
    const [error, setError] = useState('')
    const [reset_status, setReset_status] = useState('')
    const { token,id,branch_id } = useSelector((state) => state.auth)

     //this is to reset password for a user by an admin
     const resetPassword = async (e) => {
        e.preventDefault();
        setLoading(true);
        try {
            const response = await api.put(
                api_url + `/api/v1/wholesaler/user/${id}/branch/${branch_id}/user-profile/${user.id}/reset-password`,
                {},
                {
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: `Bearer ${token}`
                    }
                }
            );
            // Set a success message to inform the user
            setReset_status("Password reset successfully");
            // Clear the success message after a few seconds
            setTimeout(() => {
                setReset_status("");
                toggle()
            }, 2000);
            

        } catch (error) {
            // If there's an error, extract a user-friendly error message
            setError( "An error occurred while resetting the password.");
        } finally {
            setLoading(false);
        }
    };

 
    return (
        <div>
            <div className="card sm:w-96 bg-neutral text-neutral-content">
                <div className="card-body items-center text-center">
                {loading && ( // Display loader while loading
                    <div className="flex justify-center items-center w-full h-full bg-gradient-to-br from-opacity-40 to-opacity-60 backdrop-blur-sm absolute top-0 left-0">
                        <InfinitySpin color="#008DDA" loading={loading} size={36} />
                    </div>
                )}
                    <h2 className="font-bold">Reset User Password</h2>

                    <div>
                        <p className='text-xs sm:text-sm'>Are you sure you want to reset this user's password?</p>
                    </div>
                    <div className="flex items-center justify-center gap-3 my-2">
                        <button onClick={()=>toggle()} className="bg-gray-500 font-lufga text-white py-1 text-xs sm:text-sm px-4 rounded-md hover:bg-gray-600 shadow-md transition duration-300">Cancel</button>
                        <button onClick={resetPassword} className="bg-blue-500 font-lufga text-white py-1 text-xs sm:text-sm px-4 rounded-md hover:bg-blue-600 shadow-md transition duration-300">YES</button>
                    </div>
                    <div>
                    {reset_status ? <div>
                        <p className="bg-green-300 font-lufga rounded-md shadow-md px-2 py-1 text-xs text-center ">{reset_status}</p>
                    </div> : error ? <div>
                        <p className="bg-red-300 font-lufga rounded-md text-center shadow-md px-2 py-1 text-sm ">
                            {typeof error === 'string' ? error : (
                                Object.keys(error).map((key) => (
                                    Array.isArray(error[key]) ? error[key][0] : error[key]
                                ))
                            )}
                        </p>
                    </div> : null}</div>
                </div>
            </div>
        </div>
    );
}

export default ResetUserPassword;
