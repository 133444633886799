import React from 'react';
import SalesInfo from '../../components/Wholesalelib/sales/SalesInfo';

const Sales = () => {
    return (
        <div>
          <SalesInfo/>
        </div>
    );
}

export default Sales;
