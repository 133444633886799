import React, { useState, useEffect } from 'react';
import { ColorRing } from 'react-loader-spinner';
import api from '../../../utils/api';
import { api_url } from '../../../config';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { hasPermission } from '../../../Redux/permissionSlice';

const PreparingOrderComponent = ({ order, fetchOrder, roles }) => {
    const [products, setProducts] = useState([]);
    const [subtotal, setSubtotal] = useState(0);
    const [discount, setDiscount] = useState(0);
    const [total, setTotal] = useState(0);
    const [loading, setLoading] = useState(false);
    const { id, branch_id, token } = useSelector((state) => state.auth)

    useEffect(() => {
        if (order && order?.order_items) {
            const initialProducts = order?.order_items.map(item => ({
                ...item,
                selectedStocks: [{ stockId: null, quantity: 0 }]
            }));
            setProducts(initialProducts);
        }
    }, [order]);



    useEffect(() => {
        const newSubtotal = products.reduce((sum, product) =>
            sum + product.selectedStocks.reduce((stockSum, stock) => {
                const selectedStock = product.available_stocks.find(s => s.wholesale_stock.id == stock.stockId);
                return stockSum + (stock.quantity * parseFloat(selectedStock?.wholesale_stock.price || 0));
            }, 0), 0);
        setSubtotal(newSubtotal);
        setTotal(newSubtotal - discount);
    }, [products, discount]);

    const handleQuantityChange = (productIndex, stockIndex, newQuantity) => {
        const updatedProducts = [...products];
        updatedProducts[productIndex].selectedStocks[stockIndex].quantity = Math.max(0, parseInt(newQuantity) || 0);
        setProducts(updatedProducts);
    };

    const handleStockChange = (productIndex, stockIndex, newStockId) => {
        const updatedProducts = [...products];
        updatedProducts[productIndex].selectedStocks[stockIndex].stockId = newStockId;
        updatedProducts[productIndex].selectedStocks[stockIndex].quantity = 0;
        setProducts(updatedProducts);
    };

    const addStockInput = (productIndex) => {
        const updatedProducts = [...products];
        updatedProducts[productIndex].selectedStocks.push({ stockId: null, quantity: 0 });
        setProducts(updatedProducts);
    };

    const removeStockInput = (productIndex, stockIndex) => {
        const updatedProducts = [...products];
        if (updatedProducts[productIndex].selectedStocks.length > 1) {
            updatedProducts[productIndex].selectedStocks.splice(stockIndex, 1);
            setProducts(updatedProducts);
        }
    };

    const handleDiscountChange = (newDiscount) => {
        setDiscount(Math.max(0, parseFloat(newDiscount) || 0));
    };

    const calculateProductTotal = (product) => {
        return product.selectedStocks.reduce((total, stock) => {
            const selectedStock = product.available_stocks.find(s => s.wholesale_stock.id == stock.stockId);

            return total + (stock.quantity * parseFloat(selectedStock?.wholesale_stock.price || 0));

        }, 0);
    };

    const UpdateOrder = async (status) => {
        if (!navigator.onLine) {
            toast("You're offline");
            setLoading(false);
            return;
        }


        if (status == 'preparing-order') {
            const data = {
                status,
                discount,
                order_items: products.map(product => ({
                    id: product.id,
                    w_inventory_id: product.w_inventory_id,
                    order_stocks: product.selectedStocks
                        .filter(stock => stock.stockId && stock.quantity > 0)
                        .map(stock => ({
                            id: stock.stockId,
                            w_stock_id: stock.stockId,
                            supply_quantity: stock.quantity
                        }))
                }))
            };

            try {
                setLoading(true)
                await api.put(api_url + `/api/v1/wholesaler/user/${id}/branch/${branch_id}/order/${order.id}`, { data: data }, {
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: `Bearer ${token}`
                    },

                })
                fetchOrder()
            } catch (error) {
                console.log(error);
                toast("An error occured")

            } finally {
                setLoading(false)

            }
        } else if (status == 'cancelled') {

            try {
                setLoading(true)
                await api.put(api_url + `/api/v1/wholesaler/user/${id}/branch/${branch_id}/order/${order.id}`, { data: { status } }, {
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: `Bearer ${token}`
                    },

                })
                fetchOrder()
            } catch (error) {
                console.log(error);
                toast("An error occured")

            } finally {
                setLoading(false)

            }
        }
    }




    console.log(products);

    return (
        <div className='my-5'>
            <div className="max-w-full mx-auto p-4 overflow-auto">
                <div className='max-h-96 overflow-y-auto'>
                    <table className="w-full border-collapse ">
                        <thead>
                            <tr className="border-b">
                                <th className="text-left pb-2">Product Name</th>
                                <th className="text-left pb-2">Manufacturer</th>
                                <th className="text-left pb-2">Stocks Available</th>
                                <th className="text-right pb-2">Demand</th>
                                <th className="text-right pb-2">Quantity</th>
                                <th className="text-right pb-2">Total</th>
                            </tr>
                        </thead>
                        <tbody >
                            {products.map((product, productIndex) => (
                                <tr key={productIndex} className="border-b">
                                    <td className="py-2">{productIndex + 1}. {product.product_name}</td>
                                    <td className="py-2">{product.manufacturer}</td>
                                    <td className="py-2">
                                        {product.selectedStocks.map((selectedStock, stockIndex) => (
                                            <div key={stockIndex} className="flex items-center mb-2">
                                                <select
                                                    className="border rounded px-2 py-1 mr-2 w-32"
                                                    value={selectedStock.stockId || ''}
                                                    onChange={(e) => handleStockChange(productIndex, stockIndex, e.target.value)}
                                                >
                                                    <option value="">Select stock</option>
                                                    {product.available_stocks
                                                        .filter(stock =>
                                                            !product.selectedStocks.some(
                                                                selectedStock => selectedStock.stockId === stock.wholesale_stock.id.toString() &&
                                                                    selectedStock !== product.selectedStocks[stockIndex]
                                                            )
                                                        )
                                                        .map((stock) => (
                                                            <option key={stock.wholesale_stock.id} value={stock.wholesale_stock.id} >
                                                                ID:{stock.wholesale_stock.id},
                                                                Qty: {stock.wholesale_stock.quantity},
                                                                Price: {stock.wholesale_stock.price},
                                                                Exp: {stock.wholesale_stock.expiry_date}
                                                            </option>
                                                        ))
                                                    }
                                                </select>
                                                <input
                                                    type="number"
                                                    value={selectedStock.quantity}
                                                    onChange={(e) => handleQuantityChange(productIndex, stockIndex, e.target.value)}
                                                    className="border rounded w-16 px-2 py-1 text-right mr-2"
                                                />
                                                {stockIndex == product.selectedStocks.length - 1 && (
                                                    <button
                                                        onClick={() => addStockInput(productIndex)}
                                                        className="bg-gray-200 hover:bg-gray-300 rounded-full w-6 h-6 flex items-center justify-center mr-1"
                                                    >
                                                        +
                                                    </button>
                                                )}
                                                {product.selectedStocks.length > 1 && (
                                                    <button
                                                        onClick={() => removeStockInput(productIndex, stockIndex)}
                                                        className="bg-gray-200 hover:bg-gray-300 rounded-full w-6 h-6 flex items-center justify-center"
                                                    >
                                                        -
                                                    </button>
                                                )}
                                            </div>
                                        ))}
                                    </td>
                                    <td className="text-right py-2">{product.demand_quantity}</td>
                                    <td className="text-right py-2">
                                        {product.selectedStocks.reduce((total, stock) => total + stock.quantity, 0)}
                                    </td>
                                    <td className="text-right py-2">
                                        {calculateProductTotal(product).toFixed(2)}
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
                <div className="mt-4 text-right">
                    <div className="flex justify-end items-center">
                        <span className="mr-4">Subtotal</span>
                        <span className="w-24">{subtotal.toFixed(2)}</span>
                    </div>

                    <div className="flex justify-end items-center mt-2">
                        <span className="mr-4">Discount</span>
                        <input
                            type="number"
                            value={discount}
                            onChange={(e) => handleDiscountChange(e.target.value)}
                            className="border rounded w-24 px-2 py-1 text-right"
                        />
                    </div>
                    <div className="flex justify-end items-center mt-4 font-bold">
                        <span className="mr-4">TOTAL</span>
                        <span className="w-24">{total.toFixed(2)}</span>
                    </div>
                </div>
            </div>


            {hasPermission(roles, ['orderWriter', 'orderAdmin']) && (
                <div className='flex justify-end mt-10'>
                    {!loading ? <div className='flex items-center gap-3'>
                        <button onClick={() => { UpdateOrder('cancelled') }} className="bg-gray-200 hover:opacity-90 text-black py-1 px-4 rounded-md shadow-md text-xs">
                            Cancel order
                        </button>
                        <button onClick={() => { UpdateOrder('preparing-order') }} className="bg-blue-500 hover:opacity-90 text-white py-1 px-4 rounded-md shadow-md text-xs">
                            Confirm
                        </button>
                    </div> : <ColorRing height="20" width="20" colors={['#e15b64', '#f47e60', '#f8b26a', '#abbd81', '#849b87']} />}
                </div>
            )}
        </div>
    );
}

export default PreparingOrderComponent;