import React, { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import api from '../../../utils/api';
import { api_url } from '../../../config';
import { VscSend } from "react-icons/vsc";
import { ColorRing } from 'react-loader-spinner';
import { hasPermission } from '../../../Redux/permissionSlice';


const OrderComments = ({order, orderId,roles}) => {
    const [loading, setLoading] = useState(true);
    const [btnLoader, setBtnLoader] = useState(false);
    const [comments, setComments] = useState(null);
    const [newComment, setNewComment] = useState('');
    const {id, token, branch_id} = useSelector((state) => state.auth);
    const messagesEndRef = useRef(null);

    const fetchComments = async () => {
        if (!navigator.onLine) {
            toast("You're offline");
            setLoading(false);
            return;
        }

        try {
            const response = await api.get(api_url + `/api/v1/retailer/user/${id}/branch/${branch_id}/order/${orderId}/comments`, {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${token}`
                },
            });

            const { data, meta } = response.data;
            setComments(data);
        } catch (error) {
            console.log(error);
            toast("Order comments unavailable");
        } 
    };

    useEffect(() => {
        fetchComments();
    }, [orderId,order]);

    useEffect(() => {
        scrollToBottom();
    }, [comments]);

    const scrollToBottom = () => {
        messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
    };

    const handleSendComment = async () => {
        if (!newComment.trim()) return;
        setBtnLoader(true)

        try {
            await api.post(api_url + `/api/v1/retailer/user/${id}/branch/${branch_id}/order/${orderId}/comments`, 
            {
                content: newComment
            },
            {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${token}`
                },
            });
            fetchComments();
            setNewComment('');
        } catch (error) {
            console.log(error);
            toast("Failed to send comment");
        }finally{
            setBtnLoader(false)
        }
    };

    const ChatMessage = ({ comment }) => {
        const isRetail = comment?.model === 'Retail';
        return (
            <div className={`max-w-[70%] mb-5 p-3 rounded-lg ${isRetail ? 'ml-auto bg-green-100' : 'mr-auto bg-gray-100'}`}>
                <div className="font-bold mb-1">
                    <span>{comment?.user?.first_name} {comment?.user?.last_name}</span>
                </div>
                <div className="mb-1">{comment?.content}</div>
                <span className="text-xs text-gray-500 ">{new Date(comment?.created_at).toLocaleString()}</span>
            </div>
        );
    };

    return (
        <div className="flex flex-col max-h-[90vh]">
         
          <div className="h-[90vh] overflow-y-auto p-4">
            {comments?.length == 0 ? <p>No comments available</p> :  <div>
             {comments?.map(comment => (
                    <ChatMessage key={comment.id} comment={comment} />
                ))}
                <div ref={messagesEndRef} />
             </div>}
            </div>
           

            {hasPermission(roles, ['orderWriter', 'orderAdmin']) && (
                <div className="pt-2 bg-white border-t my-4">
                <div className="flex">
                    <textarea
                        className="flex-1 p-2 border rounded-l-lg focus:outline-none "
                        value={newComment}
                        onChange={(e) => setNewComment(e.target.value)}
                        placeholder="Type your comment..."
                    />
                    <button
                        className="px-4 py-2 flex flex-col items-center justify-center bg-green-500 text-white rounded-r-lg hover:bg-green-600 focus:outline-none "
                        onClick={handleSendComment}
                    >
                        {btnLoader ? <ColorRing height="20" width="20" colors={['#e15b64', '#f47e60', '#f8b26a', '#abbd81', '#849b87']} />  :<div>
                            <VscSend size={20}/>
                            <p className='text-[8px]'>Send</p>
                            </div>}
                    </button>
                </div>
            </div>
            )}
        </div>
    );
};

export default OrderComments;