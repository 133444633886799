import { Collapse } from 'antd';
import axios from 'axios';
import React, { useEffect, useRef, useState } from 'react';
import { InfinitySpin } from 'react-loader-spinner';
import { api_url } from '../../../config';
import { useDispatch, useSelector } from 'react-redux';
import { addItem, clearCart, decreaseQuantity, deleteItem, increaseQuantity } from '../../../Redux/SalesSlice';
import { CiCirclePlus } from "react-icons/ci";
import { CiCircleMinus } from "react-icons/ci";
import { FcDeleteDatabase } from 'react-icons/fc';
import { toast } from 'react-toastify';
import api from '../../../utils/api';
import { useReactToPrint } from 'react-to-print';
import PrintableReceipt from './PrintableReceipt';


const AddSales = ({ fetchSaleTransactions }) => {
    const [quantity, setQuantity] = useState()
    const [loading, setLoading] = useState(false)
    const [email, setEmail] = useState('')
    const [address, setAddress] = useState('')
    const [contact, setContact] = useState('')
    const [error, setError] = useState('')
    const [sales_status, setSales_status] = useState('')
    const [items_status, setItems_status] = useState('')
    const [items_error, setItems_error] = useState('')
    const [retailersInput, setRetailersInput] = useState('');
    const [retailersSuggestions, setRetailersSuggestions] = useState([]);
    const [showRetailersSuggestions, setShowRetailersSuggestions] = useState(false);
    const retailersInputRef = useRef(null);
    const [selectedRetailerId, setSelectedRetailerId] = useState(null);
    const [retailers, setRetailers] = useState([])
    const [discount, setDiscount] = useState(parseFloat(0))
    const [comment, setComment] = useState('')


    const [productsInput, setProductsInput] = useState('');
    const [productsSuggestions, setProductsSuggestions] = useState([]);
    const [showProductsSuggestions, setShowProductsSuggestions] = useState(false);
    const productsInputRef = useRef(null);
    const [selectedProductId, setSelectedProductId] = useState(null);
    const [products, setProducts] = useState([])
    const { id, branch_id, token } = useSelector((state) => state.auth);
    const [quantityToSell, setQuantityToSell] = useState(parseFloat(0));
    const [items, setItems] = useState([])
    const [showPrintBtn, setShowPrintBtn] = useState(false)
    const [date_sold, setDate_sold] = useState(false)

    //confirm view
    const [confirm_view, setConfirm_view] = useState(false)

    const now = new Date();
    const formattedDateTime = now.toLocaleString();
    useEffect(() => {
        // Close the suggestions dropdown when clicking outside
        const handleClickOutside = (event) => {
            if (retailersInputRef.current && !retailersInputRef.current.contains(event.target)) {
                setShowRetailersSuggestions(false);
            }
            if (productsInputRef.current && !productsInputRef.current.contains(event.target)) {
                setShowProductsSuggestions(false);
            }
        };
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);



    const fetchRetailers = async () => {

        try {
            const response = await api.get(api_url + `/api/v1/wholesaler/user/${id}/branch/${branch_id}/transaction/retailer`, {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${token}`
                }
            })
            const data = response.data.data
            setRetailers(data)
        } catch (error) {
            console.log(error);

        }
    }



    const handleRetailersInputChange = (e) => {
        const value = e.target.value;
        setRetailersInput(value);

        // Filter retailerss based on the input value
        const filteredSuggestions = retailers.filter(item =>
            item.name && item.name.toLowerCase().includes(value.toLowerCase())
        );


        setRetailersSuggestions(filteredSuggestions);
        setShowRetailersSuggestions(true);
    };



    const handleFocus = () => {
        // Show all retailers on focus
        setRetailersSuggestions(retailers);
        setShowRetailersSuggestions(true);

    };

    const handleSelectRetailers = (selectedRetailer) => {
        setRetailersInput(selectedRetailer.name);
        setSelectedRetailerId(selectedRetailer.id);
        setEmail(selectedRetailer.email)
        setAddress(selectedRetailer.address)
        setContact(selectedRetailer.phone)
        setShowRetailersSuggestions(false);
    };




    const fetchProducts = async () => {

        try {
            const response = await api.get(api_url + `/api/v1/wholesaler/user/${id}/branch/${branch_id}/transaction/inventory`, {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${token}`
                }
            })
            const data = response.data.data
            setProducts(data)
        } catch (error) {
            setError(error?.response?.data?.error)
        }
    }
    console.log('productsn:', products);



    const handleProductsInputChange = (e) => {
        const value = e.target.value;
        setProductsInput(value);

        // Filter retailerss based on the input value
        const filteredProductSuggestions = products.filter(item =>
            item.product_name && item.product_name.toLowerCase().includes(value.toLowerCase())
        );

        // Debugging logs
        console.log('Filtered Suggestions:', filteredProductSuggestions);

        setProductsSuggestions(filteredProductSuggestions);
        setShowProductsSuggestions(true);
    };

    const handleProductFocus = () => {
        // Show all retailers on focus
        setProductsSuggestions(products);
        setShowProductsSuggestions(true);

    };

    const handleSelectProducts = (selectedProduct) => {
        setProductsInput(selectedProduct.product_name + " - " + selectedProduct.manufacturer);
        setSelectedProductId(selectedProduct.id);
        setShowProductsSuggestions(false);
        setItems(selectedProduct.stock)
    };

    useEffect(() => {
        fetchRetailers()
        fetchProducts()
    }, [id, branch_id, token])

    const dispatch = useDispatch();


    const handleAddItem = (item) => {
        const quantity = parseInt(quantityToSell); // Ensure quantity is a valid number
        const unitPrice = parseFloat(item.price); // Ensure price is a valid number
        if (item.quantity < quantity) {
            setError('Quantity to sell is more than quantity left')
            setTimeout(() => {
                setError('');
            }, 3000);
            return
        }

        const saleItem = {
            inventory_id: selectedProductId,
            id: item.id,
            productName: productsInput,
            quantity: quantity,
            unit_price: unitPrice,
            price: (unitPrice * quantity).toFixed(2), // Calculate total price
            expiryDate: item.expiry_date,
        };

        // Check if the item is already in the sale items list
        // const saleitems = useSelector((state) => state.sales);
        const itemExists = saleitems.some(saleItem => saleItem.id === item.id);

        if (itemExists) {
            setError(`Item is already in the sale list`);
            toast.error(`Item is already in the sale list`);
            setTimeout(() => {
                setError('');
            }, 3000);
        } else {
            dispatch(addItem(saleItem));
            setQuantityToSell('');
            setError('');
        }
    };


    const handleDeleteItem = (itemId) => {
        dispatch(deleteItem(itemId));
    };
    const handleIncreaseQuantity = (item) => {
        const onItemExist = items.find(onItem => onItem.id === item.id)
        if (onItemExist.quantity === item.quantity) {
            return
        }
        dispatch(increaseQuantity(item.id));
    };

    const handleDecreaseQuantity = (item) => {
        if (item.quantity === 1) {
            return
        }
        dispatch(decreaseQuantity(item.id));
    };
    const saleitems = useSelector((state) => state.sales)
    console.log(saleitems);


    const handleConfirmView = () => {
        if (!retailersInput) {
            toast.error("Retailer details required")
            return
        }
        if (saleitems.length < 1) {
            toast.error("Cart is empty")
            return
        }
        setShowPrintBtn(false)
        setConfirm_view(!confirm_view)
    }
    const calculateGrandTotal = () => {
        const acc = saleitems.reduce((total, item) => {
            return (total + parseFloat(item.price));
        }, 0);

        return (acc - discount).toFixed(2)
    };

    const handleConfirm = async (e) => {
        e.preventDefault()
        const data = {
            contact_id: selectedRetailerId,
            stock: saleitems,
            discount: parseFloat(discount),
            date_sold,
            total_amount: calculateGrandTotal()
        }
        console.log(data);

        const sale_comment = comment;
        try {
            setLoading(!loading)
            const res = await api.post(api_url + `/api/v1/wholesaler/user/${id}/branch/${branch_id}/transaction`, { data: data }, {
                headers: {
                    "Content-type": "application/json",
                    Authorization: `Bearer ${token}`
                }
            })
            const saleId = res.data.data.id;


            // Send POST request to add sale comment
            if (comment) {
                await api.post(`${api_url}/api/v1/wholesaler/user/${id}/branch/${branch_id}/transaction/${saleId}/comments`, { content: sale_comment }, {
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: `Bearer ${token}`
                    }
                });
            }
            toast.success('Sales added successfully')
            setSales_status('Sales added successfully')
            setShowPrintBtn(true)
            fetchSaleTransactions()
            setTimeout(() => {
                setSales_status('');
            }, 3000);

        } catch (error) {
            toast.error('Adding sales failed')
            setError(error?.response?.data?.error)
            setConfirm_view(false)
            setTimeout(() => {
                setError('');
            }, 6000);


        } finally {
            setLoading(false)
        }

    }

    // const handlePrint = () => {
    //     dispatch(clearCart());
    //     setRetailersInput('');
    //     setAddress('')
    //     setContact('')
    //     setEmail('')
    //     setProductsInput('')

    //     window.print()
    // }

    const printRef = useRef();

    const handlePrint = useReactToPrint({
        content: () => printRef.current,
    });

    // const fetchStockItems = async () => {
    //     try {
    //         const response = await axios.get(api_url + `/api/v1/wholesaler/user/${id}/branch/${branch_id}/transation/stock/${selectedProductId}`, {
    //             headers: {
    //                 "Content-Type": "application/json",
    //                 Authorization: `Bearer ${token}`
    //             }
    //         })
    //         const data = response.data.data
    //         setItems(data)
    //         setItems_status('successful')

    //     } catch (error) {
    //         console.log(error);
    //         setItems_status('failed')

    //     }
    // }

    // useEffect(()=>{
    //  fetchStockItems()
    // },[selectedProductId])




    return (
        <div className='text-xs'>
            {loading && ( // Display loader while loading
                <div className="flex justify-center items-center w-full h-full bg-gradient-to-br from-opacity-40 to-opacity-60 backdrop-blur-sm absolute top-0 left-0">
                    <InfinitySpin color="#008DDA" loading={loading} size={36} />
                </div>
            )}
            {!confirm_view ? <div className=' mx-auto p-4 max-h-[80vh] max-w-[90vw] overflow-auto'>
                <div className=' mb-4'>
                    <p className='font-lufga text-center text-sm font-semibold text-neutral-500'>Create Sale</p>
                </div>
                <div className='flex flex-col lg:flex-row items-start justify-center gap-5'>
                    <div className='border-r flex flex-col gap-3 w-full sm:w-80  h-full px-4'>
                        <p className=''>Retailer Details</p>
                        {/* <div className='flex flex-col gap-2.5'>
                            <label className="text-sm font-lufga flex items-center gap-1">Name<span className='font-serif text-red-400'>*</span></label>
                            <select
                                // value={product}
                                // onChange={(e) => setProduct(e.target.value)}
                                className="px-3 py-2.5 border border-gray-300 rounded-md focus:outline-none focus:border-blue-500"
                                required
                            >
                                <option value="" disabled>Select Product</option>
                                <option value="hospital">XYZ pharmaceuticals</option>
                                <option value="pharmacy">OK pharmaceuticals</option>
                                <option value="insurance provider">YO pharmaceuticals</option>
                            </select>
                        </div> */}
                        <div ref={retailersInputRef} className="relative flex flex-col gap-3">
                            <label className="text-sm font-lufga flex items-center gap-1">Name<span className='font-serif text-red-400'>*</span></label>
                            <input
                                type="text"
                                value={retailersInput}
                                onChange={handleRetailersInputChange}
                                onFocus={handleFocus}
                                className="px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:border-blue-500"
                                placeholder="Type retailers..."
                                required
                            />
                            {showRetailersSuggestions && retailersSuggestions.length > 0 && (
                                <div className="absolute z-10 w-full bg-white border max-h-[150px] overflow-auto border-gray-300 rounded-lg shadow-lg mt-20">
                                    {retailersSuggestions.map((retailer, index) => (
                                        <div
                                            key={index}
                                            className="py-1 px-2 hover:bg-gray-100 cursor-pointer"
                                            onClick={() => handleSelectRetailers(retailer)}
                                        >
                                            <p className='text-xs hover:bg-blue-100 px-1 rounded-md shadow-sm'>
                                                {retailer.name}
                                            </p>
                                        </div>
                                    ))}
                                </div>
                            )}
                        </div>
                        <div className='flex flex-col gap-2.5'>
                            <label className="text-sm font-lufga flex items-center gap-1">Email</label>
                            <input
                                type="text"
                                value={email}
                                placeholder=""
                                className="px-3 py-2 border bg-neutral-200 border-gray-100 rounded-md focus:outline-none focus:border-blue-500"
                                disabled={true}
                            />
                        </div>
                        <div className='flex flex-col gap-2.5'>
                            <label className="text-sm font-lufga flex items-center gap-1">Address</label>
                            <input
                                type="text"
                                value={address}
                                placeholder=""
                                className="px-3 py-2 border bg-neutral-200 border-gray-100  rounded-md focus:outline-none focus:border-blue-500"
                                disabled={true}
                            />
                        </div>
                        <div className='flex flex-col gap-2.5'>
                            <label className="text-sm font-lufga flex items-center gap-1">Contact</label>
                            <input
                                type="text"
                                value={contact}
                                placeholder=""
                                className="px-3 py-2 border bg-neutral-200 border-gray-100 rounded-md focus:outline-none focus:border-blue-500"
                                disabled={true}
                            />
                        </div>

                    </div>
                    <div className='border-r flex flex-col gap-3  h-full px-4'>
                        <p className=''>Sale item</p>
                        {/* <div className='flex flex-col gap-2.5'>
                            <label className="text-sm font-lufga flex items-center gap-1">Product<span className='font-serif text-red-400'>*</span></label>
                            <select
                                // value={product}
                                // onChange={(e) => setProduct(e.target.value)}
                                className="px-3 py-2.5 border border-gray-300 rounded-md focus:outline-none focus:border-blue-500"
                                required
                            >
                                <option value="" disabled>Select Product</option>
                                <option value="hospital">Paracetamol</option>
                                <option value="pharmacy">Vitamin C</option>
                                <option value="insurance provider">Zubes</option>
                            </select>
                        </div> */}
                        <div ref={productsInputRef} className="relative flex flex-col gap-3">
                            <div className='flex flex-col gap-2.5'>
                                <label className="text-sm font-lufga flex items-center gap-1" >Date Sold<span className='font-serif text-red-400'>*</span></label>
                                <input
                                    type="date"
                                    value={date_sold}
                                    onChange={(e) => { setDate_sold(e.target.value) }}
                                    placeholder=""
                                    className="px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:border-blue-500"
                                    required
                                />
                            </div>
                            <label className="text-sm font-lufga flex items-center gap-1">Name<span className='font-serif text-red-400'>*</span></label>
                            <input
                                type="text"
                                value={productsInput}
                                onChange={handleProductsInputChange}
                                onFocus={handleProductFocus}
                                className="px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:border-blue-500"
                                placeholder="Type product..."
                                required
                            />
                            {showProductsSuggestions && productsSuggestions.length > 0 && (
                                <div className="absolute z-10 w-full bg-white border max-h-48  overflow-auto border-gray-300 rounded-lg shadow-lg top-36">
                                    {productsSuggestions.map((product, index) => (
                                        <div
                                            key={index}
                                            className="py-1 px-2 hover:bg-gray-100 cursor-pointer"
                                            onClick={() => handleSelectProducts(product)}
                                        >
                                            <p className='text-xs hover:bg-blue-100 px-1 rounded-md shadow-sm'>
                                                {product?.product_name} - {product?.manufacturer}
                                            </p>
                                        </div>
                                    ))}
                                </div>
                            )}
                        </div>
                        {items.length > 0 ? <div className='w-60 md:w-80  '>
                            <label className="text-sm font-lufga flex items-center gap-1">Stocks available<span className='font-serif text-red-400'>*</span></label>
                            {/* <Collapse accordion>
                                {items.map((item) => (
                                    <Panel header={item.name} key={item.id} className='hover:bg-slate-200 bg-slate-100 flex flex-col '>
                                        <p>price: {item.price}</p>
                                        <p>Amount left: {item.quantity}</p>
                                        <p>expiry date: {item.expiry_date}</p>
                                        <div className='flex flex-col gap-1'>
                                            <div className='flex flex-col gap-2.5 mt-1'>
                                                <label className=" text-xs font-lufga flex items-center gap-1">Quantity to sell<span className='font-serif text-red-400'>*</span></label>
                                                <input
                                                    type="text"
                                                    // value={quantity}
                                                    // onChange={(e) => { setQuantity(e.target.value) }}
                                                    placeholder=""
                                                    className="px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:border-blue-500"
                                                    required
                                                />
                                            </div>
                                            <div className='flex justify-end'>
                                                <button className="bg-blue-500  hover:opacity-90 text-white py-1 px-4 rounded-sm shadow-md text-xs">Add item</button>
                                            </div>
                                        </div>
                                    </Panel>
                                ))}
                            </Collapse> */}
                            <Collapse className='max-h-[200px] overflow-auto' accordion items={items.map((item) => ({
                                key: item.id,
                                label: item.id,
                                children: (
                                    <div>
                                        <p>price: {item.price}</p>
                                        <p>Amount left: {item.quantity}</p>
                                        <p>expiry date: {item.expiry_date}</p>
                                        <div className='flex flex-col gap-2.5 mt-1'>
                                            <label className=" text-xs font-lufga flex items-center gap-1">Quantity to sell<span className='font-serif text-red-400'>*</span></label>
                                            <input
                                                type="number"
                                                value={quantityToSell}
                                                onChange={(e) => { setQuantityToSell(e.target.value) }}
                                                placeholder=""
                                                className="px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:border-blue-500"
                                                required
                                            />
                                        </div>
                                        <div className='flex justify-end mt-2'>
                                            <button onClick={() => handleAddItem(item)} className="bg-blue-500  hover:opacity-90 text-white py-1 px-4 rounded-sm shadow-md text-xs">Add item</button>
                                        </div>
                                    </div>
                                ),
                                className: 'hover:bg-slate-200 bg-slate-100',
                            }))} />
                        </div> :
                            <div className='flex flex-col gap-2.5 w-60 md:w-80'>
                                <label className="text-sm font-lufga flex items-center gap-1">Stocks Available</label>
                                <input
                                    type="text"
                                    placeholder="none"
                                    className="px-3 py-2 border bg-neutral-200 border-gray-100 rounded-md focus:outline-none focus:border-blue-500"
                                    disabled={true}
                                />
                            </div>}
                    </div>




                    <div>
                        <p className='font-bold'>Selected Items</p>
                        <div className='md:w-80 w-full py-4 max-h-[45vh] flex flex-col gap-3 overflow-auto'>
                            {saleitems.map((item, index) => {
                                return (
                                    <div key={index} className='flex items-center w-full justify-around p-2 border shadow-lg rounded-md '>
                                        <div>
                                            <p className='font-bold'>{item.productName}</p>
                                            <p>Quantity: <span className='font-bold'>{item.quantity}</span></p>
                                            <p>Price: <span className='font-bold'>{item.price}</span></p>
                                        </div>
                                        <div>
                                            <div className='flex gap-3 items-center'>
                                                <CiCircleMinus onClick={() => handleDecreaseQuantity(item)} className='hover:bg-slate-200 cursor-pointer rounded-full' size={18} />
                                                <p>{item.quantity}</p>
                                                <CiCirclePlus onClick={() => handleIncreaseQuantity(item)} className='hover:bg-slate-200 cursor-pointer rounded-full' size={18} />
                                            </div>
                                        </div>
                                        <div>
                                            <FcDeleteDatabase onClick={() => handleDeleteItem(item.id)} size={20} className='hover:scale-125 transition duration-150 ease-out cursor-pointer' />
                                        </div>
                                    </div>
                                )
                            })}
                        </div>
                    </div>


                </div>


                <div className='flex flex-col lg:flex-row gap-3 justify-end'>
                    <div className='flex  flex-col gap-1.5 w-50 md:w-80 my-4'>
                        <label className="text-sm font-lufga flex items-center gap-1">Comments</label>
                        <textarea
                            value={comment}
                            onChange={(e) => { setComment(e.target.value) }}
                            type="text"
                            rows={3}
                            placeholder=""
                            className="px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:border-blue-500"
                            required
                        />
                    </div>
                    <div className='flex items-center gap-4'>
                        <div>
                            <p>Total: <span className='font-semibold'>{calculateGrandTotal()}</span> </p>
                            <div className='flex items-center gap-2'>
                                <label className="text-xs font-lufga flex items-center gap-1">discount: </label>
                                <input
                                    type="text"
                                    value={discount}
                                    onChange={(e) => { setDiscount(e.target.value) }}
                                    className="px-3 py-2 border border-gray-300 rounded-md w-10 focus:outline-none focus:border-blue-500"
                                    placeholder=""
                                    required
                                />
                            </div>

                        </div>
                        <button onClick={handleConfirmView} className="bg-blue-500 font-lufga text-white py-2 px-4 rounded-md hover:bg-blue-600 shadow-md transition duration-300">Create sale</button>
                    </div>

                </div>



            </div>
                :
                <div className="card w-96 bg-neutral text-neutral-content">
                    <div className="card-body items-center text-center">
                        <h2 className="font-bold">Confirm sale</h2>
                        <div className='my-2 flex flex-col gap-2 items-center'>
                            <p>Retailer's name: <span className='font-bold'>{retailersInput}</span></p>
                            <p>Retailer's email: <span className='font-bold'>{email}</span></p>
                            <p>Retailer's contact: <span className='font-bold'>{contact}</span></p>
                        </div>

                        <div className='border shadow-lg rounded-md my-3'>
                            {saleitems.map((item, index) => {
                                return (
                                    <div key={index} className='flex items-center w-full justify-around p-2  '>
                                        <div className='flex justify-evenly items-center w-full'>
                                            <p className='font-bold'>{item.productName}</p>
                                            <p>Quantity: <span className='font-bold'>{item.quantity}</span></p>
                                            <p>Price: <span className='font-bold'>{item.price}</span></p>
                                        </div>
                                    </div>
                                )
                            })}
                        </div>
                        <div>
                            <p>discount: {discount}</p>
                            <p>total: {calculateGrandTotal()}</p>
                            <p>Date: {formattedDateTime}</p>
                        </div>
                        <div className="flex items-center justify-center gap-3 my-2">
                            <button onClick={() => { setConfirm_view(!confirm_view) }} className="bg-gray-500 font-lufga text-white py-1 px-4 rounded-md hover:bg-gray-600 shadow-md transition duration-300">back</button>
                            {!showPrintBtn ? <button onClick={handleConfirm} className="bg-blue-500 font-lufga text-white py-1 px-4 rounded-md hover:bg-blue-600 shadow-md transition duration-300">Confirm</button>
                                : <button onClick={handlePrint} className="bg-blue-500 font-lufga text-white py-1 px-4 rounded-md hover:bg-blue-600 shadow-md transition duration-300">Print</button>
                            }
                        </div>
                    </div>
                </div>}
            <div className='flex justify-between items-center'>
                <div>
                    {sales_status ? <div>
                        <p className="bg-green-300 font-lufga rounded-md shadow-md px-2 py-1 text-xs text-center ">{sales_status}</p>
                    </div> : error ? <div>
                        <p className="bg-red-300 font-lufga rounded-md text-center shadow-md px-2 py-1 text-sm ">
                            {typeof error === 'string' ? error : (
                                Object.keys(error).map((key) => (
                                    Array.isArray(error[key]) ? error[key][0] : error[key]
                                ))
                            )}
                        </p>
                    </div> : null}</div>


            </div>
            <div style={{ display: 'none' }}>
                <PrintableReceipt
                    ref={printRef}
                    retailersInput={retailersInput}
                    email={email}
                    contact={contact}
                    saleitems={saleitems}
                    discount={discount}
                    calculateGrandTotal={calculateGrandTotal}
                    formattedDateTime={formattedDateTime}
                />
            </div>
        </div >
    );
}

export default AddSales;
