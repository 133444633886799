import React, { useState } from 'react';
import { BsFillFileEarmarkCheckFill } from "react-icons/bs";
import api from '../../../utils/api';
import { api_url } from '../../../config';
import { toast } from 'react-toastify';
import { useSelector } from 'react-redux';
import { ColorRing } from 'react-loader-spinner';
import axios from 'axios';
import { hasPermission } from '../../../Redux/permissionSlice';

const AwaitingConfirmationComponent = ({ order, fetchOrder ,roles}) => {
    const [loading, setLoading] = useState(false)
    const { id, branch_id, token } = useSelector((state) => state.auth)

    const UpdateOrder = async () => {
        if (!navigator.onLine) {
            toast("You're offline");
            setLoading(false);
            return;
        }
        const data = {
            status: "cancelled"
        }

        try {
            setLoading(true)
            const response = await api.put(api_url + `/api/v1/retailer/user/${id}/branch/${branch_id}/order/${order.id}`, { data: data }, {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${token}`
                },

            })
            fetchOrder()
        } catch (error) {
            console.log(error);
            toast("Error fetching order")

        } finally {
            setLoading(false)
        }
    }


    return (
        <div>
            <div className="w-full mx-auto bg-white shadow-md my-4 max-h-[50vh] rounded-lg overflow-auto">
                <table className="w-full ">
                    <thead className="bg-gray-50">
                        <tr>
                            {/* <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">ID</th> */}
                            <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Product Name</th>
                            <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Manufacturer</th>
                            <th className="px-6 py-3 text-right text-xs font-medium text-gray-500 uppercase tracking-wider">Quantity</th>
                        </tr>
                    </thead>
                    <tbody className="bg-white divide-y divide-gray-200">
                        {order?.order_items?.map((product, index) => (
                            <tr key={product.id}>
                                {/* <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">{product.id}</td> */}
                                <td className="px-6 py-4 whitespace-nowrap text-xs font-medium text-gray-900">{product?.product_name}</td>
                                <td className="px-6 py-4 whitespace-nowrap text-xs text-gray-500">{product?.manufacturer}</td>
                                <td className="px-6 py-4 whitespace-nowrap text-xs text-gray-500 text-right">{product?.demand_quantity}</td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
           
            {hasPermission(roles, ['orderWriter', 'orderAdmin']) && (
               <div className=' flex justify-end'>
               <button onClick={UpdateOrder} className="bg-green-500  hover:opacity-90 text-white py-1 px-4 rounded-md shadow-md text-xs">
                   {loading ? <ColorRing
                       height="20"
                       width="20"
                       ariaLabel="color-ring-loading"
                       wrapperStyle={{}}
                       wrapperClass="color-ring-wrapper"
                       colors={['#e15b64', '#f47e60', '#f8b26a', '#abbd81', '#849b87']}
                   /> : 'Cancel order'}
               </button>

           </div>
            )}
        </div>
    );
}

export default AwaitingConfirmationComponent;
