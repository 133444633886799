import React from 'react';
import { BsFillFileEarmarkCheckFill } from 'react-icons/bs';
import { MdShoppingCart } from 'react-icons/md';

const AwaitingPaymentComponent = () => {
    return (

        <div className="flex justify-center items-center h-full py-6">
            <div className="bg-white rounded-lg shadow-md p-6 text-center max-w-sm">
                <div className="flex justify-center text-4xl mb-4">
                    <BsFillFileEarmarkCheckFill />
                </div>
                <p className="text-gray-700 leading-relaxed">
                    Your invoice has been sent. Payment should be received soon
                </p>
            </div>
        </div>

    );
}

export default AwaitingPaymentComponent;
