"use client";
import { useContext, useEffect, useState } from "react";
import { createContext } from "react";
import { api_url } from '../config';





const AppContext = createContext({
  setTokenData: () => { },
  userInfo: {},
  AllowedPermission: (type) => { return false; },
  getToken: () => { }, logout: () => { },

});

export function DBContext({ children }) {
  const [userInfo, setUserInfo] = useState({});

  function setTokenData(userdata) {

    sessionStorage.setItem('token', JSON.stringify(userdata.token));
    sessionStorage.setItem('subscription_code', userdata.subscription_code);
    sessionStorage.setItem('user_type', userdata.user_type);
    sessionStorage.setItem('user_id', userdata.user_id);
  }

    function setToken(userToken) {
      sessionStorage.setItem('token', JSON.stringify(userToken));

    }

    function getToken() {
      const tokenString = sessionStorage.getItem('token');

      return "Bearer " + JSON.parse(tokenString)
    }

    function logout() {
      sessionStorage.removeItem('token');
      sessionStorage.removeItem('subscription_code');
      sessionStorage.removeItem('user_type');
      sessionStorage.removeItem('user_id');


      window.location.href = '/login'
    }
    function AllowedPermission(type) {
      if (userInfo?.user_access == undefined) return false;
      if (userInfo?.user_access?.toString().toLowerCase() === "all") {
        return true;
      } else {
        return userInfo?.user_access[type]
      }
    }

    async function fetchUserData() {
      const token = sessionStorage.getItem('token');
      if (!token || token == "") {
        return;
      }
      const output = await (await fetch(api_url + '/wholesale/user.php', {
        method: 'POST',
        headers: {
          "Authorization": getToken()
        }
      })).json()
      if (output.status == 200) {
        setUserInfo(output);
      }

    }

    useEffect(() => { fetchUserData() }, [])




    return (
      <AppContext.Provider value={{ AllowedPermission: AllowedPermission, userInfo: userInfo, setTokenData: setTokenData, getToken: getToken, logout: logout }}>
        {children}
      </AppContext.Provider>
    );
  }


export const useDBContext = () => useContext(AppContext);

