import axios from 'axios';
import store from '../store'; 
import { clearAuthData } from '../Redux/AuthSlice';
import { toast } from 'react-toastify';

const api = axios.create();

api.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response && error.response.data.message === "unAuthenticated") {
      store.dispatch(clearAuthData());
      
    //   window.location.href = '/login';
    toast.error('Session expired. Please login again')
      
    }else if(error.response && error.response.data.message === "subscription-expired"){
      store.dispatch(clearAuthData());
      
      // window.location.href = '/';
      toast.error('You do not have access to this tool. Kindly contact your administrator!') 
    }

    return Promise.reject(error);
  }
);

export default api;