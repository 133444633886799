import { configureStore } from '@reduxjs/toolkit';
import authReducer from './Redux/AuthSlice';
import salesReducer from './Redux/SalesSlice';
import permissionsReducer from './Redux/permissionSlice';




const store = configureStore({
  reducer: {
  auth : authReducer,
  sales: salesReducer,
  permissions: permissionsReducer,


  },
});

export default store;
