import React, { useEffect, useRef, useState } from 'react';
import { FaRegEye } from 'react-icons/fa6';
import { ColorRing, InfinitySpin } from 'react-loader-spinner';
import { GrEdit } from "react-icons/gr";
import { CiExport, CiMenuKebab } from "react-icons/ci";
import { IoIosArrowRoundDown, IoIosArrowRoundUp, IoMdClose, IoMdCloseCircle } from 'react-icons/io';
import EditSupplier from '../supplier/EditSupplier';
import AddSupplier from '../supplier/AddSupplier';
import AddStock from './AddStock';
import EditStock from './EditStock';
import { useSelector } from 'react-redux';
import axios from 'axios';
import { api_url } from '../../../config';
import { toast } from 'react-toastify';
import StockDetail from './StockDetail';
import { IoArrowBackCircleOutline } from 'react-icons/io5';
import DeleteStock from './DeleteStock';
import { MdOutlineDeleteForever } from 'react-icons/md';
import { FcSearch } from 'react-icons/fc';
import { hasPermission } from '../../../Redux/permissionSlice';
import { selectRoles } from '../../../Redux/AuthSlice';
import api from '../../../utils/api';
import { getPageNumbers } from '../../../utils/getPageNumbers';
import exportFromJSON from 'export-from-json';

const StocksInfo = () => {
    const [stocks, setStocks] = useState([])
    const [stock, setStock] = useState([])
    const [products, setProducts] = useState([])
    const [error, setError] = useState([])
    const [filteredStocks, setFilteredStocks] = useState([]);
    const [stocks_limit, setStocks_limit] = useState([])
    const [loading, setLoading] = useState(true)
    const [selectedDropdown, setSelectedDropdown] = useState(null);
    const [selectedStock, setSelectedStock] = useState(null);
    const [stockToEdit, setStockToEdit] = useState(null);
    const [stockToDelete, setStockToDelete] = useState(null);
    const [showModal, setShowModal] = useState(false)
    const [editModal, setEditModal] = useState(false)
    const [deleteModal, setDeleteModal] = useState(false)
    const [selectedStockId, setSelectedStockId] = useState()
    const modalRef = useRef();
    const { id, token, branch_id } = useSelector((state) => state.auth)
    const [currentPage, setCurrentPage] = useState(1);
    const [lastPage, setLastPage] = useState();
    const [limit, setLimit] = useState(50)
    const [newLimit, setNewLimit] = useState(50);
    const [stock_view, setStock_view] = useState(false)
    const [activeTab, setActiveTab] = useState('All');
    const [filter, setFilter] = useState('')
    const [filter_status, setFilter_status] = useState('')
    const [sortby, setSortby] = useState('-created_at')
    const [showExpirySort, setShowExpirySort] = useState(false)
    const [showDateSort, setShowDateSort] = useState(false)
    const [showDateReceivedSort, setShowDateReceivedSort] = useState(false)
    const [showQuantityReceivedSort, setShowQuantityReceivedSort] = useState(false)
    const [showQuantitySort, setShowQuantitySort] = useState(false)
    const [showPriceSort, setShowPriceSort] = useState(false)
    const [showProductNameSort, setShowProductNameSort] = useState(false)
    const [showSupplierNameSort, setShowSupplierNameSort] = useState(false)
    const [showManufacturerSort, setShowManufacturerSort] = useState(false)
    const [metadata, setMetadata] = useState('')
    const [search, setSearch] = useState('')
    const [hasSearched, setHasSearched] = useState(false);
    const [searchLoading, setSearchLoading] = useState(false)




    const handleSortExpiryAsc = () => {
        setSortby('expiry_date')
        setShowExpirySort(!showExpirySort)
    }

    const handleSortExpiryDsc = () => {
        setSortby('-expiry_date')
        setShowExpirySort(!showExpirySort)

    }

    const handleSortDateAsc = () => {
        setSortby('created_at')
        setShowDateSort(!showDateSort)
    }

    const handleSortDateDsc = () => {
        setSortby('-created_at')
        setShowDateSort(!showDateSort)

    }
    const handleSortDateReceivedAsc = () => {
        setSortby('date_received')
        setShowDateReceivedSort(!showDateReceivedSort)
    }

    const handleSortDateReceivedDsc = () => {
        setSortby('-date_received')
        setShowDateReceivedSort(!showDateReceivedSort)

    }
    const handleSortQuantityReceivedAsc = () => {
        setSortby('quantity_received')
        setShowQuantityReceivedSort(!showQuantityReceivedSort)
    }

    const handleSortQuantityReceivedDsc = () => {
        setSortby('-quantity_received')
        setShowQuantityReceivedSort(!showQuantityReceivedSort)

    }
    const handleSortQuantityAsc = () => {
        setSortby('quantity')
        setShowQuantitySort(!showQuantitySort)
    }

    const handleSortQuantityDsc = () => {
        setSortby('-quantity')
        setShowQuantitySort(!showQuantitySort)

    }
    const handlePriceSortAsc = () => {
        setSortby('price')
        setShowPriceSort(!showPriceSort)
    }

    const handlePriceSortDsc = () => {
        setSortby('-price')
        setShowPriceSort(!showPriceSort)

    }

    const handleSortProductNameAsc = () => {
        setSortby('product_name')
        setShowProductNameSort(!showProductNameSort)
    }

    const handleSortProductNameDsc = () => {
        setSortby('-product_name')
        setShowProductNameSort(!showProductNameSort)

    }
    const handleSortSupplierNameAsc = () => {
        setSortby('contact_name')
        setShowSupplierNameSort(!showSupplierNameSort)
    }

    const handleSortSupplierNameDsc = () => {
        setSortby('-contact_name')
        setShowSupplierNameSort(!showSupplierNameSort)

    }
    const handleSortManufacturerAsc = () => {
        setSortby('manufacturer')
        setShowManufacturerSort(!showManufacturerSort)
    }

    const handleSortManufacturerDsc = () => {
        setSortby('-manufacturer')
        setShowManufacturerSort(!showManufacturerSort)

    }






    const togglePopUp = (event, index, stock) => {
        event.stopPropagation();
        event.preventDefault();
        if (index === selectedDropdown) {
            setSelectedDropdown(null);
            setSelectedStock(null); // Deselect branch if clicking on the same dropdown
        } else {
            setSelectedDropdown(index);
            setSelectedStock(stock);
        }
    };
    const handleLimitChange = (e) => {
        setNewLimit(parseInt(e.target.value));
    };

    const applyNewLimit = () => {
        setLimit(newLimit);
    };

    const handlePageClick = (pageNumber) => {
        setCurrentPage(pageNumber);
    };


    const handleNextPage = () => {
        if (currentPage < lastPage) {
            setCurrentPage(currentPage + 1);

        }
    };

    const handlePrevPage = () => {
        if (currentPage > 1) {
            setCurrentPage(currentPage - 1);
        }
    };
    useEffect(() => {
        // Function to handle click outside of modal
        const handleClickOutside = (event) => {
            if (modalRef.current && !modalRef.current.contains(event.target)) {
                setShowModal(false);
                setEditModal(false)
                setDeleteModal(false)
            }
        };

        // Attach the event listener
        document.addEventListener('mousedown', handleClickOutside);

        // Cleanup the event listener
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);
    useEffect(() => {
        // Function to handle click outside of modal
        const handleClickOutside = (event) => {
            if (popUpRef.current && !popUpRef.current.contains(event.target)) {
                setSelectedDropdown(null);
            }
        };

        // Attach the event listener
        document.addEventListener('mousedown', handleClickOutside);

        // Cleanup the event listener
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);


    const fetchStocks = async (page, limit, searchQuery) => {
        setLoading(loading)
        if (!navigator.onLine) {
            toast("You're offline");
            setLoading(false);
            return;
        }

        const params = {
            page: page || 1,
            limit: limit || 50,
            sort: sortby,
            [`filter[${filter}]`]: `${filter_status}`,
            search: `${searchQuery || ''}`
        };
        try {
            const response = await api.get(api_url + `/api/v1/retailer/user/${id}/branch/${branch_id}/stock`, {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${token}`
                },
                params

            })

            const data = response.data.data
            const meta = response.data.meta
            setStocks(data)
            setMetadata(meta)
            setFilteredStocks(data);
            setStocks_limit(response.data.meta.total);
            setLastPage(response.data.meta.last_page)



        } catch (error) {
            console.log(error);

        } finally {
            setLoading(false)
        }
    }
    // const fetchInventories = async (page, limit) => {
    //     setLoading(loading)

    //     try {
    //         const response = await axios.get(api_url + `/api/v1/retailer/user/${id}/branch/${branch_id}/inventory`, {
    //             headers: {
    //                 "Content-Type": "application/json",
    //                 Authorization: `Bearer ${token}`
    //             }
    //         })
    //         const data = response.data.data
    //         // setInventories(data)
    //         // setInventories_limit(response.data.meta.total);
    //         // setLastPage(response.data.meta.last_page)

    //     } catch (error) {
    //         console.log(error);

    //     } finally {
    //         setLoading(false)
    //     }
    // }
    const fetchProducts = async () => {

        try {
            const response = await api.get(api_url + `/api/v1/retailer/user/${id}/branch/${branch_id}/inventory/list`, {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${token}`
                }
            })
            const data = response.data.data
            setProducts(data)
        } catch (error) {
            setError(error?.response?.data?.error)
        }
    }

    useEffect(() => {
        fetchStocks(currentPage, limit)
        // fetchInventories()
        fetchProducts()
    }, [id, token, currentPage, limit, filter, filter_status, sortby, loading])

    const popUpRef = useRef();
    const showEditModal = (stockId) => {
        setEditModal(!editModal)
        setSelectedStockId(stockId)
    }
    const toggleStockForm = () => {
        setShowModal(!showModal)
    };

    const handleEditStock = (event, stockId) => {
        if (event) {
            event.preventDefault(); // Prevent default behavior
            event.stopPropagation();
        }
        const stockToEdit = stocks.find(stock => stock.id === stockId);
        if (stockToEdit) {
            setEditModal(true);
            setStockToEdit(stockToEdit);
        }
    };
    const handleDeleteStock = (event, stockId) => {
        if (event) {
            event.preventDefault(); // Prevent default behavior
            event.stopPropagation();
        }
        const stockToDelete = stocks.find(stock => stock.id === stockId);
        if (stockToDelete) {
            setDeleteModal(true);
            setStockToDelete(stockToDelete);
        }
    };

    const switchTab = (value) => {
        setActiveTab(value);
        setLoading(true)
        setHasSearched(false)
        switch (value) {

            case 'lowonstock':
                // filtered = stocks.filter(stock => stock.stock_status === 'low-on-stock');
                setFilter_status('low-on-stock')
                setFilter('stock_status')
                break;
            case 'outofstock':
                setFilter_status('out-of-stock')
                setFilter('stock_status')
                break;
            case 'expiringsoon':
                setFilter('expiry_status')
                setFilter_status('expiring')
                break;
            case 'expired':
                setFilter('expiry_status')
                setFilter_status('expired')
                break;
            default:
                setFilter_status('')
                setFilter('')
        }

    };


    const showStockDetail = (data) => {
        setStock_view(!stock_view)
        setStock(data)
    }

    const handleSearchClick = async (searchQuery) => {
        setHasSearched(true);
        setSearchLoading(!searchLoading)
        try {
            await fetchStocks(1, 50, searchQuery);;
            setHasSearched(true);
        } catch (error) {
            console.log(error);
        } finally {
            setSearchLoading(false)
        }


    };

    const selectedRoles = useSelector(selectRoles);
    const roles = Array.isArray(selectedRoles) ? selectedRoles : selectedRoles.split(',').map(role => role.trim());

    const exportFunc = () => {
        const data = stocks?.map(s => ({
            id: s.id,
            Product_name: s.inventory.name,
            Manufacturer: s.manufacturer,
            Quantity_received: s.quantity_received,
            Quantity_left: s.quantity,
            Unit_price: s.price,
            Date_received: s.date_received,
            Date_created: new Date(s?.created_at).toLocaleDateString(),
            Expiry_date: s.expiry_date



        }))
        const fileName = 'Stocks'
        const exportType = exportFromJSON.types.csv

        exportFromJSON({ data, fileName, fields: ["id", "Product_name", "Manufacturer", "Quantity_received", "Quantity_left", "Unit_price", "Date_received", "Date_created", "Expiry_date"], exportType })

    }



    return (
        <div className='h-full overflow-auto font-lufga'>
            {!stock_view ? <div className=' font-lufga'>
                {loading && ( // Display loader while loading
                    <div className="flex justify-center items-center w-full h-full bg-gradient-to-br from-opacity-40 to-opacity-60 backdrop-blur-sm absolute top-0 left-0">
                        <InfinitySpin color="#059212" loading={loading} size={40} />
                    </div>
                )}
                <div className='mb-2 mx-8 '>
                    <p className='lg:text-3xl text-xl font-semibold text-slate-600'>Stocks ({metadata?.total || '-'})</p>
                </div>
                <div className='flex overflow-auto xl:gap-10 font-lufga mb-3 '>
                    <button
                        className={`text-xs sm:text-sm cursor-pointer py-2 px-4 ${activeTab === 'All'
                            ? 'border-b-2 border-green-500 text-green-500'
                            : 'text-gray-500'
                            }`}
                        onClick={() => switchTab('All')}
                    >
                        All
                    </button>
                    <button
                        className={`text-xs sm:text-sm cursor-pointer py-2 px-4 ${activeTab === 'lowonstock'
                            ? 'border-b-2 border-green-500 text-green-500'
                            : 'text-gray-500'
                            }`}
                        onClick={() => switchTab('lowonstock')}
                    >
                        Low on Stock
                    </button>
                    <button
                        className={`text-xs sm:text-sm cursor-pointer py-2 px-4 ${activeTab === 'outofstock'
                            ? 'border-b-2 border-green-500 text-green-500'
                            : 'text-gray-500'
                            }`}
                        onClick={() => switchTab('outofstock')}
                    >
                        Out of Stock
                    </button>
                    <button
                        className={`text-xs sm:text-sm cursor-pointer py-2 px-4 ${activeTab === 'expiringsoon'
                            ? 'border-b-2 border-green-500 text-green-500'
                            : 'text-gray-500'
                            }`}
                        onClick={() => switchTab('expiringsoon')}
                    >
                        Expiring soon
                    </button>
                    <button
                        className={`text-xs sm:text-sm cursor-pointer py-2 px-4 ${activeTab === 'expired'
                            ? 'border-b-2 border-green-500 text-green-500'
                            : 'text-gray-500'
                            }`}
                        onClick={() => switchTab('expired')}
                    >
                        Expired
                    </button>
                </div>
                <div className='font-lufga'>


                    <div className={stocks.length === 0 ? 'hidden' : 'flex flex-col gap-4 mb-4 sm:flex-row justify-between items-center font-lufga mx-6'}>
                        {currentPage === 1 ? <div className='flex items-center gap-2'>
                            <p className='text-sm'  >List per page</p>
                            <input className='border-[1px] w-16 px-2 text-xs' type="number" id="limit" value={newLimit} onChange={handleLimitChange} />
                            <button className="bg-green-500  hover:opacity-90 text-white py-1 px-4 rounded-md shadow-sm text-xs" onClick={applyNewLimit}>Apply</button>
                        </div> :
                            <div>
                                <p className='text-sm'  >List</p>
                            </div>}
                        {activeTab === 'All' && <div className='flex flex-col sm:flex-row items-center gap-4'>
                            <div className='flex items-center gap-2'>
                                <input type='text' placeholder='Search stock' value={search} onChange={(e) => { setSearch(e.target.value) }} className='w-38 sm:w-[200px] focus:outline-none rounded-md shadow-sm border px-2 py-3 h-3 font-lufga text-xs' />
                                {searchLoading ? <ColorRing
                                    height="20"
                                    width="20"
                                    ariaLabel="color-ring-loading"
                                    wrapperStyle={{}}
                                    wrapperClass="color-ring-wrapper"
                                    colors={['#e15b64', '#f47e60', '#f8b26a', '#abbd81', '#849b87']}
                                /> : <FcSearch onClick={() => { handleSearchClick(search) }} className='hover:scale-110 cursor-pointer' />}
                            </div>
                            <div>
                                {hasPermission(roles, ['stockWriter', 'stockAdmin']) && (
                                    <button onClick={toggleStockForm} className="bg-green-500  hover:opacity-90 text-white py-1 px-4 rounded-md shadow-sm text-xs">Add stock</button>

                                )}
                            </div>
                            <div>
                                <button
                                    onClick={exportFunc}
                                    className="bg-gray-200  hover:opacity-90 text-black py-1 px-4 rounded-md shadow-sm text-xs flex items-center gap-1">
                                    <CiExport />
                                    <p>Export</p>
                                </button>

                            </div>
                        </div>}
                    </div>
                    {!loading && (<div className='w-full '>
                        {stocks?.length > 0 ? (
                            <div>
                                <div className='rounded-lg  shadow-md max-h-[60vh] overflow-auto'>

                                    <table className='w-full text-xs px-2 py-2 table-auto overflow-auto'>
                                        <thead className='bg-green-200 shadow-lg sticky top-0'>
                                            <tr>
                                                <th>No.</th>
                                                <th>ID</th>
                                                <th>
                                                    <div className='flex justify-center items-center'>
                                                        <p>Product name</p>
                                                        {showProductNameSort ? <IoIosArrowRoundUp onClick={handleSortProductNameAsc} className="text-sm cursor-pointer hover:bg-slate-50 rounded-md" /> :
                                                            <IoIosArrowRoundDown onClick={handleSortProductNameDsc} className="text-sm cursor-pointer hover:bg-slate-50 rounded-md" />}
                                                    </div>
                                                </th>
                                                <th>
                                                    <div className='flex justify-center items-center'>
                                                        <p>Supplier name</p>
                                                        {showSupplierNameSort ? <IoIosArrowRoundUp onClick={handleSortSupplierNameAsc} className="text-sm cursor-pointer hover:bg-slate-50 rounded-md" /> :
                                                            <IoIosArrowRoundDown onClick={handleSortSupplierNameDsc} className="text-sm cursor-pointer hover:bg-slate-50 rounded-md" />}
                                                    </div>
                                                </th>
                                                <th>
                                                    <div className='flex justify-center items-center'>
                                                        <p>Manufacturer</p>
                                                        {showManufacturerSort ? <IoIosArrowRoundUp onClick={handleSortManufacturerAsc} className="text-sm cursor-pointer hover:bg-slate-50 rounded-md" /> :
                                                            <IoIosArrowRoundDown onClick={handleSortManufacturerDsc} className="text-sm cursor-pointer hover:bg-slate-50 rounded-md" />}
                                                    </div>
                                                </th>
                                                <th>
                                                    <div className='flex justify-center items-center'>
                                                        <p>Quantity Received</p>
                                                        {showQuantityReceivedSort ? <IoIosArrowRoundUp onClick={handleSortQuantityReceivedAsc} className="text-sm cursor-pointer hover:bg-slate-50 rounded-md" /> :
                                                            <IoIosArrowRoundDown onClick={handleSortQuantityReceivedDsc} className="text-sm cursor-pointer hover:bg-slate-50 rounded-md" />}
                                                    </div>
                                                </th>
                                                <th>
                                                    <div className='flex justify-center items-center'>
                                                        <p>Quantity left</p>
                                                        {showQuantitySort ? <IoIosArrowRoundUp onClick={handleSortQuantityAsc} className="text-sm cursor-pointer hover:bg-slate-50 rounded-md" /> :
                                                            <IoIosArrowRoundDown onClick={handleSortQuantityDsc} className="text-sm cursor-pointer hover:bg-slate-50 rounded-md" />}
                                                    </div>
                                                </th>
                                                <th>
                                                    <div className='flex justify-center items-center'>
                                                        <p>Unit price</p>
                                                        {showPriceSort ? <IoIosArrowRoundUp onClick={handlePriceSortAsc} className="text-sm cursor-pointer hover:bg-slate-50 rounded-md" /> :
                                                            <IoIosArrowRoundDown onClick={handlePriceSortDsc} className="text-sm cursor-pointer hover:bg-slate-50 rounded-md" />}
                                                    </div>
                                                </th>
                                                <th>
                                                    <div className='flex justify-center items-center'>
                                                        <p>Date received</p>
                                                        {showDateReceivedSort ? <IoIosArrowRoundUp onClick={handleSortDateReceivedAsc} className="text-sm cursor-pointer hover:bg-slate-50 rounded-md" /> :
                                                            <IoIosArrowRoundDown onClick={handleSortDateReceivedDsc} className="text-sm cursor-pointer hover:bg-slate-50 rounded-md" />}
                                                    </div>
                                                </th>
                                                <th>
                                                    <div className='flex justify-center items-center'>
                                                        <p>Date created</p>
                                                        {showDateSort ? <IoIosArrowRoundUp onClick={handleSortDateAsc} className="text-sm cursor-pointer hover:bg-slate-50 rounded-md" /> :
                                                            <IoIosArrowRoundDown onClick={handleSortDateDsc} className="text-sm cursor-pointer hover:bg-slate-50 rounded-md" />}
                                                    </div>
                                                </th>
                                                <th>
                                                    <div className='flex justify-center items-center'>
                                                        <p>Expiry Date</p>
                                                        {showExpirySort ? <IoIosArrowRoundUp onClick={handleSortExpiryAsc} className="text-sm cursor-pointer hover:bg-slate-50 rounded-md" /> :
                                                            <IoIosArrowRoundDown onClick={handleSortExpiryDsc} className="text-sm cursor-pointer hover:bg-slate-50 rounded-md" />}
                                                    </div>
                                                </th>
                                                <th>Actions</th>
                                            </tr>
                                        </thead>
                                        <tbody className='py-2'>
                                            {stocks.map((data, index) => (
                                                <tr key={data.id} onClick={() => { showStockDetail(data) }} className={index % 2 === 0 ? 'bg-gray-100 hover:bg-slate-200' : 'bg-white hover:bg-slate-200'}>
                                                    <td className='border-r-2 text-center py-4 h-20'>{index + 1}</td>
                                                    <td className='border-x-2 text-center py-4 h-20'>{data?.id}</td>
                                                    <td className='border-x-2 text-center py-4 h-20'>{data?.inventory?.name}</td>
                                                    <td className='border-x-2 text-center py-4 h-20'>{data?.contact?.name}</td>
                                                    <td className='border-x-2 text-center py-4 h-20'>{data?.manufacturer}</td>
                                                    <td className='border-x-2 text-center py-4 h-20'>{data?.quantity_received}</td>
                                                    <td className='border-x-2 text-center py-4 h-20'>{data?.quantity}</td>
                                                    <td className='border-x-2 text-center py-4 h-20'>{data?.price}</td>
                                                    <td className='border-x-2 text-center py-4 h-20'>{new Date(data?.date_received).toLocaleDateString()}</td>
                                                    <td className='border-x-2 text-center py-4 h-20'>{new Date(data?.created_at).toLocaleDateString()}</td>
                                                    <td className='border-x-2 text-center py-4 h-20'>{new Date(data?.expiry_date).toLocaleDateString()}</td>
                                                    <td className='flex items-center justify-center h-20 relative'>
                                                        <CiMenuKebab className='cursor-pointer' size={18} onClick={(event) => togglePopUp(event, data.id, data)} />
                                                        {selectedDropdown === data.id && (
                                                            <div ref={popUpRef} className='absolute flex flex-col z-10 gap-2 top-10 right-2 bg-white border rounded shadow-md min-h-max p-2 min-w-max w-20'>
                                                                <div className='cursor-pointer justify-center hover:bg-slate-50 flex gap-2 items-center'>
                                                                    <FaRegEye />
                                                                    <p className='text-xs sm:text-sm'>View</p>
                                                                </div>
                                                                {hasPermission(roles, ['stockWriter', 'stockAdmin']) && (
                                                                    <div onClick={(event) => { handleEditStock(event, data.id) }} className='cursor-pointer justify-center hover:bg-slate-50 flex gap-2 items-center'>
                                                                        <GrEdit />
                                                                        <p className='text-xs sm:text-sm'>Edit</p>
                                                                    </div>
                                                                )}
                                                                {hasPermission(roles, ['stockAdmin']) && (
                                                                    <div onClick={(event) => { handleDeleteStock(event, data.id) }} className='cursor-pointer justify-center hover:bg-slate-50 flex gap-2 items-center'>
                                                                        <MdOutlineDeleteForever />
                                                                        <p className='text-xs sm:text-sm'>Delete</p>
                                                                    </div>
                                                                )}
                                                            </div>
                                                        )}
                                                    </td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>
                                </div>
                                {/* <div className='flex items-center gap-4 mt-4'>
                                    <button onClick={handlePrevPage} disabled={currentPage === 1} className={currentPage === 1 ? 'hidden' : 'text-xs text-green-600'}>Previous</button>
                                    <div className='flex items-center gap-3'>
                                        {Array.from({ length: lastPage }, (_, i) => (
                                            <button key={i + 1} onClick={() => handlePageClick(i + 1)} className={`text-xs ${currentPage === i + 1 ? 'font-bold' : ''}`}>{i + 1}</button>
                                        ))}
                                    </div>
                                    <button onClick={handleNextPage} disabled={currentPage === lastPage} className={currentPage === lastPage ? 'hidden' : 'text-xs text-green-600'}>Next</button>
                                </div> */}
                                <div className='flex items-center gap-4 mt-4'>
                                    <button
                                        onClick={handlePrevPage}
                                        disabled={currentPage === 1}
                                        className={currentPage === 1 ? 'text-gray-500 text-xs' : 'text-xs text-green-600'}
                                    >
                                        Previous
                                    </button>
                                    <div className='flex items-center gap-3'>
                                        {getPageNumbers(currentPage, lastPage).map((pageNumber, index) => (
                                            <React.Fragment key={index}>
                                                {pageNumber === '...' ? (
                                                    <span className="text-xs">...</span>
                                                ) : (
                                                    <button
                                                        onClick={() => handlePageClick(pageNumber)}
                                                        className={`text-xs ${currentPage === pageNumber ? 'font-bold' : ''}`}
                                                    >
                                                        {pageNumber}
                                                    </button>
                                                )}
                                            </React.Fragment>
                                        ))}
                                    </div>
                                    <button
                                        onClick={handleNextPage}
                                        disabled={currentPage === lastPage}
                                        className={currentPage === lastPage ? 'text-gray-500 text-xs' : 'text-xs text-green-600'}
                                    >
                                        Next
                                    </button>
                                </div>

                            </div>
                        ) : (
                            <div>
                                {hasSearched ? <div className="flex flex-col items-center justify-center font-lufga h-[80vh] sm:h-[65vh]">
                                    <p className="text-gray-500 text-center text-xs">No stocks found</p>
                                    <button onClick={() => { handleSearchClick() }} className="bg-green-500 hover:opacity-90 text-white py-2 px-4 rounded-sm shadow-md mt-4">{searchLoading ? <ColorRing
                                        height="20"
                                        width="20"
                                        ariaLabel="color-ring-loading"
                                        wrapperStyle={{}}
                                        wrapperClass="color-ring-wrapper"
                                        colors={['#e15b64', '#f47e60', '#f8b26a', '#abbd81', '#849b87']}
                                    /> : "Go back"}</button>
                                </div> : <div className="flex flex-col items-center justify-center font-lufga h-[80vh] sm:h-[65vh]">
                                    {activeTab === "All" ? (hasPermission(roles, ['stockWriter', 'stockAdmin']) && (<button onClick={toggleStockForm} className="bg-green-500  hover:opacity-90 text-white py-2 px-4 rounded-sm shadow-md mt-4">Add Stocks</button>))
                                        : <p>empty list</p>}
                                </div>}
                            </div>
                        )}
                        {editModal && (
                            <div className="fixed inset-0 flex items-center justify-center bg-gray-800 bg-opacity-50">
                                <div ref={modalRef} className="bg-white rounded-md min-h-max shadow-lg p-6">
                                    <IoMdClose size={24} onClick={() => setEditModal(false)} className='hover:scale-105 hover:text-red-800 transition duration-150 ease-out cursor-pointer ' />
                                    <EditStock toggle={() => setEditModal(false)} fetchStocks={fetchStocks} data={stockToEdit} products={products} />
                                </div>
                            </div>
                        )}
                        {deleteModal && (
                            <div className="fixed inset-0 flex items-center justify-center bg-gray-800 bg-opacity-50">
                                <div ref={modalRef} className="bg-white rounded-md min-h-max shadow-lg p-6">
                                    <IoMdCloseCircle size={24} onClick={() => setDeleteModal(false)} className='hover:scale-105 hover:text-red-800 transition duration-150 ease-out cursor-pointer ' />
                                    <DeleteStock toggle={() => setDeleteModal(false)} data={selectedStock} fetchStocks={fetchStocks} />
                                </div>
                            </div>
                        )}

                    </div>)}

                    {showModal && (
                        <div className="fixed inset-0 flex items-center justify-center bg-gray-800 bg-opacity-50">
                            <div ref={modalRef} className="bg-white rounded-md min-h-max max-h-[90vh] overflow-auto shadow-lg p-4">
                                <IoMdClose size={24} onClick={toggleStockForm} className='hover:scale-105 hover:text-red-800 transition duration-150 ease-out cursor-pointer ' />
                                <AddStock toggle={toggleStockForm} fetchStocks={fetchStocks} products={products} />
                            </div>
                        </div>
                    )}
                </div>
            </div> :
                <div className='font-lufga'>

                    <div>
                        <p onClick={() => { setStock_view(false) }} className='flex items-center gap-1 cursor-pointer hover:text-neutral-400'><IoArrowBackCircleOutline size={28} />Go back</p>

                    </div>
                    <div className='my-4 mx-8 '>
                        <p className='lg:text-3xl text-xl font-semibold text-slate-600'>Stock Details</p>
                    </div>
                    <div className='grid lg:grid-cols-4 gap-8 lg:gap-0 sm:border shadow-md rounded-sm'>
                        <div className='col-span-4 lg:col-span-1 lg:border-r   bg-neutral-100'>
                            <div className='flex flex-col gap-2 max-h-[60vh] overflow-auto'>
                                <div className='sticky top-0 z-1 bg-neutral-100 p-2 w-100'>
                                    <p className='text-sm font-lufga w-full border-b '>Stock List</p>
                                    <div>
                                        {/* <input type='text' value={searchQuery} onChange={(e) => { setSearchQuery(e.target.value) }} placeholder='Search Users' className='w-56 sm:w-[150px] focus:outline-none rounded-md shadow-sm border px-2 py-3 h-3 font-lufga text-xs' /> */}
                                    </div>
                                </div>
                                {stocks.map((data) => (
                                    <div key={data.id} className={`cursor-pointer px-[5px] hover:bg-slate-200  ${stock.id === data.id ? 'bg-neutral-200' : ''}`} onClick={() => setStock(data)}>
                                        <p className='text-sm'>{data?.inventory?.name}</p>
                                        <p className='text-xs font-bold'>{data?.manufacturer} </p>
                                    </div>
                                ))}

                            </div>
                            {/* <div className="sticky bottom-0 left-0 right-0 bg-neutral-100 z-10 p-2">
                                <div className='flex items-center gap-1'>
                                    <button onClick={handlePrevPage} disabled={currentPage === 1} className={currentPage === 1 ? 'hidden' : 'text-xs text-blue-600'}>Prev</button>
                                    <div className='flex items-center gap-1'>
                                        {Array.from({ length: lastPage }, (_, i) => (
                                            <button key={i + 1} onClick={() => handlePageClick(i + 1)} className={`text-xs ${currentPage === i + 1 ? 'font-bold' : ''}`}>{i + 1}</button>
                                        ))}
                                    </div>
                                    <button onClick={handleNextPage} disabled={currentPage === lastPage} className={currentPage === lastPage ? 'hidden' : 'text-xs text-blue-600'}>Next</button>
                                </div>
                            </div> */}
                             <div className='flex items-center gap-4 mt-4'>
                                    <button
                                        onClick={handlePrevPage}
                                        disabled={currentPage === 1}
                                        className={currentPage === 1 ? 'text-gray-500 text-xs' : 'text-xs text-green-600'}
                                    >
                                        Prev
                                    </button>
                                    <div className='flex items-center gap-3'>
                                        {getPageNumbers(currentPage, lastPage).map((pageNumber, index) => (
                                            <React.Fragment key={index}>
                                                {pageNumber === '...' ? (
                                                    <span className="text-xs">...</span>
                                                ) : (
                                                    <button
                                                        onClick={() => handlePageClick(pageNumber)}
                                                        className={`text-xs ${currentPage === pageNumber ? 'font-bold' : ''}`}
                                                    >
                                                        {pageNumber}
                                                    </button>
                                                )}
                                            </React.Fragment>
                                        ))}
                                    </div>
                                    <button
                                        onClick={handleNextPage}
                                        disabled={currentPage === lastPage}
                                        className={currentPage === lastPage ? 'text-gray-500 text-xs' : 'text-xs text-green-600'}
                                    >
                                        Next
                                    </button>
                                </div>

                        </div>
                        <div className='col-span-4 lg:col-span-3 p-2 '>
                            <p className='text-sm font-lufga w-full sm:border-b'>Details</p>
                            <div className='max-h-[60vh] overflow-auto'>
                                <StockDetail roles={roles} toggle={stock_view} data={stock} products={products} fetchStocks={() => fetchStocks(currentPage, limit, '', () => { })} />

                            </div>

                        </div>

                    </div>
                </div>
            }
        </div>
    );
}

export default StocksInfo;
