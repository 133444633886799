import React from 'react';
import { HiOutlineMenuAlt3, HiOutlineX } from 'react-icons/hi';
import Sidebar from '../../components/Wholesalelib/Sidebar';
import { FaUser } from 'react-icons/fa';
import { IoBagHandle, IoPieChart, IoPeople, IoCart, IoWarningOutline, IoSaveOutline, IoScanCircle, IoTimerOutline, } from 'react-icons/io5'
import { FcExpired } from 'react-icons/fc';
import {IoIosHelpCircleOutline} from 'react-icons/io'
import Summary from './Summary';
import BusinessInfo from '../../components/Wholesalelib/executive_dashboard/BusinessInfo';
import AccountsTable from '../../components/Wholesalelib/executive_dashboard/AccountsTable';

const Dashboard = () => {
    return (
       <div className=''>
       <BusinessInfo/>
       {/* <AccountsTable/> */}
       </div>
    );
}

export default Dashboard;
