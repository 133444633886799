
export function getPageNumbers(currentPage,lastPage) {
    const pageNumbers = [];
    const totalDisplayed = 7; // Total number of page buttons to display
    const sideButtons = 2; // Number of buttons to show on each side of the current page

    if (lastPage <= totalDisplayed) {
        // If total pages are less than or equal to totalDisplayed, show all pages
        for (let i = 1; i <= lastPage; i++) {
            pageNumbers.push(i);
        }
    } else {
        // Always show first page
        pageNumbers.push(1);

        if (currentPage > sideButtons + 2) {
            pageNumbers.push('...');
        }

        // Calculate start and end of the current range
        let rangeStart = Math.max(2, currentPage - sideButtons);
        let rangeEnd = Math.min(lastPage - 1, currentPage + sideButtons);

        // Adjust range if at the start or end
        if (currentPage <= sideButtons + 2) {
            rangeEnd = totalDisplayed - 2;
        } else if (currentPage >= lastPage - sideButtons - 1) {
            rangeStart = lastPage - totalDisplayed + 3;
        }

        for (let i = rangeStart; i <= rangeEnd; i++) {
            pageNumbers.push(i);
        }

        if (currentPage < lastPage - sideButtons - 1) {
            pageNumbers.push('...');
        }

        // Always show last page
        pageNumbers.push(lastPage);
    }

    return pageNumbers;
}
