import React from 'react';
import InventoryInfo from '../../components/Retaillib/inventories/InventoryInfo';

const Inventory = () => {
    return (
        <div className='max-h-screen overflow-auto'>
            <InventoryInfo/>
            {/* <InventoryForm/> */}
        </div>
    );
}

export default Inventory;
