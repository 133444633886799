import React from 'react';
import SignUpForm from '../components/SignUpForm';
import HomeNav from '../components/LandingPagelib/HomeNav';

const SignUp = () => {
    return (
        <div>
            <HomeNav/>
           <SignUpForm/> 
        </div>
    );
}

export default SignUp;
