import React from 'react';
import { useSelector } from 'react-redux';
import wlogo from './../../assets/wlogo.png'

const Profile = ({user}) => {

    
    return (
      
           <div className='flex justify-center items-center h-[90vh] font-lufga'>
            <div className="w-full max-w-sm rounded-lg shadow-lg bg-gradient-to-br border border-blue-800 from-blue-100 to-blue-400 p-3">

                <div className="flex flex-col gap-4 items-center pb-10">
                    <img
                        className="w-24 h-24 mb-3 rounded-full shadow-lg object-cover"
                        src={wlogo}
                        alt=""
                    />
                    <h5 className="mb-1 text-xl font-medium text-gray-900 ">{`${user?.title}. ${user?.first_name} ${user?.last_name}`}</h5>
                    <span className="text-base text-gray-900 ">Position: {user?.position}</span>
                    <span className="text-base text-gray-900 ">Email: {user?.email}</span>
                    <span className="text-base text-gray-900 ">Contact: {user?.phone}</span>
                    <span className="text-base text-gray-900 ">Username: {user?.username}</span>
                    
                </div>
            </div>
        </div>
        
    );
}

export default Profile;
