import React from 'react';
import HomeNav from '../components/LandingPagelib/HomeNav';
import Hero from '../components/LandingPagelib/Hero';
import Banner from '../components/LandingPagelib/Banner';
import Services from '../components/LandingPagelib/Services';
import Contact from '../components/LandingPagelib/Contact';
import Map from '../components/LandingPagelib/Map';
import Footer from '../components/LandingPagelib/Footer';

const Home = () => {
    return (
        <div>
            <HomeNav/>
            <Hero/>
            <Banner/>
            <Services/>
            <Contact/>
            <Footer/>
        </div>
    );
}

export default Home;
