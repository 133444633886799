import React from 'react';
import BusinessInfo from '../../components/Wholesalelib/executive_dashboard/BusinessInfo';
import SummaryInfo from '../../components/Wholesalelib/SummaryInfo';

const Summary = () => {
    return (
        <div className='overflow-auto'>
            <p className='text-2xl font-lufga font-bold text-center  border-b text-gray-600'>Summary</p>
            <SummaryInfo/>
            {/* <ChartSummary/> */}
        </div>
    );
}

export default Summary;
