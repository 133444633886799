import React from "react";
import { FcCancel } from "react-icons/fc";

const CancelledOrderComponent = () => {
  return (
    <div className="grid place-items-center h-[40vh] ">
      <div className="flex flex-col justify-center items-center">
        <FcCancel size={78} />
        <p> This order has been cancelled</p>
      </div>
    </div>
  );
};

export default CancelledOrderComponent;
