import React from 'react';
import PendingComponent from './PendingComponent';
import AwaitingConfirmationComponent from './AwaitingConfirmationComponent';
import PreparingOrderComponent from './PreparingOrderComponent';
import AwaitingPaymentComponent from './AwaitingPaymentComponent';
import PaidAwaitingShippingComponent from './PaidAwaitingShippingComponent';
import ShippedComponent from './ShippedComponent';
import ReceivedComponent from './ReceivedComponent';
import ConfirmOrderComponent from './ConfirmOrderComponent';
import CancelledOrderComponent from './CancelledOrderComponent';


    const OrderDetails = ({orderStatus,order,fetchOrder,roles}) => {
        // const orderDetails = useSelector(state => state.order.details);
      
        const renderStatusComponent = () => {
            switch (orderStatus) {
                case 'pending':
                  return <AwaitingConfirmationComponent order={order} fetchOrder={fetchOrder} roles={roles}/>;
                case 'awaiting-confirmation':
                  return <PreparingOrderComponent order={order} fetchOrder={fetchOrder} roles={roles} />;
                case 'preparing-order':
                  return <AwaitingPaymentComponent order={order} fetchOrder={fetchOrder} roles={roles}/>;
                case 'awaiting-payment':
                return <ConfirmOrderComponent order={order} fetchOrder={fetchOrder} roles={roles}/>;
                case 'paid-awaiting-shipping':
                  return <PaidAwaitingShippingComponent order={order} fetchOrder={fetchOrder} roles={roles}/>;    
                case 'shipped':
                  return <ShippedComponent order={order} fetchOrder={fetchOrder} roles={roles}/>;    
                case 'received':
                  return <ReceivedComponent order={order} fetchOrder={fetchOrder} roles={roles}/>;
                  case 'cancelled':
                    return <CancelledOrderComponent fetchOrder={fetchOrder} roles={roles} />;
                default:
                  return <PendingComponent order={order} fetchOrder={fetchOrder} roles={roles}/>;
              }
        };
      
        return (
          <div>
            {renderStatusComponent()}
          </div>
        );
      };

export default OrderDetails;
