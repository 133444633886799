import React, { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import axios from 'axios';
import { InfinitySpin } from 'react-loader-spinner';
import { Switch } from 'antd';
import { GrEdit } from 'react-icons/gr';
import { MdClose, MdHistoryToggleOff, MdLockReset } from "react-icons/md";
import { FcCancel } from 'react-icons/fc';
import { hasPermission } from '../../../Redux/permissionSlice';
import { api_url } from '../../../config';
import StatusHistory from './StatusHistory';
import ResetUserPassword from './ResetUserPassword';
import api from '../../../utils/api';


const UserDetails = ({ user, item, fetchUsers, users,roles }) => {


    const [firstname, setFirstname] = useState(user?.first_name)
    const [lastname, setLastname] = useState(user?.last_name)
    const [username, setUsername] = useState(user?.username)
    const [email, setEmail] = useState(user?.email)
    const [title, setTitle] = useState(user?.title)
    const [position, setPosition] = useState(user?.position)
    const [permissions, setPermissions] = useState('')
    const [password, setPassword] = useState('')
    const [confirm_password, setConfirm_password] = useState('')
    const [phone, setPhone] = useState(user?.phone)
    const [error, setError] = useState(null)
    const [status, setStatus] = useState('')
    const [status_history, setStatus_history] = useState(false)
    const [resetPasswordModal, setResetPasswordModal] = useState(false)
    const [userStatus, setUserStatus] = useState(user?.status)
    const [loading, setLoading] = useState(false)
    const allPermissions = ['Summary', 'Orders', 'Sales', 'Retailers', 'Suppliers', 'Stocks', 'Inventories'];
    const [selectedPermissions, setSelectedPermissions] = useState(
        user?.roles || []
    );

    useEffect(() => {
        setSelectedPermissions(user?.roles || []);
    }, [user?.roles]);
    const permissionCategories = [
       
        {
            label: "Supplier Management",
            permissions: [
                { value: "supplierViewer", label: "View Suppliers" },
                { value: "supplierWriter", label: "Manage Suppliers" },
                { value: "supplierAdmin", label: "Administer Suppliers" },
            ],
        },
        {
            label: "Retailer Management",
            permissions: [
                { value: "retailerViewer", label: "View Retailers" },
                { value: "retailerWriter", label: "Manage Retailers" },
                { value: "retailerAdmin", label: "Administer Retailers" },
            ],
        },
        {
            label: "Order Management",
            permissions: [
                { value: "orderViewer", label: "View Orders" },
                { value: "orderWriter", label: "Manage Orders" },
                { value: "orderAdmin", label: "Administer Orders" },
            ],
        },
        {
            label: "Inventory Management",
            permissions: [
                { value: "inventoryViewer", label: "View Inventories" },
                { value: "inventoryWriter", label: "Manage Inventories" },
                { value: "inventoryAdmin", label: "Administer Inventories" },
            ],
        },
        {
            label: "Stock Management",
            permissions: [
                { value: "stockViewer", label: "View Stocks" },
                { value: "stockWriter", label: "Manage Stocks" },
                { value: "stockAdmin", label: "Administer Stocks" },
            ],
        },
        {
            label: "Transaction Management",
            permissions: [
                { value: "transactionViewer", label: "View Transactions" },
                { value: "transactionWriter", label: "Manage Transactions" },
            ],
        },
        {
            label: "Other Permissions",
            permissions: [
                { value: "executiveViewer", label: "Executive View" },
            ],
        },
    ];
    const handlePermissionChange = (category, permission) => {
        setSelectedPermissions(prevPermissions => {
            if (prevPermissions.includes(permission)) {
                // If the permission is already selected, remove it
                return prevPermissions.filter(p => p !== permission);
            } else {
                // If the permission is not selected, add it and remove any other permissions from the same category
                const otherCategoryPermissions = prevPermissions.filter(p =>
                    !category.permissions.some(catPerm => catPerm.value === p)
                );
                return [...otherCategoryPermissions, permission];
            }
        });
    };

    console.log(selectedPermissions);


    // const[users,setUsers] = useState([])
    const { token } = useSelector((state) => state.auth)
    const handleUserStatus = (checked) => {
        const status = checked ? 'active' : 'inactive';
        setUserStatus(status);
    }

    const [editMode, setEditMode] = useState(false);
    const editUser = async (e) => {
        if (e) {
            e.preventDefault();
        }
        const userDetails = {
            first_name: firstname,
            last_name: lastname,
            username,
            email,
            phone,
            title,
            status: userStatus,
            roles: selectedPermissions
        }
        const required = !lastname || !firstname || !email;
        // if (required) {
        //     setError(`Please fill all required field`)
        //     setTimeout(() => {
        //         setError('');
        //     }, 6000);
        //     return
        // }
        try {
            setLoading(true)
            const response = await api.put(api_url + `/api/v1/wholesaler/admin/${item.admin_id}/branch/${item.id}/user/${user.id}`, userDetails, {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${token}`
                }
            })

            setStatus('User updated successfully')
            fetchUsers()

        } catch (error) {
            setError(error?.response?.data?.error)
            setTimeout(() => {
                setError('');
            }, 6000);

        } finally {
            setTimeout(() => {
                setStatus('');
            }, 3000);
            setLoading(false)

        }
    }


    const handleEditMode = () => {
        // Toggle edit mode
        setEditMode(!editMode);

        // If entering edit mode, set the form fields to the current user's details
        if (!editMode) {
            setFirstname(user?.first_name);
            setLastname(user?.last_name);
            setUsername(user?.username);
            setEmail(user?.email);
            setTitle(user?.title);
            setPhone(user?.phone);
            setPermissions(''); // Set permissions according to your requirements
        }
    };

    useEffect(() => {
        setFirstname(user?.first_name);
        setLastname(user?.last_name);
        setEmail(user?.email);
        setUsername(user?.username);
        setTitle(user?.title);
        setPhone(user?.phone);
        setUserStatus(user?.status);
    }, [user]);

    const checker = user?.status === 'active'
   

    const modalRef = useRef()
    useEffect(() => {
        // Function to handle click outside of modal
        const handleClickOutside = (event) => {
            if (modalRef.current && !modalRef.current.contains(event.target)) {
                setStatus_history(false);
            }
        };

        // Attach the event listener
        document.addEventListener('mousedown', handleClickOutside);

        // Cleanup the event listener
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);


    return (
        <div className=' '>

            {users.map((item) => (
                <div key={item.id}>
                    {user.id == item.id &&
                        <div>
                            {user.status === 'archived' ? null : <div className=' mb-6 flex flex-col sm:flex-row justify-between  items-center '>
                                {/* <div className='flex items-center gap-4 my-1'>
                                    <button onClick={handleEditMode} className='font-lufga text-center font-semibold text-neutral-500 p-2 rounded-md shadow-md flex items-center gap-1 hover:bg-neutral-200 '>   {!editMode ? <><GrEdit /><span className='text-xs'>Edit</span></> : <><FcCancel /><span className='text-xs'>Cancel</span></>} </button>
                                    <button onClick={() => setResetPasswordModal(!resetPasswordModal)} className='font-lufga text-center text-xs font-semibold text-white p-2 rounded-md shadow-md flex items-center gap-1 bg-blue-500 hover:bg-blue-600 '>   <MdLockReset /><span className='text-xs'>Reset Password</span> </button>
                                    <button onClick={() => setStatus_history(!status_history)} className='font-lufga text-center text-xs font-semibold text-white p-2 rounded-md shadow-md flex items-center gap-1 bg-blue-500 hover:bg-blue-600 '>  <MdHistoryToggleOff /><span className='text-xs'>status history</span> </button>
                                </div> */}
                                <div className='flex items-center gap-4 my-1'>
                                    {hasPermission(roles,['userWriter'])&&(<button onClick={handleEditMode} className='font-lufga text-center font-semibold text-neutral-500 p-2 rounded-md shadow-md flex items-center gap-1 hover:bg-neutral-200 '>   <GrEdit /><span className='text-xs'>{!editMode ? 'Edit' : 'Cancel'}</span> </button>)}
                                    {hasPermission(roles,['userWriter'])&&(<button onClick={() => setResetPasswordModal(!resetPasswordModal)}className='font-lufga text-center text-xs font-semibold text-white p-2 rounded-md shadow-md flex items-center gap-1 bg-blue-500 hover:bg-blue-600 '>   <MdLockReset /><span className='text-xs'>Reset Password</span> </button>
)}
                                    <button onClick={() => setStatus_history(!status_history)} className='font-lufga text-center text-xs font-semibold text-white p-2 rounded-md shadow-md flex items-center gap-1 bg-blue-500 hover:bg-blue-600 '>  <MdHistoryToggleOff /><span className='text-xs'>status history</span> </button>
                                </div>
                                <div className='flex items-center gap-3 '>
                                    <label className="text-xs font-lufga" >Status</label>
                                    <Switch
                                        defaultChecked={checker}
                                        checkedChildren="active"
                                        unCheckedChildren="inactive"
                                        onChange={handleUserStatus}
                                        disabled={!editMode}

                                    />
                                </div>
                            </div>}
                            <form className="grid gap-4 mb-6 md:grid-cols-2">
                                {loading && ( // Display loader while loading
                                    <div className="flex justify-center items-center w-full h-full bg-gradient-to-br from-opacity-40 to-opacity-60 backdrop-blur-sm absolute top-0 left-0">
                                        <InfinitySpin color="#008DDA" loading={loading} size={36} />
                                    </div>
                                )}

                                <div className='flex flex-col gap-3'>
                                    <label className="text-sm font-lufga flex items-center gap-1" htmlFor="name">Firstname<span className='font-serif text-red-400'>*</span></label>
                                    <input
                                        type="text"
                                        id="name"
                                        name="name"
                                        value={firstname}
                                        onChange={(e) => { setFirstname(e.target.value) }}
                                        placeholder="Enter user's first name"
                                        required
                                        className={`px-3 py-2  rounded-md focus:outline-none focus:border-blue-500 ${editMode ? 'border border-gray-300' : ''}`}
                                        disabled={!editMode}
                                    />

                                </div>
                                <div className='flex flex-col gap-3'>
                                    <label className="text-sm font-lufga flex items-center gap-1" htmlFor="name">Lastname<span className='font-serif text-red-400'>*</span></label>
                                    <input
                                        type="text"
                                        value={lastname}
                                        onChange={(e) => { setLastname(e.target.value) }}
                                        placeholder="Enter user's last name"
                                        required
                                        className={`px-3 py-2  rounded-md focus:outline-none focus:border-blue-500 ${editMode ? 'border border-gray-300' : ''}`}
                                        disabled={!editMode}
                                    />

                                </div>
                                <div className='flex flex-col gap-3'>
                                    <label className="text-sm font-lufga flex items-center gap-1">Email<span className='font-serif text-red-400'>*</span></label>
                                    <input
                                        type="text"
                                        value={editMode ? email : user?.email}
                                        onChange={(e) => { setEmail(e.target.value) }}
                                        placeholder="Enter user's email"
                                        className={`px-3 py-2  rounded-md focus:outline-none focus:border-blue-500 ${editMode ? 'border border-gray-300' : ''}`}
                                        disabled={!editMode}
                                        required
                                    />
                                </div>
                                <div className='flex flex-col gap-3'>
                                    <label className="text-sm font-lufga flex items-center gap-1">Username<span className='font-serif text-red-400'>*</span></label>
                                    <input
                                        type="text"
                                        value={editMode ? username : user?.username}
                                        onChange={(e) => { setUsername(e.target.value) }}
                                        placeholder="Enter user's email"
                                        className={`px-3 py-2  rounded-md focus:outline-none focus:border-blue-500 ${editMode ? 'border border-gray-300' : ''}`}
                                        disabled={!editMode}
                                        required
                                    />
                                </div>

                                <div className='flex flex-col gap-3'>
                                    <label className="text-sm font-lufga flex items-center gap-1" htmlFor="phone">Phone Number</label>
                                    <input
                                        type="text"
                                        id="phone"
                                        name="phone"
                                        value={editMode ? phone : user?.phone}
                                        onChange={(e) => { setPhone(e.target.value) }}
                                        placeholder="Enter phone number"
                                        className={`px-3 py-2  rounded-md focus:outline-none focus:border-blue-500 ${editMode ? 'border border-gray-300' : ''}`}
                                        disabled={!editMode}
                                    />
                                </div>
                                <div className="flex flex-col gap-3">
                                    <label className="text-sm font-lufga flex items-center gap-1">
                                        Title
                                    </label>
                                    <select
                                        value={editMode ? title : user?.title}
                                        onChange={(e) => { setTitle(e.target.value) }}
                                        placeholder=""
                                        className={`px-3 py-2  rounded-md focus:outline-none focus:border-blue-500 ${editMode ? 'border border-gray-300' : ''}`}
                                        disabled={!editMode}
                                    >
                                        <option value="">Select User's Title</option>
                                        <option value="PharmD">PharmD (Doctor of Pharmacy)</option>
                                        <option value="Clinical Pharmacist">Clinical Pharmacist</option>
                                        <option value="MD">MD (Doctor of Medicine)</option>
                                        <option value="DDS">DDS (Doctor of Dental Surgery)</option>
                                        <option value="DO">DO (Doctor of Osteopathic Medicine)</option>
                                        <option value="RPh">RPh (Registered Pharmacist)</option>
                                        <option value="Other">Other</option>
                                    </select>
                                </div>

                                <div className="flex flex-col gap-3">
                                    <label className="text-sm font-lufga flex items-center gap-1">
                                        Position
                                    </label>
                                    <select
                                        value={editMode ? position : user?.position}
                                        onChange={(e) => { setPosition(e.target.value) }}
                                        placeholder=""
                                        className={`px-3 py-2  rounded-md focus:outline-none focus:border-blue-500 ${editMode ? 'border border-gray-300' : ''}`}
                                        disabled={!editMode}
                                    >
                                        <option value="">Select User's Position</option>
                                        <option value="Pharmacist">Pharmacist</option>
                                        <option value="Pharmacy Technician">Pharmacy Technician</option>
                                        <option value="Pharmacy Assistant">Pharmacy Assistant</option>
                                        <option value="Pharmacy Manager">Pharmacy Manager</option>
                                        <option value="Pharmacy Intern">Pharmacy Intern</option>
                                        <option value="Pharmacy Clerk">Pharmacy Clerk</option>
                                        <option value="Pharmacy Consultant">Pharmacy Consultant</option>
                                        <option value="Compounding Technician">
                                            Compounding Technician
                                        </option>
                                        <option value="Pharmacy Cashier">Pharmacy Cashier</option>
                                    </select>
                                </div>

                                <div className='flex flex-col gap-3'>
                                    <label className="text-sm font-lufga flex items-center gap-1" htmlFor="phone">Date Created </label>
                                    <input
                                        type="text"
                                        id="phone"
                                        name="phone"
                                        value={new Date(user?.created_at).toLocaleDateString()}
                                        onChange={(e) => { setPhone(e.target.value) }}
                                        placeholder="Enter phone number"
                                        className={`px-3 py-2  rounded-md focus:outline-none focus:border-blue-500 ${editMode ? 'border border-gray-300' : ''}`}
                                        disabled={true}
                                    />
                                </div>



                                <div className="col-span-2 tetx-xs ">
                                    <label className="text-sm font-lufga flex items-center gap-1">
                                        Permissions<span className="font-serif text-red-400">*</span>
                                    </label>
                                    <div className="grid grid-cols-1 sm:grid-cols-1 md:grid-cols-3 lg:grid-cols-4 gap-4 max-h-[180px] sm:max-h-[120px] lg:max-h-[250px] overflow-auto">
                                        {permissionCategories.map((category) => (
                                            <div key={category.label} className='flex flex-col items-start'>
                                                <div>
                                                    <p className='font-lufga'>{category.label}</p>
                                                </div>
                                                <div className='flex flex-col gap-2'>
                                                    {category.permissions.map((permission) => (
                                                        <div key={permission.value} className='flex items-center gap-1'>
                                                            <input
                                                                type="checkbox"
                                                                disabled={!editMode}
                                                                id={permission.value}
                                                                name={category.label}
                                                                value={permission.value}
                                                                checked={selectedPermissions.includes(permission.value)}
                                                                onChange={() => handlePermissionChange(category, permission.value)}
                                                                className="form-checkbox text-green-500"
                                                            />
                                                            <label htmlFor={permission.value} className='text-xs'>{permission.label}</label>
                                                        </div>
                                                    ))}
                                                </div>
                                            </div>
                                        ))}
                                    </div>
                                </div>



                            </form>
                            {status_history && <div className="fixed inset-0 flex items-center justify-center bg-gray-800 bg-opacity-50">
                                <div ref={modalRef} className="bg-white rounded-md min-h-max shadow-lg p-6">
                                    <MdClose size={24} onClick={() => setStatus_history(false)} className='hover:scale-105 hover:text-red-800 transition duration-150 ease-out cursor-pointer ' />
                                    <StatusHistory user={user} users={users} />
                                </div>
                            </div>}
                            {resetPasswordModal && <div className="fixed inset-0 flex items-center justify-center bg-gray-800 bg-opacity-50">
                                <div ref={modalRef} className="bg-white rounded-md min-h-max shadow-lg p-6">
                                    <MdClose size={24} onClick={() => setResetPasswordModal(false)} className='hover:scale-105 hover:text-red-800 transition duration-150 ease-out cursor-pointer ' />
                                    <ResetUserPassword user={user} users={users} toggle={() => setResetPasswordModal(false)}/>
                                </div>
                            </div>}
                        </div>

                    }
                </div>

            ))}



            <div >
                <div className='flex justify-between items-center'>

                    {editMode && <button onClick={editUser} className="bg-blue-500 font-lufga text-white py-2 px-4 rounded-md hover:bg-blue-600 shadow-md transition duration-300">Update</button>}
                    {status ? <div>
                        <p className="bg-green-300 font-lufga rounded-md shadow-md px-2 py-1 text-xs text-center ">{status}</p>
                    </div> : error ? <div>
                        <p className="bg-red-300 font-lufga rounded-md text-center shadow-md px-2 py-1 text-sm ">
                            {typeof error === 'string' ? error : (
                                Object.keys(error).map((key) => (
                                    Array.isArray(error[key]) ? error[key][0] : error[key]
                                ))
                            )}
                        </p>
                    </div> : null}</div>


            </div>

        </div>
    );
}

export default UserDetails;
