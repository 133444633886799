import React, { useRef, useState, useEffect } from 'react';
import Sidebar from './Sidebar';
import { Outlet, useNavigate } from 'react-router-dom';
import Header from './Header';
import { FcNext, FcPrevious } from 'react-icons/fc';
import { useDispatch, useSelector } from 'react-redux';
import UpdatePasswordForm from './updatePasswordForm';
import { InfinitySpin } from 'react-loader-spinner';
import { clearAuthData, logoutState } from '../../Redux/AuthSlice';
import { api_url } from '../../config';
import api from '../../utils/api';
import { toast } from 'react-toastify';

const Layout = () => {
    const [isSidebarVisible, setIsSidebarVisible] = useState(false);
    const [isLargeScreen, setIsLargeScreen] = useState(false);
    const { loading, reset_password } = useSelector((state) => state.auth);
    const [modal, setModal] = useState(reset_password);
    const modalRef = useRef();
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const {id,token,branch_id} = useSelector((state)=>state.auth)

    useEffect(() => {
        const handleResize = () => {
            const largeScreen = window.innerWidth >= 1024; // md breakpoint
            setIsLargeScreen(largeScreen);
            setIsSidebarVisible(largeScreen);
        };

        handleResize();
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    const toggleSidebar = () => {
        setIsSidebarVisible(!isSidebarVisible);
    };

    const handleLogout = async () => {

        try {
            dispatch(logoutState(true))
                await api.post(api_url+'/api/wholesaler/logout', {}, {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                });
            
            dispatch(clearAuthData());

            // Manipulate the browser's history to prevent going back to protected pages
            window.history.pushState(null, '', '/login');
            window.onpopstate = () => {
                window.history.pushState(null, '', '/login');
            };
            dispatch(logoutState(false))
            toast('Login again with updated password')
            // Navigate to the login page
            navigate('/login', { replace: true });
        } catch (error) {
            console.error('Logout error:', error);
            // Handle error if needed
        }
    };
    return (
        <div className="flex flex-col h-screen">
            <Header />
            <div className="flex flex-grow overflow-hidden relative">
                <div className={`
                absolute top-0 left-0 h-full
                bg-[#0e1b31] p-4 font-lufga
                transition-all duration-300 ease-in-out
                ${isLargeScreen
                        ? (isSidebarVisible ? 'w-[250px]' : 'w-0')
                        : 'w-[250px]'
                    }
                ${isSidebarVisible ? 'translate-x-0' : '-translate-x-full'}
                ${isLargeScreen ? '' : 'z-30'}
            `}>
                    <Sidebar />
                </div>
                <div className={`
                flex-grow overflow-auto transition-all duration-300 ease-in-out
                ${isLargeScreen && isSidebarVisible ? 'ml-[250px]' : 'ml-0'}
            `}>
                    <div className="px-2 py-6">
                        <Outlet/>
                    </div>
                </div>
                <button
                    onClick={toggleSidebar}
                    className="fixed top-10 left-0 z-40 bg-blue-900 bg-opacity-10 backdrop-blur-xl 
                           border border-white border-opacity-20 p-2 hover:bg-opacity-20 rounded-lg"
                >
                    {isSidebarVisible ? <FcPrevious size={20} /> : <FcNext size={20} />}
                </button>
                {loading && (
                    <div className="absolute inset-0 flex justify-center items-center bg-gradient-to-br from-opacity-40 to-opacity-60 backdrop-blur-sm z-50">
                        <div className='flex flex-col items-center gap-4'>
                            <InfinitySpin color="#387ADF" loader={loading} size={32} />
                            <p className='text-center'>Logging Out....</p>
                        </div>
                    </div>
                )}
            </div>
            {(!modal || modal === '0') && (
                <div className="fixed inset-0 flex items-center justify-center bg-neutral-800 bg-opacity-100 z-50">
                    <div ref={modalRef} className="bg-white rounded-md min-h-max shadow-lg p-6">
                        <UpdatePasswordForm setModal={setModal} modal={modal}  handleLogout={handleLogout} />
                    </div>
                </div>
            )}
        </div>
    );
}

export default Layout;