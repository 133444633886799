import React from 'react';
import services from '../../assets/services.jpg';
import subservices from '../../assets/subservice.jpg';
import { HiCheck } from 'react-icons/hi';

const Services = () => {
    return (
        <div className='mb-8'>

            <div className="text-center text-lg font-bold text-gray-800 tracking-wider mb-20">
                <p className="inline-block text-2xl">PHAMTRIX SERVICES</p>
                <hr className='w-16 mx-auto font-extrabold border-b-2 border-[#00A9FF]' />
            </div>
            <div className='bg-blue-50 flex flex-col items-center justify-center gap-14'>
                <div className='flex flex-col justify-center items-center md:flex-row gap-4 mb-8 w-full p-6 mx-auto '>
                    <div className=' '>
                        <img src={services} className='w-[500px]  h-[500px] rounded-md shadow-sm object-cover' />
                    </div>
                    <div className='h-full p-4'>
                        <div className='flex flex-col gap-6'>
                            <p className='xl:text-4xl text-2xl  font-semibold mb-4 tracking-wide'> PharmtriX for Wholesale Pharmacy</p>
                            <p className='flex items-center gap-6 text-lg xl:text-xl tracking-wide font-medium text-gray-500'><HiCheck size={28} className='text-[#00A9FF]' /> Manage your Suppliers</p>
                            <p className='flex items-center gap-6 text-lg xl:text-xl tracking-wide font-medium text-gray-500'><HiCheck size={28} className='text-[#00A9FF]' /> Manage your Retailers</p>
                            <p className='flex items-center gap-6 text-lg xl:text-xl tracking-wide font-medium text-gray-500'><HiCheck size={28} className='text-[#00A9FF]' /> Manage Inventories</p>
                            <p className='flex items-center gap-6 text-lg xl:text-xl tracking-wide font-medium text-gray-500'><HiCheck size={28} className='text-[#00A9FF]' /> Manage Stocks</p>
                            <p className='flex items-center gap-6 text-lg xl:text-xl tracking-wide font-medium text-gray-500'><HiCheck size={28} className='text-[#00A9FF]' /> Manage Sales</p>
                            <p className='flex items-center gap-6 text-lg xl:text-xl tracking-wide font-medium text-gray-500'><HiCheck size={28} className='text-[#00A9FF]' /> Manage Order from Retailers</p>
                            <p className='flex items-center gap-6 text-lg xl:text-xl tracking-wide font-medium text-gray-500'><HiCheck size={28} className='text-[#00A9FF]' /> Dashboard of how your business is doing</p>
                        </div>

                    </div>
                </div>
                <div className='flex flex-col justify-center items-center md:flex-row gap-4 mb-8 w-full p-6 mx-auto '>

                    <div className='h-full p-4'>
                        <div className='flex flex-col gap-6'>
                            <p className='xl:text-4xl text-2xl  font-semibold  mb-4 tracking-wide'> PharmtriX for Retail Pharmacy</p>
                            <p className='flex items-center gap-6 text-lg xl:text-xl tracking-wide font-medium text-gray-500'><HiCheck size={28} className='text-[#00A9FF]' /> Manage your Suppliers</p>
                            <p className='flex items-center gap-6 text-lg xl:text-xl tracking-wide font-medium text-gray-500'><HiCheck size={28} className='text-[#00A9FF]' /> Manage your Retailers</p>
                            <p className='flex items-center gap-6 text-lg xl:text-xl tracking-wide font-medium text-gray-500'><HiCheck size={28} className='text-[#00A9FF]' /> Manage Inventories</p>
                            <p className='flex items-center gap-6 text-lg xl:text-xl tracking-wide font-medium text-gray-500'><HiCheck size={28} className='text-[#00A9FF]' /> Manage Stocks</p>
                            <p className='flex items-center gap-6 text-lg xl:text-xl tracking-wide font-medium text-gray-500'><HiCheck size={28} className='text-[#00A9FF]' /> Manage Sales</p>
                            <p className='flex items-center gap-6 text-lg xl:text-xl tracking-wide font-medium text-gray-500'><HiCheck size={28} className='text-[#00A9FF]' /> Manage Order from Retailers</p>
                            <p className='flex items-center gap-6 text-lg xl:text-xl tracking-wide font-medium text-gray-500'><HiCheck size={28} className='text-[#00A9FF]' /> Dashboard of how your business is doing</p>
                        </div>

                    </div>
                    <div className=' '>
                        <img src={subservices} className='w-[500px]  h-[500px] rounded-md shadow-sm object-cover' />
                    </div>
                </div>

            </div>


        </div>
    )
}

export default Services;
