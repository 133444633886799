import React from 'react';
import { BiLogoInstagram, BiLogoTwitter } from 'react-icons/bi';
import { FaFacebookF } from 'react-icons/fa';

const Footer = () => {
    return (
        <div className=' mt-20 '>
            <div className='flex justify-center bg-teal-50 p-8'>
                <div className=' grid xl:grid-cols-4 '>
                    <div className='flex flex-col justify-start'>
                        <p className='text-4xl font-semibold tracking-wide mb-4'>Pharmtrix</p>
                        <p className='text-sm '>info@ntubedglobal.com</p>
                        <p className='text-sm mb-4'>support@ntubedglobal.com</p>
                        <p className=' text-sm'><span className='font-sans'>+</span>233 <span className='font-sans'>(0)</span>30 396 <span className='font-sans'>4</span>289</p>
                        <p className='text-sm'><span className='font-sans'>+</span>233 <span className='font-sans'>(0)</span>30 396 <span className='font-sans'>4</span>289</p>
                        <div className='flex space-x-4 mt-4'>
                            <BiLogoInstagram size={42} className='border-b-[1px] border-black rounded-full p-2' />
                            <FaFacebookF size={38} className='border-b-[1px] border-black rounded-full p-2' />
                            <BiLogoTwitter size={42} className='border-b-[1px] border-black rounded-full p-2' />
                        </div>
                    </div>

                    <div>
                        <p className='font-bold'>Useful Links</p>
                        <ul className='mt-6'>
                            <li className='text-sm'>Home</li>
                            <li className='text-sm'>Login</li>
                            <li className='text-sm'>Contact Us</li>
                        </ul>
                    </div>

                    <div>
                        <p className='font-bold'>Our services</p>
                        <ul className='mt-6'>
                            <li className='text-sm'>Wholesale Pharmacy</li>
                            <li className='text-sm'>Retail Pharmacy</li>
                            <li className='text-sm'>Lorem</li>
                        </ul>
                    </div>
                    <div className=''>
                        <p className='font-bold'>Our Newsletter</p>
                        <p className='text-sm mt-2 mb-8'>to be updated with all latest product</p>
                        <div className='relative'>
                            <input className='w-[300px] p-2 rounded-md h-10 border-[1px] ' />
                            <button className='bg-[#00A9FF] absolute  right-[1px] p-2 h-10 w-20 text-white rounded-r-md text-sm'>Subscribe</button>
                        </div>


                    </div>
                </div>
            </div>


            <div className=' p-8 w-full bg-blue-100'>
                <p className='text-center text-gray-500 text-sm'>&#169; Copyright <span className='text-black'>Pharmtrix</span>, All Rights Reserved</p>
            </div>
        </div>
    );
}

export default Footer;
