import React, { useEffect, useState } from 'react';
import Stepper from './Stepper';
import OrderDetails from './OrderDetails';
import { AiOutlineComment } from "react-icons/ai";
import OrderComments from './OrderComments';
import OrderList from './OrderList';
import { useLocation, useNavigate, useParams } from 'react-router';
import { orderStatuses } from '../../../utils/orderStatus';
import { IoArrowBack } from 'react-icons/io5';
import { ColorRing, InfinitySpin } from 'react-loader-spinner';
import { toast } from 'react-toastify';
import api from '../../../utils/api';
import { api_url } from '../../../config';
import { useSelector } from 'react-redux';
import { useOrders } from '../../../context/orderContext';
import { FiRefreshCw } from "react-icons/fi";
import { selectRoles } from '../../../Redux/AuthSlice';



const OrderData = () => {
    const [isCommentPaneOpen, setIsCommentPaneOpen] = useState(false);
    const [loader, setLoader] = useState(true)
    const [order, setOrder] = useState(null)
    const navigate = useNavigate();
    const location = useLocation();
    const requiredRoles = location.state?.requiredRoles || [];
    const { id, branch_id, token } = useSelector((state) => state.auth)

    const { id: order_id } = useParams()
    const orderId = parseInt(order_id)
    console.log(order_id);


    const fetchOrder = async () => {
        setLoader(true)
        if (!navigator.onLine) {
            toast("You're offline");
            setLoader(false);
            return;
        }


        try {
            // setLoader(true)
            const response = await api.get(api_url + `/api/v1/retailer/user/${id}/branch/${branch_id}/order/${orderId}`, {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${token}`
                },

            })

            const { data, meta } = response.data;

            setOrder(data);
        } catch (error) {
            console.log(error);
            toast("Error fetching orders")

        } finally {
            setLoader(false)
        }
    }

    useEffect(() => {
        fetchOrder()
    }, [id, orderId, branch_id, token])



    const orderStatus = order?.status
    console.log(orderStatus);
    const toggleCommentPane = () => {
        setIsCommentPaneOpen(!isCommentPaneOpen);
    };

    const {
        setLoading
    } = useOrders();


    const refreshOrder = async () => {
        try {
            await fetchOrder();
        } catch (error) {
            toast.error("Failed to refresh order");
        } 
    };


    const selectedRoles = useSelector(selectRoles);
    const roles = Array.isArray(selectedRoles) ? selectedRoles : selectedRoles.split(',').map(role => role.trim());
    return (
        <div className='font-lufga text-xs w-full overflow-auto '>
            <div className='w-full flex justify-between mb-2'>
                <div className='flex items-center gap-6'>
                    <button onClick={() => {
                        setLoading(true)
                        navigate('/retail/orders', { state: { requiredRoles } });
                    }}
                        className=' text-xs font-semibold text-slate-600 hover:underline cursor-pointer flex items-center gap-[1px] '>
                        <IoArrowBack />
                        <p>Go to Orders</p>
                    </button>
                    <button
                    onClick={refreshOrder}
                    className='flex items-center gap-2 hover:underline text-slate-600'>
                        <FiRefreshCw className='text-green-600 font-semibold'/>
                        Refresh Order
                    </button>

                </div>
                <span onClick={toggleCommentPane} className='flex items-center gap-1 border px-2 rounded-md shadow-md hover:bg-slate-50 hover cursor-pointer'>
                    <AiOutlineComment />
                    <button className='text-xs font-lufga'>Comments</button>
                </span>

            </div>
            <div className='flex gap-3 '>
                <OrderList />
                {loader ? <div className="grid place-items-center w-full ">
                    <ColorRing
                        height="50"
                        width="50"
                        loader={loader} />
                </div> : <div className='w-full h-full'>
                    <Stepper order={order} orderStatus={orderStatus} />
                    <OrderDetails order={order} orderStatus={orderStatus} orderId={orderId} fetchOrder={fetchOrder} roles={roles}/>
                </div>}
            </div>



            <div className={`fixed top-0 z-50 right-0 h-full w-80 bg-white shadow-xl transform transition-transform duration-300 ease-in-out ${isCommentPaneOpen ? 'translate-x-0' : 'translate-x-full'}`}>
                <div className="py-4 pl-2">
                    <div className="flex justify-between items-center mb-4">
                        <h2 className="text-lg font-bold">Comments</h2>
                        <button onClick={toggleCommentPane} className="text-gray-500 hover:text-gray-700">
                            <svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
                            </svg>
                        </button>
                    </div>
                    {/* Add your comments content here */}
                    <OrderComments order={order} orderId={orderId} roles={roles}/>
                </div>
            </div>
        </div>
    );
}

export default OrderData;
