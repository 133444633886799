import React, { useEffect, useRef, useState } from 'react';
import { IoIosArrowDown, IoIosArrowRoundDown, IoIosArrowRoundUp, IoIosArrowUp, IoMdClose, IoMdCloseCircle } from 'react-icons/io';


// expandalble cell implememtation
    // be careful..the useEffect handles a lot of assumptions
    export function ExpandableProductList({ products }) {
        const [isExpanded, setIsExpanded] = useState(false);
        const [height, setHeight] = useState('auto');
        const contentRef = useRef(null);
        const displayLimit = 2;
      
        useEffect(() => {
          if (contentRef.current) {
            if (isExpanded) {
              setHeight(`${contentRef.current.scrollHeight}px`);
            } else {
              setHeight(`${Math.min(products.length, displayLimit) * 24}px`); // Assuming each item is roughly 24px high
            }
          }
        }, [isExpanded, products]);
      
        const toggleExpand = (e) => {
          e.stopPropagation();
          setIsExpanded(!isExpanded);
        };
      
        return (
          <div className="relative min-h-[48px]"> {/* Minimum height for 2 items */}
            <div 
              ref={contentRef}
              style={{ 
                height: height,
                transition: 'height 0.3s ease-in-out',
                overflow: 'hidden'
              }}
            >
              {products.map((product, index) => (
                <div key={index} className="text-xs py-1 truncate">{product}</div>
              ))}
            </div>
            {products.length > displayLimit && (
              <button
                onClick={toggleExpand}
                className="absolute bottom-0 right-0 text-[10px] text-blue-500 hover:text-blue-700 focus:outline-none bg-white px-1 rounded transition-all duration-300 ease-in-out"
                style={{
                  transform: isExpanded ? 'translateY(100%)' : 'translateY(0)',
                }}
              >
                {isExpanded ? (
                  <>
                    <IoIosArrowUp className="inline w-3 h-3" />
                    <span className="ml-1">Less</span>
                  </>
                ) : (
                  <>
                    <span className="mr-1">more</span>
                    <IoIosArrowDown className="inline w-3 h-3" />
                  </>
                )}
              </button>
            )}
          </div>
        );
      }

      