import React from 'react';
import { HiOutlinePhone } from 'react-icons/hi';
import { HiOutlineEnvelope, HiOutlineMapPin } from 'react-icons/hi2';
import Map from './Map';

const Contact = () => {
    return (
        <div className='m-6'>
            <div className="text-center text-lg font-bold text-gray-700 tracking-wide mt-20 mb-10">
                <p className="inline-block ">Contact Us</p>
                <hr className='w-16 mx-auto font-extrabold border-b-2 border-[#00A9FF]' />
            </div>
            <div className='grid md:grid-cols-2 gap-4 mb-6'>
                <div className=''>
                    <div className='shadow-md p-8 border-[1px]'>
                        <HiOutlineMapPin size={56} className='text-[#00A9FF] mb-4 mx-auto border-2 border-dotted rounded-full border-[#00A9FF] p-2' />
                        <p className='text-center font-bold text-gray-600 tracking-wide'>Our Address</p>
                        <p className='text-center'>Ntubed global ltd tobinco building <span className='font-sans'>-</span> subah new office <br/> near coca<span className='font-sans'>-</span>cola roundabout</p>
                    </div>
                    <div className='mt-6 grid md:grid-cols-2 gap-4'>
                        <div className='border-[1px] px-12 py-12 shadow-md rounded-md'>
                            <HiOutlineEnvelope size={56} className='text-[#00A9FF] mb-4 mx-auto border-2 border-dotted rounded-full border-[#00A9FF] p-2  '/>
                            <p className='text-center font-bold text-gray-600 tracking-wide'>Email Us</p>
                            <p className='text-center '>info<span className='font-sans'>@</span>ntubedglobal.com</p>
                            <p className='text-center '>support<span className='font-sans'>@</span>ntubedglobal.com</p>
                        </div>
                        <div className='border-[1px]  px-12 py-12 shadow-md rounded-md'>
                            <HiOutlinePhone size={56} className='text-[#00A9FF] mb-4 mx-auto border-2 border-dotted rounded-full border-[#00A9FF] p-2  '/>
                            <p className='text-center font-bold text-gray-600 tracking-wide'>Call Us</p>
                            <p className='text-center'><span className='font-sans'>+</span>233 <span className='font-sans'>(0)</span>30 396 <span className='font-sans'>4</span>289</p>
                            <p className='text-center'><span className='font-sans'>+</span>233 <span className='font-sans'>(0)</span>30 396 <span className='font-sans'>4</span>289</p>
                        </div>
                    </div>
                </div>

                <div className=''>
                    <form className='shadow-md px-8 py-12 border-[1px] h-full'>
                        <div className='grid md:grid-cols-2 gap-4'>
                            <input className=' p-2 rounded-md h-10 border-[1px] ' placeholder='Your name' />
                            <input className=' p-2 rounded-md h-10 border-[1px] ' placeholder='Your email' />
                        </div>
                        <input className='w-full p-2 mb-6 mt-3 rounded-md h-10 border-[1px] ' placeholder='Your email' />
                        <textarea rows={6} className='w-full border-[1px] p-2 rounded-md' placeholder='message'/>

                        <div className='flex justify-center mt-4'>
                            <button className='bg-[#00A9FF] p-4 text-white rounded-md'>Send message</button>
                        </div>

                    </form>
                </div>
            </div>
            <Map/>
           
        </div>
    );
}

export default Contact;
