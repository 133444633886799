import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import axios from 'axios';
import { api_url } from '../../config';
import Profile from '../../components/Retaillib/Profile';
import api from '../../utils/api';




const UserProfilePage = () => {

    const [user,setUser] = useState('')
    const [loading,setLoading] = useState(true)

    const {id,branch_id,token} = useSelector((state)=>state.auth)

useEffect(()=>{
    const fetchUser = async () => {    
        try {
            const response = await api.get(api_url + `/api/v1/retailer/user/${id}/branch/${branch_id}/user-profile-single`, {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${token}`
                },
            
            });
            const data = response.data.data;    
            setUser(data)
          
        } catch (error) {
            console.error('Error fetching users:', error);
        } finally {
            setLoading(false)
    
        }
    };

    fetchUser()
},[id])
    
    return (
        <div>
            <Profile user={user}/>
        </div>
    );
}

export default UserProfilePage;
